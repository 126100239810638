<template>
  <v-container>
    <v-row
      v-for="reportTemplate in reportTemplates"
      :key="reportTemplate.Id"
      class="d-flex align-center"
    >
      <v-col class="d-flex justify-end">{{ reportTemplate.Name }}</v-col>
      <v-col>
        <ReportDialog
          :survey="survey"
          :report="report"
          :dialog="reportDialog"
          :reportTemplate="reportTemplate"
          :question="question"
          :selection="selection"
          :isLoading="reportIsLoading"
          @getWebReport="getWebReport"
        />
        <v-btn
          color="info"
          small
          :loading="reportIsLoading"
          @click="
            $emit('downloadWordReport', reportTemplate, question, selection)
          "
        >
          <v-icon>mdi-file-word-box</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ReportDialog from "@/components/Report/ReportDialog";

export default {
  components: {
    ReportDialog,
  },
  data: function () {
    return {
      reportDialog: false,
    };
  },
  methods: {
    getWebReport(...args) {
      this.$emit("getWebReport", ...args);
    },
  },
  props: {
    survey: Object,
    report: Array,
    question: Object,
    selection: Object,
    reportTemplates: Array,
    reportIsLoading: Boolean,
  },
};
</script>

<style></style>
