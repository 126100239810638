<template>
  <v-btn @click="downloadExcel" class="mr-2 mobile-btn"
    >Lataa excel-pohja <v-icon right>mdi-cloud-download</v-icon>
  </v-btn>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  computed: {},
  data: () => ({
    voterData: "",
    search: "",
    editText: "",
    rows: [],
    selectData: {},
    selectValues: [],
    selectedRows: [],
    index: 0,
  }),
  methods: {
    downloadExcel() {
      axios({
        url: `/api/voter/getexcel/${this.guid}`,
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Vastaajat.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
  },
  watch: {},
  props: {
    guid: String,
  },
};
</script>
