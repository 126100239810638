<template>
  <CompareReportGroupBarChart
    :title="this.title ?? $t('voter.report.overall')"
    :mainGroup="this.mainGroup"
    :allGroups="this.allGroups"
    :groupsProp="[{ id: 'c_0', name: $t('voter.report.overallaverage') }]"
    topBottom
    :showLegend="showLegend"
    @groupClicked="groupClicked"
    :bgColor="bgColor"
    :enableDrill="enableDrill"
  />
</template>

<script>
import CompareReportGroupBarChart from "@/components/Compare/CompareReportGroupBarChart";

export default {
  components: {
    CompareReportGroupBarChart,
  },
  computed: {
    mainGroup() {
      return Array.isArray(this.overview)
        ? this.overview.map((x) => x.all[0])
        : this.overview.all;
    },
    allGroups() {
      return Array.isArray(this.overview)
        ? this.overview
            .map((x) => x.all.concat(x.questions).concat(x.drilldownQuestions))
            .flat()
        : this.overview.all
            .concat(this.overview.questions)
            .concat(this.overview.drilldownQuestions);
    },
  },
  data: () => ({}),
  methods: {
    groupClicked(group) {
      this.$emit("groupClicked", group ? 1 : 0);
    },
  },
  created() {},
  props: {
    title: { type: String },
    overview: { type: [Object, Array], required: true },
    showLegend: Boolean,
    bgColor: String,
    enableDrill: { type: Boolean, default: true },
  },
};
</script>

<style></style>
