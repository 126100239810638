var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"960px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"info","small":""},on:{"click":_vm.getReport}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-document-outline")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.question !== undefined && _vm.selection !== undefined ? `${_vm.reportTemplate.Name} - ${_vm.question.question} - ${_vm.selection.selection}` : _vm.reportTemplate.Name)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"black","text":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('LoadingIndicator',{attrs:{"isLoading":_vm.isLoading}}),(!_vm.isLoading)?_c('div',[(!_vm.report || _vm.report.length === 0)?_c('p',[_vm._v(" "+_vm._s(_vm.$t("voter.report.nodata"))+" ")]):_vm._l((_vm.report),function(item,idx){return _c('v-row',{key:idx,attrs:{"align":"center","justify":"center"}},[_c('v-col',[(item.Type === 1)?_c('h2',[_vm._v(_vm._s(item.Text))]):(item.Type === 2)?_c('p',[_vm._v(_vm._s(item.Text))]):(item.Type === 3 && item.Chart1.Type === 0)?_c('PieChart',{attrs:{"width":"100%","height":"400","reportItem":item}}):(
                item.Type === 3 &&
                item.Chart1.Type === 1 &&
                item.Chart1.Series.length === 1
              )?_c('SimpleColumnChart',{attrs:{"width":"100%","height":"400","reportItem":item}}):(
                item.Type === 3 &&
                item.Chart1.Type === 1 &&
                item.Chart1.Series.length > 1
              )?_c('MultipleSeriesColumnChart',{attrs:{"width":"100%","height":"400","reportItem":item}}):(item.Type === 3 && item.Chart1.Type === 2)?_c('RadarChart',{attrs:{"width":"100%","height":"400","reportItem":item}}):(
                item.Type === 3 &&
                item.Chart1.Type === 3 &&
                item.Chart1.Series.length === 1
              )?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('SimpleBarChart',{attrs:{"width":"100%","height":"400","reportItem":item}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('SimpleColumnChart',{attrs:{"width":"100%","height":"400","reportItem":item,"useChart2":""}})],1)],1)],1):(
                item.Type === 3 &&
                item.Chart1.Type === 3 &&
                item.Chart1.Series.length > 1
              )?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('MultipleSeriesBarChart',{attrs:{"width":"100%","height":"400","reportItem":item}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('SimpleColumnChart',{attrs:{"width":"100%","height":"400","reportItem":item,"useChart2":""}})],1)],1)],1):(item.Type === 4)?_c('Table',{attrs:{"table":item.Table}}):_c('h1',[_vm._v(" "+_vm._s(_vm.getInfo(item))+" ")])],1)],1)})],2):_vm._e()],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("general.close"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }