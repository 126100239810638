<template>
  <div class="">
    <div class="text-center mb-3">
      <h3>{{ title }}</h3>
    </div>
    <div class="info rounded-circle white--text centered-card-text">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>
.centered-card-text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 150px;
  max-height: 150px;
  width: 100%;
  height: 150px;
  font-size: xx-large;
}
</style>
