<template>
  <span>{{ titleCombined }}</span>
</template>

<script>
export default {
  computed: {
    titleCombined() {
      let text = this.title;

      if (this.subtitle && this.subtitle.length > 0) {
        text += ` - ${this.subtitle}`;
      }

      return text;
    },
  },
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
  },
};
</script>
