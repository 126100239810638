<template>
  <span :style="{ color: roleColor }">{{ computedText }} ({{ count }})</span>
</template>

<script>
export default {
  computed: {
    roleColor() {
      if (this.excludeRole) {
        return this.count > 0
          ? this.$config.report.chartColorGreen
          : this.$config.report.chartColorRed;
      }

      return this.count >= this.$config.voter.roleCountOk
        ? this.$config.report.chartColorGreen
        : this.count >= this.$config.voter.roleCountWarning
        ? this.$config.report.chartColorYellow
        : this.$config.report.chartColorRed;
    },
    computedText() {
      return this.onlyFirstLetter ? this.text.charAt(0) : this.text;
    },
    isValid() {
      return this.roleColor === this.$config.report.chartColorGreen;
    },
  },
  props: {
    text: { type: String, required: true },
    count: { type: Number, required: true },
    onlyFirstLetter: { type: Boolean, default: false },
    excludeRole: { type: Boolean, default: false },
  },
};
</script>

<style>
</style>