import axios from "axios";

export default {
  namespaced: true,

  state: {
    isLoading: false,
    overview: null,
    compareOverview: null,
    overviewWithFilter: null,
    compareOverviewWithFilter: null,
    report: [],
    status: null,
    reportTokens: [],
  },

  getters: {
    overview(state) {
      return state.overview;
    },
    compareOverview(state) {
      return state.compareOverview;
    },
    status(state) {
      return state.status;
    },
    isLoading(state) {
      return state.isLoading;
    },
    overviewWithFilter(state) {
      return state.overviewWithFilter;
    },
    compareOverviewWithFilter(state) {
      return state.compareOverviewWithFilter;
    },
    report(state) {
      return state.report;
    },
    reportTokens(state) {
      return state.reportTokens;
    },
  },

  mutations: {
    SET_ISLOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_OVERVIEW(state, overview) {
      state.overview = overview;
    },
    SET_COMPARE_OVERVIEW(state, overview) {
      state.compareOverview = overview;
    },
    SET_OVERVIEW_WITH_FILTER(state, overviewWithFilter) {
      state.overviewWithFilter = overviewWithFilter;
    },
    SET_COMPARE_OVERVIEW_WITH_FILTER(state, overviewWithFilter) {
      state.compareOverviewWithFilter = overviewWithFilter;
    },
    SET_REPORT(state, report) {
      state.report = report;
    },
    SET_STATUS(state, status) {
      state.status = status;
    },
    SET_REPORT_TOKENS(state, tokens) {
      state.reportTokens = tokens;
    },
  },

  actions: {
    async getReportOverview({ commit }, guid) {
      try {
        commit("SET_ISLOADING", true);
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/report/overview/${guid}`
        );
        commit("SET_OVERVIEW", response.data);
        commit("SET_ISLOADING", false);
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    async getCompareReportOverview({ commit }, guid) {
      try {
        commit("SET_ISLOADING", true);
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/report/overview/${guid}`
        );
        commit("SET_COMPARE_OVERVIEW", response.data);
        commit("SET_ISLOADING", false);
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    async getStatus({ commit }, guid) {
      try {
        commit("SET_ISLOADING", true);
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/report/status/${guid}`
        );
        commit("SET_ISLOADING", false);
        commit("SET_STATUS", response.data);
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    async getReportOverviewWithFilter(
      { commit },
      { guid, questionId, selectionId }
    ) {
      try {
        commit("SET_ISLOADING", true);
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/report/overviewwithfilter/${guid}/${questionId}/${selectionId}`
        );
        commit("SET_ISLOADING", false);
        commit("SET_OVERVIEW_WITH_FILTER", response.data);
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    async getCompareReportOverviewWithFilter(
      { commit },
      { guid, questionId, selectionId }
    ) {
      try {
        commit("SET_ISLOADING", true);
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/report/overviewwithfilter/${guid}/${questionId}/${selectionId}`
        );
        commit("SET_ISLOADING", false);
        commit("SET_COMPARE_OVERVIEW_WITH_FILTER", response.data);
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    async getWebReport(
      { commit },
      { guid, reportId, questionId, selectionId }
    ) {
      commit("SET_ISLOADING", true);

      var filter =
        questionId !== undefined && selectionId !== undefined
          ? { Id: questionId, Selected: [selectionId] }
          : {};

      try {
        let response = await axios.post(
          `${process.env.VUE_APP_API_URL}/report/webreport/${guid}/${reportId}`,
          filter
        );
        commit("SET_ISLOADING", false);
        commit("SET_REPORT", response.data);
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    async getReportTokens({ commit }, guid) {
      let response = await axios.get(
        `${process.env.VUE_APP_API_URL}/report/reporttoken/${guid}`
      );
      commit("SET_ISLOADING", false);
      commit("SET_REPORT_TOKENS", response.data);
    },
    async postReportToken({ commit }, token) {
      try {
        commit("SET_ISLOADING", true);
        let response = await axios.post(
          `${process.env.VUE_APP_API_URL}/report/reporttoken`,
          token
        );
        commit("SET_ISLOADING", false);
        return response.data;
      } catch (e) {
        commit("SET_ISLOADING", false);
        return "";
      }
    },
    async putReportToken({ commit }, token) {
      try {
        commit("SET_ISLOADING", true);
        let response = await axios.put(
          `${process.env.VUE_APP_API_URL}/report/reporttoken/${token.guid}`,
          token
        );
        commit("SET_ISLOADING", false);
        return response.data;
      } catch (e) {
        commit("SET_ISLOADING", false);
        return "";
      }
    },
    async deleteReportToken({ commit }, token) {
      try {
        commit("SET_ISLOADING", true);
        await axios.delete(
          `${process.env.VUE_APP_API_URL}/report/reporttoken/${token}`
        );
        commit("SET_ISLOADING", false);
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    async sendReportLinkEmail({ commit }, token) {
      try {
        commit("SET_ISLOADING", true);
        let response = await axios.post(
          `${process.env.VUE_APP_API_URL}/report/sendreportlinkemail`,
          token
        );
        commit("SET_ISLOADING", false);
        return response.data;
      } catch (e) {
        commit("SET_ISLOADING", false);
        return "";
      }
    },
    clearOverview({ commit }) {
      commit("SET_OVERVIEW", null);
    },
    clearCompareOverview({ commit }) {
      commit("SET_COMPARE_OVERVIEW", null);
    },
    clearOverviewWithFilter({ commit }) {
      commit("SET_OVERVIEW_WITH_FILTER", null);
    },
    clearCompareOverviewWithFilter({ commit }) {
      commit("SET_COMPARE_OVERVIEW_WITH_FILTER", null);
    },
    clearReport({ commit }) {
      commit("SET_REPORT", []);
    },
  },
};
