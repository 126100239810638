<template>
  <v-dialog v-model="dialog" scrollable persistent width="720">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="success" class="mobile-btn" v-bind="attrs" v-on="on"
        >Lisää uusi
      </v-btn>
    </template>

    <v-card>
      <v-card-title>Lisää arvioitavia henkilöitä</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <v-row>
          <v-col>
            <v-textarea
              outlined
              hide-details
              label="Lisää vastaajia, yksi per rivi."
              v-model="voterData"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-sm-flex justify-sm-space-between">
            <v-btn @click="parseInput" color="info" class="mobile-btn"
              >Lue tekstikenttä</v-btn
            >
          </v-col>
        </v-row>
        <v-row v-show="rows.length > 0">
          <v-col> Muista tarvittaessa valita myös tiimi. </v-col>
        </v-row>

        <v-row v-if="rows.length > 0">
          <v-data-table
            :search="search"
            v-model="selectedRows"
            :headers="headers"
            :items="rows"
            show-select
            disable-pagination
            class="elevation-0 col"
          >
            <template v-slot:[`item.email`]="{ item }">
              <v-edit-dialog
                large
                persistent
                cancel-text="Peruuta"
                save-text="OK"
                @open="editText = item.email"
                @save="saveEmail(item)"
              >
                {{ item.email }}
                <template v-slot:input>
                  <v-text-field
                    label="Sähköposti"
                    v-model="editText"
                    class="pt-5"
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template
              v-for="slot in selectValues"
              v-slot:[getSlotKey(slot)]="{ item }"
            >
              <v-edit-dialog
                :key="slot.objectKey"
                large
                persistent
                cancel-text="Peruuta"
                save-text="OK"
                @open="editText = getObjectValue(item, slot.objectKey)"
                @save="saveSelect(item, slot.objectKey)"
              >
                {{ getObjectValue(item, slot.objectKey) }}
                <template v-slot:input>
                  <v-select
                    v-if="slot.type == 'select'"
                    :items="slot.values"
                    v-model="editText"
                    item-text="value"
                    item-value="key"
                    class="pt-5"
                  ></v-select>

                  <v-combobox
                    v-if="slot.type == 'combo'"
                    :items="slot.selections"
                    v-model="editText"
                    @update:search-input="updateCombo"
                    item-text="value"
                    item-value="value"
                    class="pt-5"
                  ></v-combobox>

                  <v-text-field
                    v-if="slot.type == 'text'"
                    v-model="editText"
                    class="pt-5"
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small @click="deleteRow(item)"> mdi-delete </v-icon>
            </template>
          </v-data-table>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="success"
          :loading="isLoading"
          @click="save"
          v-if="selectedRows.length > 0"
        >
          Tuo valitut</v-btn
        >
        <v-btn color="error" @click.stop="close">Sulje</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  components: {},
  computed: {
    ...mapGetters({
      survey: "survey/survey",
      surveyLoading: "survey/isLoading",
      voterLoading: "voter/isLoading",
    }),
    show: {
      get() {
        return this.dialog;
      },
      set(value) {
        if (!value) {
          this.rows = [];
          this.voterData = "";
          this.$emit("close");
        }
      },
    },
    headers: function () {
      let arr = [
        {
          text: "Sähköposti",
          value: "email",
        },
        {
          text: "Kieli",
          value: "languageCode",
        },
      ];

      arr = arr.concat(
        this.predefinedQuestions
          .filter((q) => {
            return this.survey.Options.Question360Role !== q.id;
          })
          .map((q) => {
            return {
              text: q.question,
              value: `answers[${q.id}]`,
            };
          })
      );

      arr = arr.concat({
        text: "",
        value: "actions",
        sortable: false,
      });

      return arr;
    },
    selfAssesmentSelection() {
      let roleQuestion = this.predefinedQuestions.find((q) => {
        return this.survey.Options.Question360Role == q.id;
      });

      if (roleQuestion) {
        let selfSelection = roleQuestion.selections.find(
          (s) => s.id === this.survey.Options.Selection360Self
        );

        if (self.Selection) {
          return selfSelection.selection;
        }
      }

      return "";
    },
    isLoading: function () {
      return this.surveyLoading || this.voterLoading;
    },
  },
  data: () => ({
    voterData: "",
    search: "",
    editText: "",
    rows: [],
    selectData: {},
    selectValues: [],
    selectedRows: [],
    index: 0,
    dialog: false,
  }),
  methods: {
    ...mapActions({
      getVoters: "voter/getVoters",
      postVoters: "voter/postVoters",
      postExternalSurveySelection: "survey/postExternalSurveySelection",
    }),
    updateCombo(s) {
      this.editText = s;
    },
    close() {
      this.rows = [];
      this.selectedRows = [];
      this.dialog = false;
    },
    async finishUpload() {
      await this.getVoters();
      this.show = false;
    },
    getSlotKey(slot) {
      return `item.${slot.objectKey}`;
    },
    getObjectValue(item, key) {
      let nested = key.match(/\[(.*)\]/);

      if (nested && nested.length > 0 && nested.index > 0) {
        var propertyKey = key.substring(0, nested.index);
        var nestedKey = nested[1];

        return item[propertyKey][nestedKey];
      }

      return item[key];
    },
    parseInput() {
      if (this.voterData) {
        var split = this.voterData.split(/\r?\n/);

        if (split && split.length > 0) {
          split.forEach((r) => {
            let first = r.split(" ")[0];
            let email = this.cleanEmail(first);

            if (email && email.length > 0) {
              let voter = {
                id: this.index++,
                email: email,
                languageCode: this.survey.DefaultLanguage,
                answers: {},
              };

              let name = email
                .split("@")[0]
                .replace(".", " ")
                .replace("_", " ");

              name = name
                .split(" ")
                .map((str) => {
                  return str.charAt(0).toUpperCase() + str.slice(1);
                })
                .join(" ");

              this.predefinedQuestions.map((q) => {
                let key = `${q.id}`;
                let answer = "";

                if (this.survey.Options.Question360Person === q.id) {
                  answer = name;
                } else if (this.survey.Options.Question360Role === q.id) {
                  answer = this.selfAssesmentSelection;
                }

                voter.answers[key] = answer;
              });

              this.rows.push(voter);
              this.selectedRows.push(voter);
            }
          });

          this.voterData = "";
        }
      }
    },

    saveEmail(data) {
      if (data && this.editText) {
        let email = this.cleanEmail(this.editText);

        if (email && email.length > 0) {
          data.email = email;
        }

        this.editText = "";
      }
    },

    saveSelect(data, key) {
      if (data && this.editText) {
        let value = this.editText.trim();

        let selectList = this.selectValues.find((s) => s.objectKey == key);

        if (selectList && selectList.locked == false && value.length > 0) {
          let old = selectList.selections.find((s) => s == value);

          if (!old) {
            selectList.selections.push(value);
          }
        }

        let nested = key.match(/\[(.*)\]/);

        if (nested && nested.length > 0 && nested.index > 0) {
          var propertyKey = key.substring(0, nested.index);
          var nestedKey = nested[1];

          data[propertyKey][nestedKey] = value;
        } else {
          data[key] = value;
        }
        this.editText = "";
      }
    },

    deleteRow(item) {
      let index = this.rows.findIndex((r) => r.id == item.id);
      let selectedIndex = this.selectedRows.findIndex((r) => r.id == item.id);

      if (index >= 0 && confirm("Oletko varma?")) {
        this.rows.splice(index, 1);

        if (selectedIndex >= 0) {
          this.selectedRows.splice(selectedIndex, 1);
        }
      }
    },

    async save() {
      let selections = [];
      let voters = [];

      this.selectedRows.map((r) => {
        let selection = r.answers[this.survey?.Options?.Question360Person];
        let email = r.email;

        if (selection && selection.length > 0 && email && email.length > 0) {
          let found = selections.find((s) => {
            return s.selection == selection;
          });

          let personInSurvey = this.predefinedQuestions
            .find((q) => q.id === this.survey.Options.Question360Person)
            .selections.find((s) => s.selection === selection);

          if (personInSurvey) {
            this.$emit(
              "error",
              `${selection} on lisätty jo arvioitavaksi henkilöksi. Muuta tarvittaessa nimeä.`
            );
            return;
          }

          if (!found) {
            selections.push({
              selection: selection,
              email: email,
            });
          }

          let voter = {
            voterEmail: email,
            languageCode: r.languageCode,
            answers: Object.keys(r.answers).reduce((acc, curr) => {
              if (r.answers[curr]) {
                acc[curr] = r.answers[curr];
              }
              return acc;
            }, {}),
          };

          voters.push(voter);
        }
      });

      if (voters.length > 0) {
        for (const index in selections) {
          let newSelection = {
            surveyGuid: this.survey.Guid,
            questionId: this.survey.Options.Question360Person,
            selection: selections[index].selection,
            email: selections[index].email,
          };

          await this.postExternalSurveySelection(newSelection);
        }

        await this.postVoters({
          surveyGuid: this.survey.Guid,
          voters: voters,
        });

        this.$emit(
          "setNewVoters",
          voters.map((v) => v.voterEmail)
        );

        this.close();
      }
    },
    setSelectValues() {
      let values = this.survey.Languages.map((l) => {
        return {
          key: l.Code,
          value: l.Language,
        };
      });
      this.selectValues.push({
        objectKey: "languageCode",
        locked: true,
        type: "select",
        values: values,
        selections: [],
      });
      let questions = this.predefinedQuestions.map((q) => {
        let locked =
          this.survey.Options.Question360Role === q.id ||
          this.survey.Options.Question360Person === q.id;
        let selections = q.selections.filter((s) => s.selection.length > 0);
        let type =
          this.survey.Options.Question360Person === q.id ? "text" : "combo";
        return {
          objectKey: `answers[${q.id}]`,
          locked: locked,
          type: type,
          values: selections.map((s) => {
            return {
              key: s.selection,
              value: s.selection,
            };
          }),
          selections: selections.map((s) => {
            return s.selection;
          }),
        };
      });
      this.selectValues = this.selectValues.concat(questions);
    },
  },
  created() {
    this.setSelectValues();
  },
  watch: {
    survey: function () {
      this.setSelectValues();
    },
  },
  props: {
    predefinedQuestions: Array,
  },
};
</script>
