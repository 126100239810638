<template>
  <div v-if="predefinedQuestions.length">
    <v-row
      ><v-col>
        <h3>Taustatiedot</h3>
        <h4>
          Voit nimetä vastaajien roolit uudestaan, poistaa tai lisätä niitä.
        </h4>
      </v-col></v-row
    >
    <div v-for="q in predefinedQuestions" :key="q.Id">
      <v-row>
        <v-col> {{ getQuestion(q.Id, q.question) }}: </v-col>
        <v-col>
          <v-row v-for="s in q.selections" :key="s.Id">
            <v-col class="py-0">
              <PredefinedSelection
                ref="predefined"
                :disabled="s.Id === survey.Options.Selection360Self"
                :label="s.originalSelection"
                :selection="getSelection(s.Id, s.selection)"
                :canDelete="
                  s.Id !== survey.Options.Selection360Self &&
                    s.Id !== survey.Options.Selection360Superior
                "
                :isDeleted="isDeleted(s.Id)"
                @update:selection="selectionChanged($event, s.Id)"
                @delete:selection="selectionDelete(s.Id)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="addedSelections.length">
        <v-col></v-col>
        <v-col>
          <v-row v-for="s in addedSelections" :key="s.Id">
            <v-col class="py-0">
              <PredefinedSelection
                ref="predefined"
                label="Uusi"
                :selection="s.selection"
                :canDelete="true"
                @update:selection="addedSelectionChanged($event, s.Id)"
                @delete:selection="addedSelectionDelete(s.Id)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col></v-col>
        <v-col class="py-0">
          <v-text-field
            v-model="newSelection"
            append-outer-icon="mdi-plus"
            @click:append-outer="selectionAdd"
            label="Lisää uusi"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PredefinedSelection from "@/components/Survey/PredefinedSelection";

export default {
  components: {
    PredefinedSelection,
  },
  computed: {
    ...mapGetters({
      survey: "survey/survey",
    }),
    isValid: function() {
      return (
        !this.$refs["predefined"] ||
        this.$refs["predefined"].reduce(
          (acc, curr) => acc && !curr.$v.$invalid,
          true
        )
      );
    },
  },
  data: function() {
    return {
      newSelection: "",
    };
  },
  methods: {
    getQuestion(id, originalQuestion) {
      let translation = this.language
        ? this.survey.SurveyQuestionTranslations.filter(
            (q) => q.Id === id && q.Code === this.language.Code
          )[0]?.Question ?? undefined
        : originalQuestion;
      return translation || originalQuestion;
    },
    getSelection(id, originalSelection) {
      let s = this.selections.find((sel) => sel.Id === id);
      return s?.selection || originalSelection;
    },
    selectionChanged(event, id) {
      this.selections.find((s) => s.Id === id).selection = event;
    },
    addedSelectionChanged(event, id) {
      this.addedSelections.find((s) => s.Id === id).selection = event;
    },
    selectionAdd() {
      if (this.newSelection.length === 0) {
        this.$emit("update:snackbarText", "Et voi lisätä tyhjää valintaa.");
        return;
      }
      this.$emit("add:selection", this.newSelection);
      this.newSelection = "";
    },
    selectionDelete(id) {
      this.$emit("delete:selection", id);
    },
    addedSelectionDelete(id) {
      this.$emit("delete:addedSelection", id);
    },
    isDeleted(id) {
      return this.deletedSelections.includes(id);
    },
  },
  props: {
    language: Object,
    predefinedQuestions: {
      type: Array,
      required: true,
    },
    selections: {
      type: Array,
      required: true,
    },
    addedSelections: {
      type: Array,
      required: true,
    },
    deletedSelections: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style></style>
