<template>
  <v-text-field
    v-model="questionQuestion"
    label="Kysymys"
    :prepend-icon="language ? undefined : 'mdi-delete'"
    required
    :error-messages="questionErrors"
    @input="input"
    @blur="$v.questionQuestion.$touch()"
    @click:prepend="removeNewTextQuestion(question.Id)"
  >
  </v-text-field>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  computed: {
    questionErrors() {
      const errors = [];
      if (!this.$v.questionQuestion.$dirty) return errors;
      !this.$v.questionQuestion.required && errors.push("Pakollinen");
      return errors;
    },
    isValid: function () {
      this.validate();
      return !this.$v.$invalid;
    },
  },
  data: function () {
    return {
      questionQuestion: this.question.Question,
    };
  },
  methods: {
    removeNewTextQuestion(id) {
      this.$delete(
        this.newTextQuestions,
        this.newTextQuestions.map((q) => q.Id).indexOf(id)
      );

      this.newTextQuestionTranslations.forEach((t) => {
        this.$delete(
          t.textQuestions,
          t.textQuestions.map((q) => q.Id).indexOf(id)
        );
      });
    },
    input(value) {
      this.question.Question = value;
      this.$v.questionQuestion.$touch();
    },
    validate() {
      this.$v.$touch();
    },
  },
  validations: {
    questionQuestion: {
      required,
    },
  },
  props: {
    question: { type: Object, required: true },
    language: Object,
    newTextQuestions: {
      type: Array,
      required: true,
    },
    newTextQuestionTranslations: {
      type: Array,
      required: true,
    },
    textQuestions: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style>
</style>