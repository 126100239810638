<template>
  <div v-if="isLoading" class="text-center">
    <v-progress-circular
      :size="50"
      color="info"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  computed: {},
  data() {
    return {};
  },
  props: {
    isLoading: Boolean,
  },
  watch: {},
};
</script>

<style></style>
