var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.overview && _vm.compareOverview)?_c('div',[_c('div',{staticClass:"mb-10"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"6"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.compareOverview.surveyName))]),_c('v-card-text',[_c('ReportSurveyOverview',{attrs:{"overview":_vm.compareOverview,"filter":_vm.filter}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","xl":"6"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.overview.surveyName))]),_c('v-card-text',[_c('ReportSurveyOverview',{attrs:{"overview":_vm.overview,"filter":_vm.filter}})],1)],1)],1)],1)],1),(_vm.showTabs)?_c('v-tabs',{attrs:{"color":"basil","grow":"","slider-size":"0","active-class":"custom-v-tabs-active-tab"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{on:{"click":_vm.tabClick}},[_vm._v(" "+_vm._s(`${_vm.$t("voter.report.show")} ${_vm.$t("voter.report.overall")}`)+" ")]),_vm._l((_vm.overview.predefined),function(item){return _c('v-tab',{key:item.id,on:{"click":_vm.tabClick}},[_vm._v(" "+_vm._s(`${_vm.$t("voter.report.show")} ${item.question}`)+" ")])})],2):_vm._e(),(_vm.showTabs)?_c('div',{staticClass:"custom-v-tabs-slider"}):_vm._e(),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('div',{staticClass:"d-flex justify-end"},[_c('v-switch',{attrs:{"label":_vm.$t('voter.report.showDescriptions')},model:{value:(_vm.showLegend),callback:function ($$v) {_vm.showLegend=$$v},expression:"showLegend"}})],1),_c('CompareReportAllTopBottom',{directives:[{name:"show",rawName:"v-show",value:(_vm.showGroup === 0 || _vm.showGroup === 1),expression:"showGroup === 0 || showGroup === 1"}],attrs:{"title":`${_vm.$t('voter.report.overall')} -
                ${_vm.compareOverview.surveyName}`,"overview":_vm.compareOverview,"showLegend":_vm.showLegend,"enableDrill":false}}),_c('CompareReportAllTopBottom',{directives:[{name:"show",rawName:"v-show",value:(_vm.showGroup === 0 || _vm.showGroup === 1),expression:"showGroup === 0 || showGroup === 1"}],attrs:{"title":`${_vm.$t('voter.report.overall')} -
                ${_vm.overview.surveyName}`,"overview":_vm.overview,"showLegend":_vm.showLegend,"enableDrill":false}}),_c('CompareBestWorst',{attrs:{"overview":_vm.overview,"compareOverview":_vm.compareOverview,"mapping":_vm.overview.reportCombinationJson}}),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('td'),_c('td',[_vm._v(" "+_vm._s(_vm.compareOverview.surveyName)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.overview.surveyName)+" ")])])]),_c('tbody',[_c('CompareRow',{attrs:{"survey":_vm.survey,"report":_vm.report,"overview":_vm.overview,"compareOverview":_vm.compareOverview,"overviewWithFilter":_vm.overviewWithFilter,"compareOverviewWithFilter":_vm.compareOverviewWithFilter,"item":_vm.overview.all[0],"all":true}}),_vm._l((_vm.overview.majorGroups),function(c){return _c('CompareRow',{key:c.id,attrs:{"item":c,"survey":_vm.survey,"report":_vm.report,"overview":_vm.overview,"compareOverview":_vm.compareOverview,"overviewWithFilter":_vm.overviewWithFilter,"compareOverviewWithFilter":_vm.compareOverviewWithFilter}})})],2)]},proxy:true}],null,false,62593267)})],1),_vm._l((_vm.overview.predefined),function(item){return _c('v-tab-item',{key:item.id},[_c('v-expansion-panels',{staticClass:"mt-3"},_vm._l((item.selections),function(selection){return _c('v-expansion-panel',{key:selection.id,style:({
            'border-right': '1px solid #f2f2f2',
            'border-left': '1px solid #f2f2f2',
          }),attrs:{"active-class":"active-panel"}},[_c('v-expansion-panel-header',{ref:`panel-header-${selection.id}`,refInFor:true,on:{"click":function($event){return _vm.clicked(item.id, selection.id)}}},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"3"}},[_vm._v(_vm._s(selection.selection))]),_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"3"}},[_c('span',{staticClass:"mx-10"},[_vm._v(_vm._s(_vm.compareSelectionAverage(item.id, selection.id).toFixed(2)))])]),_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"3"}},[_c('span',{staticClass:"mx-10"},[_vm._v(_vm._s(selection.average?.toFixed(2) ?? "0.00"))])]),_c('v-col',{staticClass:"d-flex align-center justify-end",style:({
                  color: _vm.getAnswerPercentageColor(
                    selection.answers,
                    selection.voters
                  ),
                }),attrs:{"cols":"3"}},[(
                    _vm.compareSelectionAverage(item.id, selection.id) <
                    (selection.average ?? 0)
                  )?_c('v-icon',{attrs:{"color":_vm.$config?.report?.chartColorGreen ?? '#4CAF50'}},[_vm._v("mdi-thumb-up")]):(
                    _vm.compareSelectionAverage(item.id, selection.id) >
                    (selection.average ?? 0)
                  )?_c('v-icon',{attrs:{"color":_vm.$config?.report?.chartColorRed ?? '#F44336'}},[_vm._v("mdi-thumb-down")]):_c('v-icon',[_vm._v("mdi-thumbs-up-down")])],1)],1)],1),_c('v-expansion-panel-content',[_c('LoadingIndicator',{attrs:{"isLoading":_vm.overviewWithFilterLoading}}),(!_vm.overviewWithFilterLoading)?_c('CompareBestWorst',{attrs:{"overview":_vm.overviewWithFilter,"compareOverview":_vm.compareOverviewWithFilter,"mapping":_vm.overview.reportCombinationJson}}):_vm._e(),(!_vm.overviewWithFilterLoading)?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('td'),_c('td',[_vm._v(" "+_vm._s(_vm.compareOverview.surveyName)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.overview.surveyName)+" ")]),_c('td',[_vm._v(_vm._s(_vm.$t("voter.report.trend")))])])]),_c('tbody',[_c('CompareRow',{attrs:{"item":_vm.overviewWithFilter.all[0],"all":true,"filter":true,"survey":_vm.survey,"report":_vm.report,"overview":_vm.overview,"compareOverview":_vm.compareOverview,"overviewWithFilter":_vm.overviewWithFilter,"compareOverviewWithFilter":_vm.compareOverviewWithFilter}}),_vm._l((_vm.overviewWithFilter.majorGroups),function(c){return _c('CompareRow',{key:c.id,attrs:{"item":c,"filter":true,"survey":_vm.survey,"report":_vm.report,"overview":_vm.overview,"compareOverview":_vm.compareOverview,"overviewWithFilter":_vm.overviewWithFilter,"compareOverviewWithFilter":_vm.compareOverviewWithFilter}})})],2)]},proxy:true}],null,true)}):_vm._e()],1)],1)}),1)],1)})],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }