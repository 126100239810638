<template>
  <div>
    <v-app-bar color="white" fixed>
      <v-row align="center" justify="center">
        <v-col md="10" lg="8">
          <v-toolbar elevation="0">
            <v-toolbar-title>
              <v-img :src="logo" class="logo" />
            </v-toolbar-title>

            <v-toolbar-items class="hidden-sm-and-down">
              <slot />
            </v-toolbar-items>
            <v-spacer></v-spacer>

            <div class="locale-changer">
              <v-btn
                v-for="lang in selectableLangs"
                :key="lang.lang"
                @click="languageClick(lang.lang)"
                text
              >
                <span :class="`fi fi-${lang.flag}`"></span>
              </v-btn>
            </div>
            <v-btn text class="hidden-sm-and-down">
              {{ user }}
            </v-btn>

            <v-toolbar-items class="hidden-md-and-up">
              <v-app-bar-nav-icon
                @click="drawer = !drawer"
              ></v-app-bar-nav-icon>
            </v-toolbar-items>
          </v-toolbar>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list nav class="py-0">
        <v-list-item>
          <v-list-item-title class="mt-3">
            <v-img :src="logo" class="logo" />
          </v-list-item-title>
        </v-list-item>

        <slot name="drawer"></slot>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import logo from "@/assets/mlp_logo.png";

export default {
  data: () => ({
    logo: logo,
    drawer: false,
    langs: [
      { lang: "en", flag: "gb" },
      { lang: "fi", flag: "fi" },
    ],
  }),
  computed: {
    selectableLangs: function () {
      return this.langs.filter((l) => {
        return l.lang != this.$i18n.locale;
      });
    },
  },
  methods: {
    languageClick(lang) {
      this.changeLanguage(lang);
      this.$emit("languageChanged");
    },
  },
  props: { user: { type: String } },
};
</script>

<style>
.v-toolbar__content .logo {
  max-width: 100px;
}

.v-navigation-drawer__content .logo {
  width: 100%;
}

.v-btn--active.no-active::before {
  opacity: 0 !important;
}

.no-hover::before {
  opacity: 0 !important;
}
</style>
