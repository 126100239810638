<template>
  <div>
    <div v-if="!isLoading && survey">
      <v-row v-if="noVoters" class="mt-3">
        <v-col>
          HUOM! Mittauksella ei ole vielä vastaajia. Valitse VASTAAJAT & JAKELU
          -painike.
        </v-col>
      </v-row>
      <v-row v-show="isLoadingStatus">
        <v-col>
          <LoadingIndicator :isLoading="isLoadingStatus" />
        </v-col>
      </v-row>
      <v-row v-show="!isLoadingStatus && showStatus">
        <v-col>
          <v-tabs
            v-model="tab"
            color="basil"
            grow
            active-class="custom-v-tabs-active-tab"
            slider-size="0"
          >
            <v-tab v-for="item in status" :key="item.id">
              {{ `${status.length > 1 ? "Näytä " : ""}${item.question}` }}
            </v-tab>
          </v-tabs>
          <div class="custom-v-tabs-slider"></div>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in status" :key="item.id">
              <div class="pt-5">
                <v-row v-for="selection in item.selections" :key="selection.id">
                  <v-col class="d-flex align-center" cols="3">{{
                    selection.selection
                  }}</v-col>
                  <v-col cols="6">
                    <v-progress-linear
                      :value="selection.answerPercentage * 100.0"
                      :color="
                        getAnswerPercentageColor(
                          selection.answers,
                          selection.voters
                        )
                      "
                      height="25"
                    >
                      <strong
                        >{{
                          Math.ceil(selection.answerPercentage * 100)
                        }}%</strong
                      >
                    </v-progress-linear>
                  </v-col>
                  <v-col cols="3" class="d-flex align-center justify-end"
                    >{{ selection.answers }} / {{ selection.voters }}</v-col
                  >
                </v-row>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </div>
    <LoadingIndicator :isLoading="isLoading" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import LoadingIndicator from "@/components/LoadingIndicator";

export default {
  data: () => ({
    tab: null,
    items: [],
  }),
  components: {
    LoadingIndicator,
  },
  computed: {
    ...mapGetters({
      survey: "survey/surveySimple",
      status: "report/status",
      isLoading: "survey/isLoading",
      isLoadingStatus: "report/isLoading",
      user: "auth/user",
    }),
    noVoters: function () {
      return (
        !this.survey || !this.survey.voterCount || this.survey.voterCount <= 0
      );
    },
    showStatus: function () {
      return (
        this.status && this.status.length > 0 && this.survey.voterCount > 0
      );
    },
  },
  methods: {
    getAnswerPercentageColor(answerCount, voterCount) {
      if (voterCount === 0) {
        return this.$config?.report?.chartColorRed || "red";
      }
      let percentage = (answerCount / voterCount) * 100;

      let highAnswerPercentageMin =
        this.$config?.report?.highAnswerPercentageMin ?? 66;
      let mediumAnswerPercentageMin =
        this.$config?.report?.mediumAnswerPercentageMin ?? 33;

      if (percentage >= highAnswerPercentageMin) {
        return this.$config?.report?.chartColorGreen || "green";
      } else if (percentage >= mediumAnswerPercentageMin) {
        return this.$config?.report?.chartColorYellow || "yellow";
      } else {
        return this.$config?.report?.chartColorRed || "red";
      }
    },
  },
  props: {
    showTitle: {
      default: true,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped></style>
