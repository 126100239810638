<template>
  <v-dialog v-model="showDialog" persistent width="720">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mobile-btn" color="info" dark v-bind="attrs" v-on="on">
        Lähetä viesti
      </v-btn>
    </template>

    <v-card>
      <v-card-title> Lähetä viesti vastaajille </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <LanguageSelector
          class="py-3"
          :languages="surveyEmailLanguages"
          :selectedLanguage="selectedLanguage"
          @update:selectedLanguage="$emit('update:selectedLanguage', $event)"
        />
        <VotersEmail
          class="pb-3"
          v-if="selectedLanguage"
          :emails="emails"
          :selectedLanguage="selectedLanguage"
          :showValidation="showEmailValidation"
          @emailChanged="$emit('emailChanged', $event)"
          @titleChanged="$emit('titleChanged', $event)"
        />

        <h3>Lähetysaika</h3>

        <v-container>
          <v-row>
            <v-col>
              <DatePickerInput :date.sync="sendAfterDate" :min="minDate" />
            </v-col>
            <v-col>
              <TimePickerInput :time.sync="sendAfterTime" />
            </v-col>
          </v-row>
        </v-container>
        <h3 class="mb-3">Muistutusviesti (päivää)</h3>
        <v-container>
          <v-row>
            <v-col>
              <v-slider
                v-model="reminderInDays"
                thumb-label="always"
                thumb-size="20"
                inverse-label
                :thumb-color="reminderInDays === 0 ? 'secondary' : 'primary'"
                :track-color="reminderInDays === 0 ? 'secondary' : 'primary'"
                max="30"
                min="0"
              ></v-slider>
            </v-col>
          </v-row>
        </v-container>

        <h3>Jakelu</h3>

        <v-container>
          <v-row>
            <v-col>
              Valittuna {{ selected.length }} viestien vastaanottajaa
            </v-col>
            <v-col v-if="Object.keys(selectedGrouped).length > 1">
              Kielet
              <div v-for="l in Object.keys(selectedGrouped)" :key="l">
                {{ `${l}: ${selectedGrouped[l].length}` }}
              </div>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-col>
              <p v-if="sendAfterDate === undefined">Viestit lähetetään heti</p>
              <p v-else>Viestit lähetetään {{ sendAfter.format("L LT") }}</p>
              <p v-if="reminderInDays > 0">
                Muistutusviestit lähetetään {{ reminderInDaysFormatted }}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="success"
          @click="$emit('sendEmails')"
          :loading="voterLoading"
        >
          Lähetä viestit
        </v-btn>
        <v-btn color="error" @click="close">Sulje</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import _groupBy from "lodash/groupBy";
import _orderBy from "lodash/orderBy";
import moment from "moment";

import LanguageSelector from "@/components/LanguageSelector";
import VotersEmail from "@/components/Voters/VotersEmail";
import DatePickerInput from "@/components/DatePickerInput";
import TimePickerInput from "@/components/TimePickerInput";

export default {
  components: {
    LanguageSelector,
    VotersEmail,
    DatePickerInput,
    TimePickerInput,
  },
  data: function () {
    return {
      showDialog: false,
      reminderInDays: 0,
      sendAfterDate: undefined,
      sendAfterTime: undefined,
      selected: this.selectedVoters,
      minDate: moment().format(),
    };
  },
  computed: {
    ...mapGetters({
      survey: "survey/survey",
      voterLoading: "voter/isLoading",
    }),
    showEmailValidation: function () {
      return (
        !!this.selectedLanguage &&
        this.selectedVoters
          .map((v) => v.languageCode)
          .includes(this.selectedLanguage.Code)
      );
    },
    surveyEmailLanguages: function () {
      return _orderBy(this.survey.Languages, ["IsDefault"], ["desc"]);
    },
    reminderInDaysFormatted: function () {
      let date = this.sendAfterDate ? moment(this.sendAfterDate) : moment();
      return date.add(this.reminderInDays, "day").format("L");
    },
    selectedGrouped: function () {
      return _groupBy(this.selected, "languageCode");
    },
    sendAfter: function () {
      let date = moment(this.sendAfterDate);
      if (this.sendAfterTime) {
        date.set("hour", this.sendAfterTime.split(":")[0]);
        date.set("minute", this.sendAfterTime.split(":")[1]);
      }

      return date;
    },
  },
  methods: {
    close() {
      this.showDialog = false;
    },
    titleChanged: function (value) {
      this.$emit("titleChanged", value);
    },
    emailChanged: function (html) {
      this.$emit("emailChanged", html);
    },
  },
  watch: {
    selected: function (newValue) {
      this.$emit("update:selectedVoters", newValue);
    },
    selectedVoters: function (newValue) {
      this.selected = newValue;
    },
    reminderInDays: function (newValue) {
      this.$emit("update:reminderInDays", newValue);
    },
    sendAfter: function (newValue) {
      this.$emit("update:sendAfter", newValue);
    },
    sendAfterDate: function (newValue) {
      if (newValue === undefined) {
        this.sendAfterTime = undefined;
      }
    },
  },
  props: {
    selectedVoters: { type: Array, required: true },
    selectedLanguage: { type: Object, required: true },
    emails: { type: Object, required: true },
  },
};
</script>

<style>
</style>