<template>
  <v-container v-if="voter360.voter">
    <v-row>
      <v-col>
        <v-text-field
          dense
          disabled
          outlined
          :label="voter360.question.question"
          v-model="voter360.selection.selection"
        />
        <v-text-field
          v-if="voter360.selection.email"
          dense
          disabled
          outlined
          label="Email"
          :value="voter360.selection.email"
        />
        <div v-if="!simpleView">
          <v-combobox
            v-for="q in predefinedQuestions.filter(
              (question) => !question.questionFor360 && !question.canEdit
            )"
            :disabled="voterLoading"
            v-model="selections[q.id]"
            :label="q.question"
            :key="q.id"
            :items="q.selections"
            item-text="selection"
            item-value="selection"
            @change="selectionChanged(voter360, q.id)"
          >
          </v-combobox>
          <v-btn
            :to="{
              name: 'voter',
              params: {
                guid: voter360.voter.voterGuid,
              },
              query: {
                lang: voter360.voter.languageCode.toLowerCase(),
              },
            }"
            exact
            target="_blank"
            color="success"
            >{{ $t("voter.personalView") }}</v-btn
          >
        </div>
      </v-col>
      <v-col>
        <v-card
          class="mb-1"
          outlined
          v-for="s in voter360.voters.filter(
            (s) => s.selection.id !== survey.Options.Selection360Self
          )"
          :key="s.id"
        >
          <v-card-title>
            <VoterRole
              ref="voter-role"
              :excludeRole="
                s.selection.id === survey.Options.Selection360Superior
              "
              :text="s.selection.selection"
              :count="s.emails.length"
            />
          </v-card-title>
          <v-card-text>
            <v-list flat dense class="pa-0">
              <v-list-item v-if="s.emails.length === 0" class="pa-0">
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("voter.noAssessors")
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-for="(o, oIdx) in s.emails"
                :key="oIdx"
                class="pa-0"
                :disabled="disableEditing || o.answered"
              >
                <v-list-item-icon>
                  <v-icon
                    >{{ o.answered ? "mdi-check" : "mdi-chevron-right" }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content
                  :style="{ cursor: 'pointer' }"
                  @click="$emit('setVoter', o)"
                >
                  <v-list-item-title
                    >{{ o.email }} ({{ o.languageCode }})</v-list-item-title
                  >
                  <v-list-item-subtitle
                    >{{ $t("general.added") }}: {{ formatDateTime(o.added) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="!disableEditing">
                  <v-btn icon @click="$emit('setVoter', o)"
                    ><v-icon>mdi-human-edit</v-icon></v-btn
                  >
                </v-list-item-action>
                <v-list-item-action v-if="!disableEditing">
                  <v-btn icon @click="$emit('deleteVoter', o)"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </v-list-item-action>
              </v-list-item>

              <v-form
                v-if="!disableAdd"
                :ref="`f-${voter360.selection.id}-${s.selection.id}`"
                class="d-flex align-center"
              >
                <Voters360AddVoterCombobox
                  ref="combobox"
                  v-if="!disableEditing"
                  :survey="survey"
                  :voter360="voter360"
                  :voterLoading="voterLoading"
                  :voterSelection="s"
                  :predefinedQuestions="predefinedQuestions"
                  :disableEditing="disableEditing"
                  @addVoter="$emit('addVoter', $event, voter360, s)"
                />
              </v-form>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Voters360AddVoterCombobox from "@/components/Voters/Voters360AddVoterCombobox";
import VoterRole from "@/components/Voters/VoterRole";

export default {
  components: {
    Voters360AddVoterCombobox,
    VoterRole,
  },
  computed: {
    isValid: function() {
      return this.$refs["voter-role"].every((v) => v.isValid);
    },
  },
  data: () => ({
    selections: {},
  }),
  methods: {
    selectionChanged(voter360, questionId) {
      // TODO: Tämän tarkastus, jostain syystä enter käyttää ainoastan tekstiä,
      //       mutta listalta valinta palauttaa objektin
      let selection =
        this.selections[questionId].selection ?? this.selections[questionId];
      this.$emit("predefinedChanged", voter360, questionId, selection);
    },
  },
  created() {
    // TODO: Kielen vaihto renderöi komponentin kahdesti. Tämän selvitys
    // Ilmeisesti survey prop ?
    if (this.voter360.voter) {
      this.selections = Object.assign(this.voter360.voter.answers);
    }
  },
  props: {
    voter360: { type: Object, required: true },
    predefinedQuestions: { type: Array, required: true },
    survey: { type: Object, required: true },
    voterLoading: { type: Boolean, required: true },
    disableEditing: { type: Boolean, default: false },
    simpleView: { type: Boolean, default: false },
    disableAdd: { type: Boolean, default: false },
  },
};
</script>

<style></style>
