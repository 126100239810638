<template>
  <v-dialog v-model="show" scrollable max-width="460px">
    <v-card>
      <v-card-title>Vaihda salasana</v-card-title>
      <v-card-text>
        <p>Anna uusi salasana. Salasanan minimipituus on 8 merkkiä.</p>
        <v-form @submit.prevent>
          <v-text-field
            :disabled="loading"
            label="Nykyinen salasana"
            prepend-icon="mdi-lock"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            required
            @keyup.enter="submit"
            v-model="form.password"
          ></v-text-field>

          <v-text-field
            :disabled="loading"
            label="Uusi salasana"
            prepend-icon="mdi-lock"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :color="isValidPassword(form.newPassword) ? 'success' : null"
            required
            @keyup.enter="submit"
            v-model="form.newPassword"
          ></v-text-field>

          <v-text-field
            :disabled="loading"
            label="Uusi salasana uudestaan"
            prepend-icon="mdi-lock"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :color="isValid ? 'success' : null"
            required
            @keyup.enter="submit"
            v-model="form.newPasswordAgain"
          ></v-text-field> </v-form
      ></v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          :disabled="loading || !isValid"
          @click="submit"
        >
          Tallenna
        </v-btn>
        <v-btn color="blue darken-1" text @click="show = false">
          Sulje
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "settings",
  components: {},
  data: () => ({
    form: {
      password: "",
      newPassword: "",
      newPasswordAgain: "",
    },
    loading: false,
    showPassword: false,
  }),
  computed: {
    show: {
      get() {
        return this.showDialog;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    ...mapGetters({
      authenticated: "auth/authenticated",
    }),
    isValid() {
      let old = this.form.password;
      let first = this.form.newPassword;
      let second = this.form.newPasswordAgain;

      return (
        old &&
        this.isValidPassword(first) &&
        this.isValidPassword(second) &&
        first === second
      );
    },
  },
  methods: {
    ...mapActions({
      changePassword: "auth/changepassword",
    }),
    resetForm() {
      this.form.password = "";
      this.form.newPassword = "";
      this.form.newPasswordAgain = "";
    },
    async submit() {
      if (this.isValid) {
        this.loading = true;

        axios
          .post(`${process.env.VUE_APP_API_URL}/auth/changepw`, this.form)
          .then((res) => {
            if (res) {
              this.$toast.center("Salasana vaihdettiin.");
              this.resetForm();
              this.dialog = false;
            } else {
              this.$toast.center("Salasanaa ei voitu vaihtaa.");
            }
            this.loading = false;
          })
          .catch(() => {
            this.$toast.center("Salasanaa ei voitu vaihtaa.");
            this.loading = false;
          });
      }
    },
  },
  props: {
    showDialog: Boolean,
  },
};
</script>
