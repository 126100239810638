<template>
  <v-col cols="12">
    <v-row
      ><v-col md="8" cols="6" :style="{ color: differenceColor }"
        ><h4>
          {{
            best
              ? $t("voter.report.positiveChanges")
              : $t("voter.report.negativeChanges")
          }}
        </h4></v-col
      >
      <v-col></v-col>
    </v-row>
    <v-row v-for="q in questions" :key="q.id">
      <v-col md="8" cols="6">{{ q.name }}</v-col>
      <v-col md="4" cols="6" class="d-flex align-center"
        ><v-row>
          <v-col class="d-flex justify-center">
            {{ q.oldAverage.toFixed(2) }}
          </v-col>
          <v-col class="d-flex justify-center">
            <v-icon :style="{ color: differenceColor }">{{ arrow }}</v-icon>
          </v-col>
          <v-col class="d-flex justify-center">
            {{ q.newAverage.toFixed(2) }}
          </v-col>
          <v-col class="d-flex justify-center">
            <span :style="{ color: differenceColor }">{{
              q.difference.toFixed(2)
            }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row></v-col
  >
</template>

<script>
const compareByName = function (a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const compareAvgBest = function (a, b) {
  if (a.oldAverage < b.oldAverage) {
    return -1;
  }
  if (a.oldAverage > b.oldAverage) {
    return 1;
  }
  return compareByName(a, b);
};

const compareAvgWorst = function (a, b) {
  if (a.oldAverage < b.oldAverage) {
    return -1;
  }
  if (a.oldAverage > b.oldAverage) {
    return 1;
  }
  return compareByName(a, b);
};

const compareBest = function (a, b) {
  if (a.difference > b.difference) {
    return -1;
  }
  if (a.difference < b.difference) {
    return 1;
  }
  return compareAvgBest(a, b);
};

const compareWorst = function compare(a, b) {
  if (a.difference < b.difference) {
    return -1;
  }
  if (a.difference > b.difference) {
    return 1;
  }
  return compareAvgWorst(a, b);
};

export default {
  props: {
    overview: Object,
    compareOverview: Object,
    mapping: String,
    best: { type: Boolean, required: true },
  },
  computed: {
    questions() {
      if (this.overview === null || this.compareOverview === null) {
        return [];
      }
      let mapping = JSON.parse(
        this.mapping ?? this.overview.reportCombinationJson
      ).surveys[0];
      let questions = [];

      this.overview.questions.forEach((q) => {
        let questionId = parseInt(q.id.split("_")[1]);
        let oldQuestionId = parseInt(
          Object.keys(mapping.questions).find(
            (key) => mapping.questions[key] === questionId
          )
        );
        let oldQuestion = this.compareOverview.questions.find(
          (q) => q.id === `q_${oldQuestionId}`
        );
        let obj = {
          id: q.id,
          name: q.name,
          oldAverage: oldQuestion?.average ?? 0,
          newAverage: q.average,
          difference: 0,
        };
        obj.difference = obj.newAverage - obj.oldAverage;
        if (obj.oldAverage !== 0) {
          questions.push(obj);
        }
      });

      questions.sort(this.best ? compareBest : compareWorst);

      return questions
        .filter((q) => {
          return this.best ? q.difference > 0 : q.difference < 0;
        })
        .slice(0, this.$config?.report?.compareBestWorstCount ?? 3);
    },
    differenceColor() {
      return this.best
        ? this.$config?.report?.chartColorGreen ?? "green"
        : this.$config?.report?.chartColorRed ?? "red";
    },
    arrow() {
      return this.best
        ? "mdi-arrow-top-right-thin"
        : "mdi-arrow-bottom-right-thin";
    },
  },
};
</script>

<style></style>
