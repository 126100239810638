var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"md":"10","lg":"8"}},[_c('v-card',{staticClass:"elevation-12 main-content"},[_c('v-card-title',[_vm._v(" Mittaukset ")]),_c('v-card-text',[_c('LoadingIndicator',{attrs:{"isLoading":!_vm.surveyLoading && _vm.isLoading}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading || (_vm.isLoading && _vm.surveyLoading)),expression:"!isLoading || (isLoading && surveyLoading)"}],staticClass:"search-text-field",attrs:{"label":"Haku: Voit hakea mittauksia sekä mittauksen että omistajan nimellä","clearable":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],staticClass:"header-row",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_vm._v("Mittaus")]),_c('v-col',{attrs:{"md":"2"}},[_vm._v("Yksikkö")]),_c('v-col',{attrs:{"md":"2"}},[_vm._v("Luotu")]),_c('v-col',{attrs:{"md":"2"}},[_vm._v("Tila")]),_c('v-col',{attrs:{"md":"2"}},[_vm._v("Vastauksia")])],1)],1),_c('v-expansion-panels',_vm._l((_vm.filteredExternalSurveys),function(survey){return _c('v-expansion-panel',{key:survey.id,attrs:{"active-class":"active-panel"}},[_c('v-expansion-panel-header',{ref:`s-${survey.guid}`,refInFor:true,on:{"click":function($event){return _vm.setSurvey(survey)}}},[_c('v-row',{staticClass:"epanel-listing"},[_c('v-col',{attrs:{"md":"4"}},[_c('strong',[_vm._v(_vm._s(survey.name))])]),_c('v-col',{attrs:{"md":"2"}},[_vm._v(" "+_vm._s(survey.options.unit)+" ")]),_c('v-col',{attrs:{"md":"2"}},[_vm._v(" "+_vm._s(_vm.formatDate(survey.createDate))+" ")]),_c('v-col',{attrs:{"md":"2"}},[_c('span',[_vm._v(_vm._s(survey.isOpen ? "Avoin" : "Valmis"))])]),_c('v-col',{style:({
                      color: _vm.getAnswerPercentageColor(
                        survey.answerCount,
                        survey.voterCount
                      ),
                    }),attrs:{"md":"2"}},[_vm._v(" "+_vm._s(`${survey.answerCount} / ${survey.voterCount} (${ survey.voterCount > 0 ? ( (survey.answerCount / survey.voterCount) * 100 ).toFixed(0) : 0 } %)`)+" ")])],1)],1),_c('v-expansion-panel-content',[(_vm.isLoading)?_c('LoadingIndicator',{attrs:{"isLoading":_vm.surveyLoading && _vm.isLoading}}):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"pr-0"},[(survey.options.owner)?_c('div',[_vm._v(" Hallinnoija: "+_vm._s(survey.options.owner)+" ")]):_vm._e()]),(_vm.openText.length > 0)?_c('v-col',{staticClass:"pl-0"},[_vm._v(" Aukiolo: "+_vm._s(_vm.openText)+" ")]):_vm._e()],1),(
                    _vm.selectedSurvey &&
                    _vm.selectedSurveyGuid == _vm.selectedSurvey.guid
                  )?_c('v-row',{staticClass:"text-center mt-3"},[_c('v-col',{staticClass:"d-sm-flex justify-sm-space-between"},[_c('v-btn',{staticClass:"v-btn-100 mobile-btn",attrs:{"color":"warning","large":"","dark":"","to":{
                        name: 'edit-survey',
                        params: { guid: _vm.selectedSurvey.guid },
                      }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Muokkaa mittausta ")],1),_c('v-btn',{staticClass:"v-btn-100 mobile-btn",attrs:{"color":"success","large":"","dark":"","to":{
                        name: 'voters',
                        params: { guid: _vm.selectedSurvey.guid },
                      }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-multiple")]),_vm._v(" Vastaajat & Jakelu ")],1),_c('v-btn',{staticClass:"v-btn-100 mobile-btn",attrs:{"color":"info","large":"","dark":"","to":{
                        name: 'report',
                        params: { guid: _vm.selectedSurvey.guid },
                      }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chart-line")]),_vm._v(" Tulokset ")],1),_c('v-btn',{staticClass:"v-btn-100 mobile-btn",attrs:{"large":"","to":{
                        name: 'repeat-survey',
                        params: { guid: _vm.selectedSurvey.guid },
                      }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-repeat")]),_vm._v(" Toista ")],1)],1)],1):_vm._e(),(
                    _vm.selectedSurvey &&
                    _vm.selectedSurveyGuid == _vm.selectedSurvey.guid
                  )?_c('SurveyOverview',{staticClass:"mb-5",attrs:{"showTitle":false}}):_vm._e()],1)],1)}),1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mobile-btn",attrs:{"color":"success","dark":"","large":"","to":{ name: 'new-survey' }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Luo uusi mittaus ")],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }