<template>
  <div>
    <VoterNavigation
      v-if="overview"
      :user="userEmail"
      @languageChanged="languageChanged"
    />
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col md="10" lg="8">
          <v-card class="elevation-12 main-content">
            <v-card-text>
              <LoadingIndicator
                :isLoading="isLoading && !overviewWithFilterLoading"
              />

              <CompareMain
                :survey="survey"
                :report="report"
                :overview="overview"
                :compareOverview="compareOverview"
                :overviewWithFilter="overviewWithFilter"
                :compareOverviewWithFilter="compareOverviewWithFilter"
                :showTabs="true"
                :overviewWithFilterLoading="overviewWithFilterLoading"
                @clicked="clicked"
              />

              <div v-if="notFound">
                <v-alert type="error">
                  {{ $t("voter.report.notFound") }}
                </v-alert>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                v-if="overview"
                color="secondary"
                :to="{
                  name: 'token-report',
                  params: { guid: this.$route.params.guid },
                }"
                >{{ $t("general.back") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar">{{ snackbarText }}</v-snackbar>
    </v-container>
  </div>
</template>

<style scoped>
.active-panel {
  background-color: #f6f6f6;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";

import LoadingIndicator from "@/components/LoadingIndicator";
import VoterNavigation from "@/components/VoterNavigation";
import CompareMain from "@/components/Compare/CompareMain";

export default {
  name: "token-report",
  mixins: [validationMixin],
  components: {
    LoadingIndicator,
    VoterNavigation,
    CompareMain,
  },
  data: () => ({
    overviewWithFilterLoading: false,
    snackbar: false,
    snackbarText: "",
    userEmail: "",
    notFound: false,
  }),
  computed: {
    ...mapGetters({
      reportIsLoading: "anonymous/reportLoading",
      surveyIsLoading: "anonymous/surveyLoading",
      survey: "anonymous/survey",
      report: "anonymous/report",
      overview: "anonymous/overview",
      compareOverview: "anonymous/compareOverview",
      overviewWithFilter: "anonymous/overviewWithFilter",
      compareOverviewWithFilter: "anonymous/compareOverviewWithFilter",
    }),
    isLoading() {
      return this.reportIsLoading || this.surveyIsLoading;
    },
  },
  methods: {
    ...mapActions({
      getExternalSurvey: "anonymous/getSurveyByReportToken",
      getReportOverview: "anonymous/getReportOverviewByReportToken",
      getCompareReportOverview:
        "anonymous/getCompareReportOverviewByReportToken",
      getReportOverviewWithFilter:
        "anonymous/getReportOverviewWithFilterByReportToken",
      getCompareReportOverviewWithFilter:
        "anonymous/getCompareReportOverviewWithFilterByReportToken",
      clearOverviewWithFilter: "anonymous/clearOverviewWithFilter",
      clearCompareOverviewWithFilter:
        "anonymous/clearCompareOverviewWithFilter",
    }),
    clicked: async function (questionId, selectionId) {
      this.clearOverviewWithFilter();
      this.clearCompareOverviewWithFilter();
      this.overviewWithFilterLoading = true;
      await this.getReportOverviewWithFilter({
        guid: this.$route.params.guid,
        questionId: questionId,
        selectionId: selectionId,
      });

      const json = JSON.parse(this.overview.reportCombinationJson);
      const compareSurveyQuestionId = Object.keys(
        json.surveys[0].questions
      ).find((k) => json.surveys[0].questions[k] === questionId);
      const compareSurveySelectionId = Object.keys(
        json.surveys[0].selections
      ).find((k) => json.surveys[0].selections[k] === selectionId);

      if (compareSurveySelectionId) {
        await this.getCompareReportOverviewWithFilter({
          guid: this.$route.params.guid,
          questionId: compareSurveyQuestionId,
          selectionId: compareSurveySelectionId,
        });
      }
      this.overviewWithFilterLoading = false;
    },
    async languageChanged() {
      this.$store.dispatch("anonymous/clearOverview", null);
      this.$store.dispatch("anonymous/clearCompareOverview", null);
      this.$store.dispatch("anonymous/clearOverviewWithFilter", null);
      this.$store.dispatch("anonymous/clearCompareOverviewWithFilter", null);
      await this.getExternalSurvey(this.$route.params.guid);
      this.userEmail = this.survey.Email;
      await this.getReportOverview(this.$route.params.guid);
      await this.getCompareReportOverview(this.$route.params.guid);
    },
  },
  watch: {
    snackbarText: function (newValue) {
      if (newValue) {
        this.snackbar = true;
        setTimeout(() => {
          this.snackbar = false;
          this.snackbarText = "";
        }, 2000);
      }
    },
  },
  async created() {
    this.changeLanguage("fi");

    await this.getExternalSurvey(this.$route.params.guid);

    if (this.survey) {
      this.userEmail = this.survey.Email;

      if (this.survey?.PreviousSurveyGuid) {
        await this.getReportOverview(this.$route.params.guid);
        await this.getCompareReportOverview(this.$route.params.guid);
      }
    } else {
      this.notFound = true;
    }
  },
  destroyed() {
    this.$store.dispatch("anonymous/clearOverview", null);
    this.$store.dispatch("anonymous/clearCompareOverview", null);
    this.$store.dispatch("anonymous/clearOverviewWithFilter", null);
    this.$store.dispatch("anonymous/clearCompareOverviewWithFilter", null);
  },
};
</script>
