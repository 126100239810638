<template>
  <VueFusionCharts
    :type="type"
    :width="width"
    :height="height"
    :dataFormat="dataFormat"
    :dataSource="dataSource"
    :id="getId()"
  >
  </VueFusionCharts>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

import VueFusionChartsComponent from "vue-fusioncharts/component";
import FusionCharts from "fusioncharts/core";
import Bar2d from "fusioncharts/viz/bar2d";
import Column2d from "fusioncharts/viz/column2d";
import MSBar2d from "fusioncharts/viz/msbar2d";
import MSColumn2d from "fusioncharts/viz/mscolumn2d";
import Pie2d from "fusioncharts/viz/pie2d";
import Radar from "fusioncharts/viz/radar";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

FusionCharts.options.license({
  key:
    "2mA4mpE-11E2B4G1B3I3A3C4E2F3B4E4D4rmeD7E4jlbA-8uG1B6d1paB2E3C1F3H3A8A5D4F4A3E3B2A7A32B13B9tkeG4H3D3mA9C11A5e1cvA2I3A1ptkE2B2G2phhC2B2C8B3E4B2C3H2C3B10C6ogaD3D2TF2wqe1B2E2B2qlE1GC3C1xzkB4D1H4C6D4A2F3F4H2B1B3D1A2C5s==",
  creditLabel: false,
});

const VueFusionCharts = VueFusionChartsComponent(
  FusionCharts,
  Bar2d,
  Column2d,
  Radar,
  MSBar2d,
  MSColumn2d,
  Pie2d,
  FusionTheme
);

export default {
  components: {
    VueFusionCharts,
  },
  data: () => ({}),
  computed: {},
  methods: {
    getId() {
      return `c-${uuidv4()}`;
    },
  },
  props: {
    type: { type: String, required: true },
    width: { type: String, required: true },
    height: { type: String, required: true },
    dataFormat: { type: String, default: "json" },
    dataSource: { type: Object, required: true },
  },
};
</script>

<style>
</style>