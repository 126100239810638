import Vue from "vue";
import * as moment from "moment";
import "moment/locale/fi";

const mixins = {
  data: () => ({
    minPasswordLength: 8,
  }),
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      window.$cookies.set("lang", lang);
    },
    formatDateTime(date) {
      var mDate = moment(date || null);
      return mDate.isValid() ? mDate.format("D.M.YYYY HH:mm") : "";
    },
    formatDate(date) {
      var mDate = moment(date || null);
      return mDate.isValid() ? mDate.format("L") : "";
    },
    split(input, sep) {
      sep = sep || ",";

      if (input && input.length > 0) {
        return input.split(sep);
      }

      return [];
    },
    isValidPassword(password) {
      return password && password.length >= this.minPasswordLength;
    },
    dynamicSort(property) {
      var sortOrder = 1;
      if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }
      return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result =
          a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
      };
    },
    dynamicSortMultiple() {
      /*
       * save the arguments object as it will be overwritten
       * note that arguments object is an array-like object
       * consisting of the names of the properties to sort by
       */
      var props = arguments;
      return function (obj1, obj2) {
        var i = 0,
          result = 0,
          numberOfProperties = props.length;
        /* try getting a different result from 0 (equal)
         * as long as we have extra properties to compare
         */
        while (result === 0 && i < numberOfProperties) {
          result = this.dynamicSort(props[i])(obj1, obj2);
          i++;
        }
        return result;
      };
    },
    cleanEmail(email) {
      return email
        ? email
            .toLowerCase()
            .trim()
            .replace(/[^a-zA-Z0-9_/@.-]/g, "")
        : "";
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },
  },
};

Vue.mixin(mixins);

export default mixins;
