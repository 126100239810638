<template>
  <div>
    <h3>Avoimet kysymykset</h3>

    <div class="text-questions">
      <v-row v-for="t in textQuestions" :key="t.Id">
        <v-col>
          <TextQuestion
            ref="textquestion"
            :question="t"
            :deletedTextQuestions="deletedTextQuestions"
            :language="language"
          />
        </v-col>
      </v-row>
      <h3 v-if="newTextQuestions.length > 0">Lisätyt avoimet kysymykset</h3>
      <v-row v-for="t in newTextQuestions" :key="t.Id">
        <v-col>
          <AddedTextQuestion
            ref="textquestion"
            :question="t"
            :language="language"
            :newTextQuestions="newTextQuestions"
            :newTextQuestionTranslations="newTextQuestionTranslations"
            :textQuestions="textQuestions"
          />
        </v-col>
      </v-row>
      <v-btn v-if="!language" color="success" @click="addNewTextQuestion"
        ><v-icon>mdi-plus</v-icon> Lisää uusi</v-btn
      >
    </div>
  </div>
</template>

<script>
import TextQuestion from "@/components/Survey/TextQuestion";
import AddedTextQuestion from "@/components/Survey/AddedTextQuestion";

export default {
  components: {
    TextQuestion,
    AddedTextQuestion,
  },
  computed: {
    isValid: function () {
      return this.$refs["textquestion"].reduce((acc, curr) => {
        return acc && curr.isValid;
      }, true);
    },
  },
  methods: {
    addNewTextQuestion() {
      const id =
        this.newTextQuestions.length === 0
          ? -1
          : Math.min(...this.newTextQuestions.map((q) => q.Id)) - 1;
      this.newTextQuestions.push({
        Question: "",
        Id: id,
      });
      this.newTextQuestionTranslations.forEach((l) => {
        l.textQuestions.push({
          Id: id,
          Question: "",
        });
      });
    },
  },
  props: {
    deletedTextQuestions: {
      type: Array,
      required: true,
    },
    language: Object,
    newTextQuestions: {
      type: Array,
      required: true,
    },
    newTextQuestionTranslations: {
      type: Array,
      required: true,
    },
    textQuestions: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style>
</style>