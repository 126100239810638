import Vue from "vue";

import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import mixins from "@/plugins/mixins";

import config from "@/config/config.json";
import "vue2-toast/lib/toast.css";
import Toast from "vue2-toast";
import i18n from "./i18n";

import VueCookies from "vue-cookies";

Vue.use(Toast);

require("@/store/subscriber");
require("@/utils/axiosSetup");

Vue.config.productionTip = false;

Vue.prototype.$config = config;

store
  .dispatch("auth/getCurrentUser", localStorage.getItem("token"))
  .then(() => {
    new Vue({
      mixins: mixins,
      router,
      store,
      vuetify,
      VueCookies,
      i18n,
      render: (h) => h(App),
    }).$mount("#app");
  });
