import axios from "axios";

export default {
  namespaced: true,

  state: {
    token: null,
    user: null,
  },

  getters: {
    authenticated(state) {
      return state.token !== null && state.user !== null;
    },
    user(state) {
      return state.user;
    },
    token(state) {
      return state.token;
    },
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, user) {
      state.user = user;
    },
  },

  actions: {
    async login({ dispatch }, credentials) {
      try {
        let response = await axios.post(
          `${process.env.VUE_APP_API_URL}/auth/authenticate`,
          credentials
        );
        await dispatch("getCurrentUser", response.data);
        return true;
      } catch (e) {
        return false;
      }
    },
    async reset({ dispatch }, data) {
      try {
        let response = await axios.post(
          `${process.env.VUE_APP_API_URL}/auth/reset`,
          data
        );
        await dispatch("getCurrentUser", response.data);
        return true;
      } catch (e) {
        return false;
      }
    },
    async refreshToken({ dispatch }) {
      try {
        let response = await axios.post(
          `${process.env.VUE_APP_API_URL}/auth/refresh-token`
        );
        await dispatch("getCurrentUser", response.data);
      } catch (e) {
        return false;
      }
    },
    async getCurrentUser({ commit, state }, token) {
      if (token) {
        commit("SET_TOKEN", token);
      }

      if (!state.token) {
        return;
      }

      try {
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/auth/me`
        );
        commit("SET_USER", response.data);
      } catch (e) {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
      }
    },
    logout({ commit, state }) {
      return axios
        .post(`${process.env.VUE_APP_API_URL}/auth/revoke-token`, state.token)
        .then(() => {
          commit("SET_TOKEN", null);
          commit("SET_USER", null);
        });
    },
  },
};
