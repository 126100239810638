import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth";
import survey from "./survey";
import voter from "./voter";
import report from "./report";
import anonymous from "./anonymous";
import user from "./user";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: auth,
    survey: survey,
    voter: voter,
    report: report,
    // TODO: Tämän erotus ja viedään pelkästään tämä henkilökohtaiseen näkymään ?
    anonymous: anonymous,
    user: user,
  },
});
