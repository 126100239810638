<template>
  <div>
    <LoadingIndicator :isLoading="isLoading" />
    <div>
      <v-row v-if="selectedGroup">
        <v-col md="12">
          <div class="d-flex align-center justify-space-between mb-3">
            <span :style="{ fontSize: 'large' }" v-if="title">{{ title }}</span>
          </div>
          <v-breadcrumbs v-if="groups.length > 1" :items="groups" large>
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                @click="buttonClicked(item, groups.indexOf(item))"
              >
                <span
                  :class="
                    groups.indexOf(item) === groups.length - 1
                      ? 'green--text text-uppercase pointer'
                      : 'pointer text-decoration-underline'
                  "
                  >{{
                    topBottom && groups.indexOf(item) === 1
                      ? $t("voter.report.bestAndWorst")
                      : item.name
                  }}</span
                >
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>

          <div v-for="(group, gIdx) in selectedGroup" :key="group.id">
            <div v-if="showRow(gIdx, selectedGroup.length)">
              <v-row>
                <v-col md="12">
                  <StackedBar2D
                    v-if="$config.report"
                    :group="group"
                    width="100%"
                    height="110"
                    :dataSource="
                      getDataSource(group, gIdx, selectedGroup.length)
                    "
                    @groupClicked="groupClicked($event)"
                  />
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import LoadingIndicator from "@/components/LoadingIndicator";
import StackedBar2D from "@/components/Report/StackedBar2dChart";

export default {
  components: {
    LoadingIndicator,
    StackedBar2D,
  },
  computed: {
    ...mapGetters({
      isLoading: "report/isLoading",
    }),
    selectedGroup: function () {
      if (this.selected) {
        let all = this.allGroups;
        let sel = all.find((a) => a.id === this.selected);
        return all.filter((a) => sel.children.includes(a.id));
      }
      return this.mainGroup;
    },
  },
  data: function () {
    return {
      groups: this.groupsProp || [
        { id: "c_0", name: this.$i18n.t("voter.report.all") },
      ],
      selected: undefined,
    };
  },
  methods: {
    groupClicked(group) {
      this.$emit("groupClicked", group);
      if (group.children.length > 0) {
        this.groups.push(group);
        this.selected = group.id;
      }
    },
    buttonClicked(group, idx) {
      if (idx === 0) {
        this.$emit("groupClicked");
      }
      this.groups = this.groups.slice(0, idx + 1);
      if (idx > 0) {
        this.selected = group.id;
      } else {
        this.selected = undefined;
      }
    },
    showRow(index, count) {
      let colorize = Math.round(count / 4);
      let show = index + 1 <= colorize || count - index <= colorize;
      return (
        !this.topBottom ||
        !this.selected ||
        this.selected.startsWith("q_") ||
        show
      );
    },
    getTrendlineDisplayValue(group, r) {
      let value = 0;
      if (group.id.startsWith("q_")) {
        value = group.roundedSelectionDistribution[r] || 0;
      } else {
        let count = Object.keys(group.roundedSelectionDistribution).reduce(
          (a, c) => {
            return group.roundedSelectionDistribution[c] + a;
          },
          0
        );
        if (count > 0) {
          value = (
            ((group.roundedSelectionDistribution[r] || 0) / count) *
            100
          ).toFixed(0);
        }
      }

      return `${r} (${value}${group.id.startsWith("q_") ? "" : " %"})`;
    },
    getRowColor(groupIdx, groupCount, isLight = false) {
      if (groupCount === 1) {
        return isLight
          ? this.$config?.report?.chartColorBlueLight
          : this.$config?.report?.chartColorBlue ?? "#2196F3";
      }
      let colorize = Math.round(groupCount / 4);
      if (groupIdx + 1 <= colorize) {
        return isLight
          ? this.$config?.report?.chartColorGreenLight
          : this.$config?.report?.chartColorGreen ?? "#4CAF50";
      } else if (groupCount - groupIdx <= colorize) {
        return isLight
          ? this.$config?.report?.chartColorRedLight
          : this.$config?.report?.chartColorRed ?? "#F44336";
      } else {
        return isLight
          ? this.$config?.report?.chartColorYellowLight
          : this.$config?.report?.chartColorYellow || "#FFEB3B";
      }
    },
    getDataSource(group, groupIdx, groupCount) {
      return {
        chart: {
          bgColor: this.bgColor || "#ffffff",
          caption: "",
          chartLeftMargin: 0,
          chartRightMargin: 40,
          canvasBottomMargin: 50,
          plotSpacePercent: 40,
          plotBorderColor: "#ffffff",
          plottooltext: `$seriesname`,
          showPlotBorder: true,
          showYAxisValues: false,
          subCaption: "",
          theme: "fusion",
          yAxisname: "",
          yAxisMaxValue: group.max,
          yAxisMinValue: 0,
          showLegend: this.showLegend,
          legendItemFontSize: 15,
          legendPosition: "absolute",
          legendXPosition: 0,
          legendYPosition: 110,
        },
        categories: [
          {
            category: [{ label: false }],
          },
        ],
        dataset: [
          {
            seriesname: `${this.$i18n.t(
              "voter.report.stdDev"
            )} (${group.stdDev.toFixed(2)})`,
            color: this.getRowColor(groupIdx, groupCount, true),
            data: [
              {
                value: group.stdDev,
              },
            ],
          },
          {
            seriesname: `${this.$i18n.t(
              "voter.report.average"
            )} (${group.average.toFixed(2)})`,
            data: [
              {
                value: group.average - group.stdDev,
              },
            ],
            color: this.getRowColor(groupIdx, groupCount),
          },
        ],
        trendlines: [
          {
            line: group.roundedSelections.map((r, rIdx, arr) => ({
              startvalue: r,
              displayvalue: this.getTrendlineDisplayValue(group, r),
              thickness: Math.round(group.median) === r ? 5 : 1,
              showOnTop: rIdx + 1 === arr.length,
            })),
          },
        ],
      };
    },
  },
  props: {
    title: String,
    mainGroup: Array,
    allGroups: Array,
    groupsProp: Array,
    topBottom: Boolean,
    showLegend: Boolean,
    bgColor: String,
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}

.v-breadcrumbs {
  padding: 18px 0;
}
</style>
