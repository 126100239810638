<template>
  <v-container>
    <v-row
      ><v-col>
        <ReportCalculationGroups
          v-if="!overviewWithFilterLoading"
          :title="`${$t('voter.report.subAreas')} ${selection.selection}`"
          :overview="overviewWithFilter"
          :showLegend="showLegend"
          @groupClicked="groupClicked"
          :bgColor="bgColor"
        /> </v-col
    ></v-row>
  </v-container>
</template>

<script>
import ReportCalculationGroups from "@/components/Report/ReportCalculationGroups";

export default {
  components: {
    ReportCalculationGroups,
  },
  data: function() {
    return {
      reportDialog: false,
    };
  },
  methods: {
    groupClicked(...args) {
      this.$emit("groupClicked", ...args);
    },
  },
  props: {
    survey: Object,
    report: Array,
    item: Object,
    selection: Object,
    reportTemplates: Array,
    overviewWithFilter: Object,
    overviewWithFilterLoading: Boolean,
    reportIsLoading: Boolean,
    showLegend: { type: Boolean, default: false },
    bgColor: String,
  },
};
</script>

<style></style>
