import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store";

import Login from "../views/Login";
import NotFound from "../views/404";
import NewSurvey from "../views/NewSurvey";
import Survey from "../views/Survey";
import EditSurvey from "../views/EditSurvey";
import Surveys from "../views/Surveys";
import Report from "../views/Report";
import Voters from "../views/Voters";
import Voter from "../views/Voter";
import Forgot from "../views/Forgot";
import Reset from "../views/Reset";
import TokenReport from "../views/TokenReport";
import TokenCompare from "../views/TokenCompare";
import RepeatSurvey from "../views/RepeatSurvey";
import Compare from "../views/Compare";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    redirect: { name: "surveys" },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "Kirjaudu sisään",
    },
  },
  {
    path: "/forgot",
    name: "forgot",
    component: Forgot,
    meta: {
      title: "Palauta käyttäjätunnus",
    },
  },
  {
    path: "/reset/:token",
    name: "reset",
    component: Reset,
    meta: {
      title: "Palauta käyttäjätunnus",
    },
  },
  {
    path: "/voter/:guid",
    name: "voter",
    component: Voter,
    title: "",
  },
  {
    path: "/results/:guid",
    name: "token-report",
    component: TokenReport,
    title: "",
  },
  {
    path: "/results/compare/:guid",
    name: "token-compare",
    component: TokenCompare,
    title: "",
  },
  {
    path: "/surveys/:guid?",
    name: "surveys",
    component: Surveys,
    meta: {
      requiresAuth: true,
      title: "Mittaukset",
    },
  },
  {
    path: "/new-survey",
    name: "new-survey",
    component: NewSurvey,
    meta: {
      requiresAuth: true,
      title: "Uusi mittaus",
    },
  },
  {
    path: "/survey/:guid",
    name: "survey",
    component: Survey,
    meta: {
      requiresAuth: true,
      title: "Mittaus",
    },
  },
  {
    path: "/edit-survey/:guid",
    name: "edit-survey",
    component: EditSurvey,
    meta: {
      requiresAuth: true,
      title: "Muokkaa mittausta",
    },
  },
  {
    path: "/voters/:guid",
    name: "voters",
    component: Voters,
    meta: {
      requiresAuth: true,
      title: "Vastaajat",
    },
  },
  {
    path: "/report/:guid",
    name: "report",
    component: Report,
    meta: {
      requiresAuth: true,
      title: "Tulokset",
    },
  },
  {
    path: "/repeat-survey/:guid",
    name: "repeat-survey",
    component: RepeatSurvey,
    meta: {
      requiresAuth: true,
      title: "Toista mittaus",
    },
  },
  {
    path: "/compare/:guid",
    name: "compare",
    component: Compare,
    meta: {
      requiresAuth: true,
      title: "Vertailuraportti",
    },
  },
  {
    path: "*",
    name: "404",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// navigation guard to check for logged in users
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  if (requiresAuth && !store.getters["auth/authenticated"]) {
    next("/login");
  } else {
    next();
  }
});

const DEFAULT_TITLE = "MLP360";

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title
      ? `${DEFAULT_TITLE} - ${to.meta.title}`
      : DEFAULT_TITLE;
  });
});

export default router;
