<template>
  <v-dialog v-model="showDialog" persistent width="720">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mobile-btn" color="info" dark v-bind="attrs" v-on="on">
        Lähetä viesti
      </v-btn>
    </template>

    <v-card>
      <v-card-title> Lähetä viesti arvioitaville henkilöille </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <LanguageSelector
          class="py-3"
          :languages="surveyEmailLanguages"
          :selectedLanguage="selectedLanguage"
          @update:selectedLanguage="$emit('update:selectedLanguage', $event)"
        />
        <v-text-field
          label="Otsikko"
          :rules="[rules.required]"
          v-model="emailTitle"
        ></v-text-field>
        <quillEditor
          class="editor mt-3"
          ref="myQuillEditor"
          :options="editorOption"
          v-model="emailBody"
        />
        <div v-if="selected.length">
          {{ selected.length }} vastaanottajaa valittu
        </div>
        <v-divider></v-divider>
        <v-select
          v-model="selected"
          :items="voters360.map((v) => v.voter)"
          multiple
          label="Valitse henkilöt"
          item-text="voterEmail"
          item-value="voterCode"
          class="mb-2"
          @change="$emit('selectChange', $event)"
        >
          <v-list-item slot="prepend-item" ripple @click="$emit('toggle')">
            <v-list-item-action>
              <v-icon :color="selected.length > 0 ? 'indigo darken-4' : ''">{{
                icon
              }}</v-icon>
            </v-list-item-action>
            <v-list-item-title>Valitse kaikki</v-list-item-title>
          </v-list-item>
          <v-divider slot="prepend-item" class="mt-2" />
        </v-select>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer />
        <v-btn color="success" @click="sendEmails" :loading="voterLoading">
          Lähetä viestit
        </v-btn>
        <v-btn color="error" @click="close">Sulje</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { mapGetters } from "vuex";
import { quillEditor } from "vue-quill-editor";
import _orderBy from "lodash/orderBy";

import LanguageSelector from "@/components/LanguageSelector";

export default {
  components: {
    quillEditor,
    LanguageSelector,
  },
  data: function() {
    return {
      showDialog: false,
      emailTitle: this.emails[this.selectedLanguage.Code].title,
      emailBody: this.emails[this.selectedLanguage.Code].body,
      selected: this.selectedVoters,
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons

            [{ list: "ordered" }, { list: "bullet" }],

            [{ header: [1, 2, 3, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme

            ["clean"],
          ],
        },
        placeholder:
          "Kirjoita tähän sähköpostiviesti henkilöille, joita olet pyytänyt vastaajiksi. Linkki henkilökohtaiselle sivulle lisätään viestin loppuun automaattisesti.",
      },
      rules: {
        required: (value) => !!value || "Pakollinen kenttä",
      },
    };
  },
  computed: {
    ...mapGetters({
      survey: "survey/survey",
    }),
    allSelected() {
      return this.selectedVoters.length === this.voters360.length;
    },
    someSelected() {
      return this.selectedVoters.length > 0 && !this.allSelected;
    },
    icon() {
      if (this.allSelected) return "mdi-close-box";
      if (this.someSelected) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    surveyEmailLanguages: function() {
      return _orderBy(this.survey.Languages, ["IsDefault"], ["desc"]);
    },
  },
  methods: {
    close() {
      this.showDialog = false;
    },
    sendEmails() {
      if (this.selected.length === 0) {
        this.$emit("error", "Et ole valinnut yhtään vastaanottajaa.");
        return;
      }
      this.$emit("sendEmails");
    },
  },
  watch: {
    selected: function(newValue) {
      this.$emit("update:selectedVoters", newValue);
    },
    selectedVoters: function(newValue) {
      this.selected = newValue;
    },
    showDialog: function(newValue) {
      this.$emit("showDialogChanged", newValue);
    },
    show: function(newValue) {
      this.showDialog = newValue;
    },
    selectedLanguage: function(lang) {
      this.emailBody = this.emails[lang.Code].body;
      this.emailTitle = this.emails[lang.Code].title;
    },
    emailTitle: function(newValue) {
      this.$emit("emailTitleChanged", newValue);
    },
    emailBody: function(newValue) {
      this.$emit("emailBodyChanged", newValue);
    },
  },
  props: {
    selectedVoters: { type: Array, required: true },
    selectedLanguage: { type: Object, required: true },
    voters360: { type: Array, required: true },
    voterLoading: { type: Boolean, required: true },
    show: { type: Boolean, default: false },
    emails: { type: Object, required: true },
  },
};
</script>

<style></style>
