<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col md="10" lg="8">
        <v-card class="elevation-12 main-content">
          <v-card-title v-if="survey">
            <span>{{ survey.Name }}</span>
          </v-card-title>
          <v-card-text>
            <SurveyOverview v-if="survey" />
            <LoadingIndicator :isLoading="isLoading" v-else />
          </v-card-text>
          <v-card-actions v-if="survey">
            <v-row>
              <v-col class="d-sm-flex justify-sm-space-between">
                <v-btn
                  color="warning"
                  class="v-btn-100"
                  large
                  dark
                  :to="{
                    name: 'edit-survey',
                    params: { guid: this.survey.Guid },
                  }"
                >
                  <v-icon left>mdi-pencil</v-icon> Muokkaa mittausta
                </v-btn>
                <v-btn
                  color="success"
                  class="v-btn-100"
                  large
                  dark
                  :to="{ name: 'voters', params: { guid: this.survey.Guid } }"
                >
                  <v-icon left>mdi-account-multiple</v-icon> Vastaajat & Jakelu
                </v-btn>
                <v-btn
                  color="info"
                  class="v-btn-100"
                  large
                  dark
                  :to="{ name: 'report', params: { guid: this.survey.Guid } }"
                >
                  <v-icon left>mdi-chart-line</v-icon> Tulokset
                </v-btn>
              </v-col>
            </v-row>
            <div class="d-flex justify-space-between"></div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">{{ snackbarText }}</v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import SurveyOverview from "@/components/Survey/SurveyOverview";
import LoadingIndicator from "@/components/LoadingIndicator";

export default {
  name: "survey",
  data: () => ({
    snackbar: false,
    snackbarText: "",
  }),
  computed: {
    ...mapGetters({
      survey: "survey/survey",
      isLoading: "survey/isLoading",
    }),
  },
  components: {
    LoadingIndicator,
    SurveyOverview,
  },
  methods: {
    ...mapActions({
      getExternalSurvey: "survey/getExternalSurvey",
      getSurveyStatus: "report/getStatus",
    }),
  },
  async created() {
    await this.getExternalSurvey(this.$route.params.guid);
    await this.getSurveyStatus(this.$route.params.guid);
  },
  destroyed() {
    this.$store.dispatch("survey/clearSurvey", null);
  },
};
</script>

<style scoped></style>
