<template>
  <BaseChartComponent
    :type="type"
    :width="width"
    :height="height"
    :dataFormat="dataFormat"
    :dataSource="dataSource"
  />
</template>

<script>
import BaseChartComponent from "@/components/Report/Charts/BaseChartComponent";

export default {
  components: {
    BaseChartComponent,
  },
  computed: {
    dataSource: function () {
      return {
        chart: {
          caption: "",
          xaxisname: "",
          yaxisname: "",
          theme: "fusion",
          yAxisMaxValue: this.reportItem.Chart1.Series[0]?.MaxValue,
          yAxisMinValue: this.reportItem.Chart1.Series[0]?.MinValue,
        },
        categories: [
          {
            category: this.reportItem.Chart1.Series[0].Values.map((v) => ({
              label: v.Label,
            })),
          },
        ],
        dataset: this.reportItem.Chart1.Series.map((s) => ({
          seriesname: s.Label,
          color:
            this.reportItem.Chart1.Series.length === 1
              ? this.$config?.report?.chartColorBlue || "#2196F3"
              : "",
          data: s.Values.map((v) => ({
            value: v.Value,
          })),
        })),
      };
    },
  },
  data: () => ({
    dataFormat: "json",
    type: "msbar2d",
  }),
  methods: {},
  props: {
    width: { type: String, required: true },
    height: { type: String, required: true },
    reportItem: { type: Object, required: true },
  },
};
</script>

<style></style>
