<template>
  <div>
    <h3>Taustatietojen hallinta</h3>

    <v-tabs
      color="basil"
      slider-size="0"
      active-class="custom-v-tabs-active-tab"
    >
      <v-tab
        v-for="(question, qIdx) in editableQuestions"
        @click="tab = qIdx + 1"
        :key="question.id"
        >{{ question.question }}</v-tab
      >
    </v-tabs>
    <div class="custom-v-tabs-slider"></div>
    <div v-for="(question, qIdx) in editableQuestions" :key="question.id">
      <!-- TODO: Tama jarkevammin ? -->
      <div v-if="tab === qIdx + 1 && newSelections[question.id]">
        <v-row v-if="!question.questionFor360">
          <v-col md="12">
            <form
              @submit.prevent="addSelection(question)"
              class="d-flex justify-space-around align-center"
            >
              <v-text-field
                class="mr-3"
                :disabled="surveyLoading || voterLoading"
                :placeholder="question.question"
                v-model="newSelections[question.id].selection"
              ></v-text-field>
              <v-btn
                type="submit"
                color="success"
                :disabled="surveyLoading || voterLoading"
                :loading="surveyLoading || voterLoading"
                >Lisää</v-btn
              >
            </form>
          </v-col>
        </v-row>
        <div>
          <v-data-table
            :headers="[
              { text: question.question, value: 'selection' },
              { text: '', value: 'actions' },
            ]"
            :items="question.selections"
          >
            <template v-slot:[`item.selection`]="{ item }">
              <v-edit-dialog
                large
                cancel-text="Peruuta"
                save-text="OK"
                persistent
                @save="
                  editSelection(
                    Object.assign({}, item, {
                      questionId: question.id,
                      question: question.question,
                      questionFor360: question.questionFor360,
                    })
                  )
                "
                @open="setEditing(item)"
                @close="clearEditing"
                @cancel="clearEditing"
              >
                {{ item.selection }}
                <template v-slot:input>
                  <v-text-field
                    v-model="editing"
                    :label="question.question"
                    :rules="[rules.required]"
                    class="pt-5"
                    autofocus
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="pa-0 d-flex justify-end">
                <v-icon small @click="deleteSelection(item)">
                  mdi-delete
                </v-icon>
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <v-snackbar v-model="snackbar">{{ snackbarText }}</v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  created() {
    this.predefinedQuestions
      .filter((q) => q.isChoice && !q.canEdit)
      .forEach((q) => {
        this.newSelections[q.id] = {
          selection: "",
        };
      });
  },
  computed: {
    ...mapGetters({
      survey: "survey/survey",
      surveyLoading: "survey/isLoading",
      voterLoading: "voter/isLoading",
    }),
    theQuestion: function () {
      return this.predefinedQuestions.find((q) => q.canEdit);
    },
    editableQuestions: function () {
      return this.predefinedQuestions.filter((q) => q.isChoice && !q.canEdit);
    },
  },
  data: () => ({
    editing: undefined,
    newSelections: {},
    rules: {
      required: (value) => !!value || "Pakollinen kenttä",
    },
    tab: 1,
    snackbar: false,
    snackbarText: "",
  }),
  methods: {
    ...mapActions({
      getExternalSurvey: "survey/getExternalSurvey",
      postExternalSurveySelection: "survey/postExternalSurveySelection",
      putExternalSurveySelection: "survey/putExternalSurveySelection",
      deleteExternalSurveySelection: "survey/deleteExternalSurveySelection",
      getVoters: "voter/getVoters",
      postVoters: "voter/postVoters",
    }),
    setEditing(item) {
      this.editing = item.selection;
    },
    clearEditing() {
      this.editing = undefined;
    },
    async addSelection(question) {
      const newSelection = this.newSelections[question.id];
      if (!newSelection) {
        return;
      }

      await this.postExternalSurveySelection({
        surveyGuid: this.survey.Guid,
        questionId: question.id,
        selection: newSelection.selection,
      });

      this.getExternalSurvey(this.survey.Guid);

      this.newSelections[question.id].selection = "";
    },
    async editSelection(item) {
      if (this.editing.length === 0) {
        this.snackbarText = `${item.question} ei voi olla tyhjä`;
        this.snackbar = true;
        return;
      }
      await this.putExternalSurveySelection({
        surveyGuid: this.survey.Guid,
        questionId: item.questionId,
        id: item.id,
        selection: this.editing,
      });

      this.getExternalSurvey(this.survey.Guid);

      this.getVoters(this.survey.Guid);
    },
    async deleteSelection(item) {
      if (confirm("Oletko varma?")) {
        let success = await this.deleteExternalSurveySelection({
          surveyGuid: this.survey.Guid,
          id: item.id,
        });
        if (success === false) {
          this.snackbarText =
            "Taustatietoa ei voitu poistaa. Taustatietoon on liitetty vastaajia.";
          this.snackbar = true;
        } else {
          this.getExternalSurvey(this.survey.Guid);
        }
      }
    },
  },
  props: {
    predefinedQuestions: { type: Array, required: true },
  },
};
</script>

<style></style>
