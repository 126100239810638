<template>
  <v-menu v-model="menu" :close-on-content-click="false" max-width="290">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="formattedTime"
        clearable
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
        @click:clear="inputTime = undefined"
      ></v-text-field>
    </template>
    <v-time-picker
      v-model="inputTime"
      @change="menu = false"
      :locale="locale"
      format="24hr"
    ></v-time-picker>
  </v-menu>
</template>

<script>
import moment from "moment";
export default {
  computed: {
    formattedTime: function () {
      let formatted = undefined;
      try {
        formatted = moment();
        formatted.set({
          hour: this.inputTime.split(":")[0],
          minute: this.inputTime.split(":")[1],
        });
        return formatted.isValid() ? formatted.format("LT") : "";
      } catch (err) {
        return "";
      }
    },
  },
  data() {
    return {
      inputTime: this.time,
      menu: false,
    };
  },
  props: {
    time: {
      type: String,
    },
    locale: {
      type: String,
      default: "fi-fi",
    },
    label: {
      type: String,
      default: "Aika",
    },
  },
  watch: {
    inputTime: function (newTime) {
      this.$emit("update:time", newTime);
    },
    // TODO: Selvitä miksi vaatii tämän eikä toimi ilman
    time: function (newTime) {
      this.inputTime = newTime;
    },
  },
};
</script>

<style>
</style>