<template>
  <div>
    <v-row>
      <v-col md="6"
        ><h3>{{ page.Title || `Sivu ${pageIndex + 1}` }}</h3></v-col
      >
      <v-col md="6">
        <h3>{{ getPage(page.Id, page.Title || `Sivu ${pageIndex + 1}`) }}</h3>
      </v-col>
    </v-row>
    <QuestionGroup
      v-for="(g, gIdx) in page.QuestionGroups"
      ref="questiongroup"
      :key="g.Id"
      :language="language"
      :questionGroup="g"
      :questionGroupIndex="gIdx"
      :textQuestions="textQuestions"
      :textQuestionTranslations="textQuestionTranslations"
      :selections="selections"
      :selectionTranslations="selectionTranslations"
      :addedSelections="addedSelections"
      :addedSelectionTranslations="addedSelectionTranslations"
      :predefinedQuestions="predefinedQuestions"
      :deletedTextQuestions="deletedTextQuestions"
      :deletedSelections="deletedSelections"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import QuestionGroup from "@/components/Survey/QuestionGroup";

export default {
  components: {
    QuestionGroup,
  },
  computed: {
    ...mapGetters({
      survey: "survey/survey",
    }),
    isValid: function() {
      return this.$refs["questiongroup"].reduce(
        (acc, curr) => acc && curr.isValid,
        true
      );
    },
  },
  methods: {
    getPage(id, original) {
      let translation = undefined;

      if (this.language) {
        translation = this.survey.SurveyPageTranslations.find(
          (l) => l.Code === this.language.Code && l.Id === id
        )?.Title;
      }

      return translation || original;
    },
  },
  props: {
    language: Object,
    page: Object,
    pageIndex: Number,
    textQuestions: Array,
    textQuestionTranslations: Array,
    selections: Array,
    selectionTranslations: Array,
    deletedTextQuestions: Array,
    deletedSelections: Array,
    predefinedQuestions: Array,
    addedSelections: Array,
    addedSelectionTranslations: Array,
  },
};
</script>

<style></style>
