<template>
  <div>
    <v-row>
      <v-col class="d-flex align-center" offset-md="2" md="4">
        {{ computedOriginalQuestion }}
      </v-col>
      <v-col class="d-flex align-center" offset-md="2" md="4">
        <v-text-field
          v-if="isTextQuestion"
          label="Käännös"
          v-model="computedQuestion"
          required
          :error-messages="questionErrors"
          @blur="$v.questionQuestion.$touch()"
        ></v-text-field>
        <span v-else>{{ getQuestion(question.Id, question.Question) }}</span>
      </v-col>
    </v-row>
    <div class="pa-0" v-if="isPredefined">
      <Selection
        ref="selection"
        v-for="s in selections"
        :key="s.Id"
        :language="language"
        :selection="s"
        :selectionTranslations="selectionTranslations"
        :isDeleted="deletedSelections.includes(s.Id)"
      />
      <AddedSelection
        ref="selection"
        v-for="s in addedSelections"
        :key="s.Id"
        :language="language"
        :selection="s"
        :selectionTranslations="addedSelectionTranslations"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import AddedSelection from "@/components/Survey/AddedSelection";
import Selection from "@/components/Survey/Selection";

export default {
  mixins: [validationMixin],
  components: {
    AddedSelection,
    Selection,
  },
  computed: {
    ...mapGetters({
      survey: "survey/survey",
    }),
    questionErrors() {
      const errors = [];
      if (!this.$v.questionQuestion.$dirty) return errors;
      !this.$v.questionQuestion.required && errors.push("Pakollinen");
      return errors;
    },
    computedOriginalQuestion: {
      get() {
        return (
          this.textQuestions?.find((q) => q.Id === this.question.Id)
            ?.Question ?? this.question.Question
        );
      },
    },
    computedQuestion: {
      get() {
        this.validate();
        return (
          this.textQuestionTranslations
            .find((l) => l.code === this.language.Code)
            ?.textQuestions.find((q) => q.Id === this.question.Id)?.Question ??
          ""
        );
      },
      set(newValue) {
        this.questionQuestion = newValue;
        this.textQuestionTranslations
          .find((l) => l.code === this.language.Code)
          .textQuestions.find(
            (q) => q.Id === this.question.Id
          ).Question = newValue;
      },
    },
    isPredefined() {
      return (
        this.predefinedQuestions?.map((q) => q.Id).includes(this.question.Id) ??
        false
      );
    },
    isTextQuestion() {
      return (
        !this.question.Predefined &&
        (this.question.QuestionTypeId === 1 ||
          this.question.QuestionTypeId === 2 ||
          this.question.Id < 0)
      );
    },
    isValid: function() {
      let valid = true;

      if (this.$refs["selection"] && this.$refs["selection"].length) {
        valid = this.$refs["selection"].reduce(
          (acc, curr) => acc && curr.isValid,
          true
        );
      }

      return !this.$v.$invalid && valid;
    },
  },
  data: function() {
    return {
      questionQuestion:
        this.textQuestionTranslations
          .find((l) => l.code === this.language.Code)
          ?.textQuestions.find((q) => q.Id === this.question.Id)?.Question ??
        "",
    };
  },
  methods: {
    getQuestion(id, original) {
      let translation = undefined;

      if (this.language) {
        translation =
          this.survey.SurveyQuestionTranslations.filter(
            (q) => q.Id === id && q.Code === this.language.Code
          )[0]?.Question ?? undefined;

        const newTranslation = this.textQuestionTranslations
          .find((l) => l.code === this.language.Code)
          .textQuestions.find((t) => t.Id === id)?.Question;

        if (newTranslation) {
          translation = newTranslation;
        }
      }

      return translation || original;
    },
    validate() {
      this.$v.$touch();
    },
  },
  validations: {
    questionQuestion: {
      required,
    },
  },
  watch: {
    language: function(val) {
      this.questionQuestion =
        this.textQuestionTranslations
          .find((l) => l.code === val.Code)
          ?.textQuestions.find((q) => q.Id === this.question.Id)?.Question ??
        "";
      this.validate();
    },
  },
  props: {
    language: Object,
    question: Object,
    textQuestions: Array,
    textQuestionTranslations: Array,
    selections: Array,
    selectionTranslations: Array,
    addedSelections: Array,
    addedSelectionTranslations: Array,
    predefinedQuestions: Array,
    deletedSelections: Array,
  },
};
</script>

<style></style>
