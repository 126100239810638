<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col md="10" lg="8">
        <v-card class="elevation-12 main-content">
          <v-card-title>
            <TitleSpan
              title="Vastaajat & Jakelu"
              :subtitle="survey ? survey.Name : ''"
            />
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="refresh"
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
          </v-card-title>
          <v-card-text>
            <LoadingIndicator :isLoading="surveyLoading" />

            <v-stepper
              v-if="survey"
              v-show="!surveyLoading"
              v-model="step"
              class="elevation-1"
              non-linear
            >
              <v-stepper-header>
                <template v-for="s in steps">
                  <v-stepper-step
                    :complete="step > s.step"
                    :key="s.step"
                    :step="s.step"
                    editable
                    color="info"
                    >{{ s.text }}</v-stepper-step
                  >
                  <v-divider
                    v-if="s.step < steps.length"
                    :key="-s.step"
                  ></v-divider>
                </template>
              </v-stepper-header>
              <v-stepper-items>
                <!-- TODO: Stepit paremmin ? -->
                <v-stepper-content step="1">
                  <VotersPredefined
                    :predefinedQuestions="predefinedQuestions"
                  />
                </v-stepper-content>
                <v-stepper-content v-if="is360Survey" step="2">
                  <LoadingIndicator :isLoading="voterLoading" />
                  <Voters360
                    :voters360="voters360"
                    :emails="voter360Emails"
                    :snackbarText.sync="snackbarText"
                    :predefinedQuestions="predefinedQuestions"
                    :selectedLanguage.sync="selectedLanguage"
                    @emailTitleChanged="voter360EmailTitleChanged"
                    @emailBodyChanged="voter360EmailBodyChanged"
                  />
                </v-stepper-content>
                <v-stepper-content :step="is360Survey ? 3 : 2">
                  <VotersDatatable
                    :predefinedQuestions="predefinedQuestions"
                    :selected="selected"
                    :snackbarText.sync="snackbarText"
                    :emails="emails"
                    :selectedLanguage.sync="selectedLanguage"
                    @selectedChanged="selectedChanged"
                    @allSelectedChanged="allSelectedChanged"
                    @emailChanged="emailChanged"
                    @titleChanged="titleChanged"
                    @update:reminderInDays="reminderInDays = $event"
                    @update:sendAfter="sendAfter = $event"
                    @sendEmails="sendMailClick"
                    @initialize="initialize"
                  />
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>
          <v-card-actions v-if="survey">
            <v-btn
              v-if="step === 1"
              color="secondary"
              :to="{
                name: 'surveys',
                params: { guid: this.survey.Guid },
              }"
              >Takaisin</v-btn
            >

            <v-btn v-if="step > 1" color="secondary" @click="step = step - 1"
              >Takaisin</v-btn
            >

            <v-spacer></v-spacer>

            <v-btn
              v-if="step < steps.length"
              color="info"
              @click="step = step + 1"
              >Seuraava</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">{{ snackbarText }}</v-snackbar>
  </v-container>
</template>

<style>
ul {
  list-style-type: none;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import _orderBy from "lodash/orderBy";

import VotersDatatable from "@/components/Voters/VotersDatatable";
import VotersPredefined from "@/components/Voters/VotersPredefined";
import Voters360 from "@/components/Voters/Voters360";
import LoadingIndicator from "@/components/LoadingIndicator";
import TitleSpan from "@/components/TitleSpan";

const LINK = "[LINK]";

export default {
  name: "voters",
  components: {
    VotersDatatable,
    VotersPredefined,
    Voters360,
    LoadingIndicator,
    TitleSpan,
  },
  computed: {
    ...mapGetters({
      survey: "survey/survey",
      surveyLoading: "survey/isLoading",
      voterLoading: "voter/isLoading",
      user: "auth/user",
      voters: "voter/voters",
    }),
    isLoading: function () {
      return this.surveyLoading || this.voterLoading;
    },
    steps: function () {
      // TODO: Tämä järkevämmin ?
      const list = ["Taustatiedot", "Arvioitavat henkilöt", "Vastaajat"];

      return list
        .filter((l) => this.is360Survey || !l.startsWith("Arvoitavat"))
        .map((l, idx) => ({
          step: idx + 1,
          text: l,
        }));
    },
    predefinedQuestions: function () {
      if (!this.survey) {
        return [];
      }

      var s = this.survey.Pages.map((p) =>
        p.QuestionGroups.map((g) =>
          g.Questions.filter((q) => q.Predefined).map((q) => ({
            id: q.Id,
            question: q.Question,
            isChoice: q.IsChoice,
            canEdit: q.Id === this.survey.Options.Question360Role,
            questionFor360: q.Id === this.survey.Options.Question360Person,
            selections: q.Selections.map((s) => ({
              id: s.Id,
              selection: s.Selection,
              email: s.Email,
            })),
          }))
        ).filter((g) => g.length > 0)
      ).filter((p) => p.length > 0);

      return [].concat(...[].concat(...s));
    },
    theQuestion: function () {
      return this.predefinedQuestions.find((q) => q.canEdit);
    },
    textQuestions: function () {
      if (!this.survey) {
        return [];
      }

      var s = this.survey.Pages.map((p) =>
        p.QuestionGroups.map((g) =>
          g.Questions.filter(
            (q) =>
              (q.QuestionTypeId === 1 || q.QuestionTypeId === 2) &&
              !q.Predefined
          ).map((q) => ({
            id: q.Id,
            question: q.Question,
            questionFullTitle: q.QuestionFullTitle,
          }))
        ).filter((g) => g.length > 0)
      ).filter((p) => p.length > 0);

      return [].concat(...[].concat(...s));
    },
    surveyEmailLanguages: function () {
      return _orderBy(this.survey.Languages, ["IsDefault"], ["desc"]);
    },
    voterHeaders: function () {
      let arr = [
        {
          text: "Email",
          value: "voterEmail",
        },
        {
          text: "Kieli",
          value: "languageCode",
        },
        {
          text: "Vastannut",
          value: "answered",
        },
        {
          text: "Viimeisin lähetetty sähköposti",
          value: "latestMailAdded",
        },
      ];

      arr = arr.concat(
        this.predefinedQuestions.map((q) => {
          return {
            text: q.question,
            value: `answers[${q.id}]`,
          };
        })
      );

      arr = arr.concat({
        text: "",
        value: "actions",
        sortable: false,
      });

      return arr;
    },
    voters360: function () {
      let voters = [];

      var question = this.predefinedQuestions.find((q) => q.questionFor360);
      if (question) {
        question.selections.forEach((s) => {
          let selfSelection = this.theQuestion.selections.find(
            (sel) => sel.id === this.survey.Options.Selection360Self
          ).selection;

          let self = this.voters.find((v) => {
            return (
              v.answers[this.survey.Options.Question360Role] ===
                selfSelection &&
              v.answers[this.survey.Options.Question360Person] === s.selection
            );
          });

          const voter = {
            voter: self,
            question: question,
            selection: s,
            voters: this.theQuestion.selections.map((t) => ({
              question: {
                question: this.theQuestion.question,
                id: this.theQuestion.id,
              },
              selection: t,
              emails: this.voters
                .filter(
                  (v) =>
                    v.answers[this.theQuestion.id] === t.selection &&
                    v.answers[question.id] === s.selection
                )
                .map((vv) => ({
                  email: vv.voterEmail,
                  code: vv.voterCode,
                  languageCode: vv.languageCode,
                  answered: vv.answered,
                  added: vv.added,
                })),
            })),
            predefined: this.predefinedQuestions
              .filter((p) => !p.questionFor360 && !p.canEdit)
              .reduce((acc, curr) => {
                acc[curr.id] = self?.answers[curr.id] ?? "";
                return acc;
              }, {}),
          };
          if (voter.voter) {
            voters.push(voter);
          }
        });
      }
      return voters;
    },
    is360Survey: function () {
      return this.predefinedQuestions.find((q) => q.canEdit) !== undefined;
    },
  },
  data: () => ({
    step: 2,
    snackbar: false,
    snackbarText: "",
    selectedLanguage: null,
    selected: [],
    rules: {
      required: (value) => !!value || "Pakollinen kenttä",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Virheellinen email";
      },
    },
    emails: {},
    voter360Emails: {},
    sendAfter: undefined,
    reminderInDays: 0,
  }),
  methods: {
    ...mapActions({
      getExternalSurvey: "survey/getExternalSurvey",
      getVoters: "voter/getVoters",
      postVoters: "voter/postVoters",
      putVoter: "voter/putVoter",
      deleteVoter: "voter/deleteVoter",
      sendMail: "voter/sendMail",
    }),
    async sendMailClick() {
      if (this.selected.length === 0) {
        this.snackbar = true;
        this.snackbarText = "Et ole valinnut yhtään vastaajaa";
        return;
      }

      let voterCodes = this.surveyEmailLanguages.reduce((acc, curr) => {
        acc[curr.Code] = [];
        return acc;
      }, {});

      this.selected.forEach((v) => {
        let voter = {
          id: v.voterId,
          voterCode: v.voterCode,
          languageId: this.surveyEmailLanguages.find(
            (l) => l.Code === v.languageCode
          ).Id,
        };

        let vv = voterCodes[v.languageCode].find(
          (v) => v.voterId == voter.voterId
        );

        if (vv) {
          vv.voterCodes.push(voter.voterCode);
        } else {
          voterCodes[v.languageCode].push({
            voterId: v.voterId,
            voterCodes: [v.voterCode],
          });
        }
      });

      let emailModels = Object.keys(this.emails)
        .filter((lang) => {
          return this.selected.map((v) => v.languageCode).includes(lang);
        })
        .map((lang) => {
          var emailLang = this.surveyEmailLanguages.find(
            (l) => l.Code === lang
          );

          let emailBody =
            this.emails[lang]?.body.indexOf(LINK) >= 0
              ? this.emails[lang].body
              : `${this.emails[lang].body}<br /><p>${LINK}</p>`;

          return {
            surveyId: this.survey.Id,
            languageId: emailLang ? emailLang.Id : 0,
            title: this.emails[lang].title,
            body: emailBody,
            html: true,
            combine: true,
            predefinedValues: true,
            sendAfter: this.sendAfter ? this.sendAfter.format() : undefined,
            automaticMessages: false,
            reminderIn:
              this.reminderInDays > 0 ? this.reminderInDays : undefined,
            thirdParty: false,
            voterEmails: voterCodes[lang],
          };
        });
      let emailsValid = emailModels.every((e) => {
        let isValid = e.title.length > 0 && e.body.indexOf(LINK) > 0;
        if (!isValid) {
          this.selectedLanguage = this.surveyEmailLanguages.find(
            (l) => l.Id === e.languageId
          );
        }
        return isValid;
      });

      if (!emailsValid) {
        this.snackbar = true;
        this.snackbarText = "Tarkasta sähköpostiviesti";
        return;
      }

      let success = await this.sendMail(emailModels);
      if (success) {
        this.snackbarText =
          this.reminderInDays > 0 ? "Viestit tallennettu" : "Viestit lähetetty";
        setTimeout(() => {
          this.$router.push({
            name: "surveys",
            params: { guid: this.survey.Guid },
          });
        }, 2000);
      } else {
        this.snackbarText = "Tapahtui virhe";
      }
    },
    selectedChanged({ item, value }) {
      if (value) {
        let alreadyAdded = this.selected.find(
          (v) => v.voterCode === item.voterCode
        );
        if (!alreadyAdded) {
          this.selected.push(item);
        }
      } else {
        this.selected = this.selected.filter(
          (v) => v.voterCode !== item.voterCode
        );
      }
    },
    allSelectedChanged(value) {
      if (value) {
        this.selected = this.voters.filter((v) => !v.answered).slice();
      } else {
        this.selected = [];
      }
    },
    emailChanged(html) {
      this.emails[this.selectedLanguage.Code].body = html;
    },
    titleChanged(title) {
      this.emails[this.selectedLanguage.Code].title = title;
    },
    async initialize() {
      await this.getExternalSurvey(this.$route.params.guid);
      if (!this.survey) {
        return;
      }
      await this.getVoters(this.survey.Guid);
      this.selected = this.voters.filter((v) => !v.answered);
      let defaultLanguage = this.survey.Languages.find((l) => l.IsDefault);
      this.selectedLanguage =
        this.voters.length === 0 ||
        this.voters.filter((v) => v.languageCode === defaultLanguage.Code)
          .length > 0
          ? defaultLanguage
          : this.survey.Languages.find(
              (l) => l.Code === this.voters[0].languageCode
            );
      this.survey.Languages.forEach((l) => {
        let emailTitle = "";
        let emailBody = "";

        let voter360EmailTitle = "";
        let voter360EmailBody = "";

        if (defaultLanguage.Id === l.Id) {
          emailTitle =
            this.survey.Emails.find((e) => e.LanguageId === l.Id)?.Title ??
            this.survey.SurveyMailSubject ??
            "";

          emailBody =
            this.survey.Emails.find((e) => e.LanguageId === l.Id)?.Body ??
            this.survey.SurveyMailBody ??
            "";

          voter360EmailTitle = this.survey.Options.Voter360MailSubject ?? "";
          voter360EmailBody = this.survey.Options.Voter360MailBody ?? "";
        } else {
          emailTitle =
            this.survey.Emails.find((e) => e.LanguageId === l.Id)?.Title ??
            this.survey.SurveyTranslations.find((t) => t.LanguageId === l.Id)
              ?.SurveyMailSubject ??
            "";

          emailBody =
            this.survey.Emails.find((e) => e.LanguageId === l.Id)?.Body ??
            this.survey.SurveyTranslations.find((e) => e.LanguageId === l.Id)
              ?.SurveyMailBody ??
            "";

          voter360EmailTitle =
            this.survey.SurveyTranslations.find((t) => t.LanguageId === l.Id)
              ?.Voter360MailSubject ?? "";
          voter360EmailBody =
            this.survey.SurveyTranslations.find((t) => t.LanguageId === l.Id)
              ?.Voter360MailBody ?? "";
        }

        this.emails[l.Code] = {
          body: emailBody,
          title: emailTitle,
        };

        const personMail = this.survey.PersonEmails.find(
          (p) => p.LanguageId === l.Id
        );

        this.voter360Emails[l.Code] = {
          body: personMail?.Body ?? voter360EmailBody,
          title: personMail?.Subject ?? voter360EmailTitle,
        };
      });
    },
    refresh() {
      this.$store.dispatch("survey/clearSurvey", null);
      this.$store.dispatch("voter/clearVoters", null);
      this.initialize();
    },
    voter360EmailTitleChanged(value) {
      this.voter360Emails[this.selectedLanguage.Code].title = value;
    },
    voter360EmailBodyChanged(value) {
      this.voter360Emails[this.selectedLanguage.Code].body = value;
    },
  },
  watch: {
    snackbarText: function (newValue) {
      if (newValue) {
        this.snackbar = true;
        setTimeout(() => {
          this.snackbar = false;
          this.snackbarText = "";
        }, 2000);
      }
    },
  },
  created() {
    // TODO: Pakotetaan toistaiseksi kieli suomeksi tässä, koska käytetään samoja komponentteja kuin henkilökohtaisen näkymän puolella, jossa kielisyys on jo käytössä
    this.changeLanguage("fi");
  },
  mounted() {
    this.initialize();
  },
  destroyed() {
    this.$store.dispatch("survey/clearSurvey", null);
    this.$store.dispatch("voter/clearVoters", null);
  },
};
</script>
