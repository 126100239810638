<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="3">
        <v-card class="elevation-12">
          <v-card-title> Kirjaudu sisään </v-card-title>
          <v-card-text>
            <v-form @submit.prevent>
              <v-text-field
                :disabled="loading"
                :error-messages="usernameErrors"
                @input="$v.form.username.$touch()"
                @blur="$v.form.username.$touch()"
                label="Sähköpostiosoite"
                name="login"
                prepend-icon="mdi-account"
                type="text"
                required
                v-model="form.username"
              ></v-text-field>
              <v-text-field
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                :error-messages="passwordErrors"
                :disabled="loading"
                @click:append="showPassword = !showPassword"
                @input="$v.form.password.$touch()"
                @blur="$v.form.password.$touch()"
                id="password"
                label="Salasana"
                name="password"
                prepend-icon="mdi-lock"
                required
                v-model="form.password"
                @keyup.enter="validateUser"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="default" text to="/forgot">Unohtuiko salasana?</v-btn>
            <v-btn color="info" @click="validateUser" :disabled="loading"
              >Kirjaudu sisään</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "login",
  mixins: [validationMixin],
  components: {},
  data: () => ({
    form: {
      username: "",
      password: "",
    },
    loading: false,
    loginFailed: false,
    showPassword: false,
  }),
  validations: {
    form: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
    }),
    usernameErrors() {
      const errors = [];
      if (!this.$v.form.username.$dirty) return errors;
      !this.$v.form.username.required && errors.push("Pakollinen");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required && errors.push("Pakollinen");
      return errors;
    },
  },
  methods: {
    ...mapActions({
      login: "auth/login",
    }),
    clearForm() {
      this.$v.$reset();
      this.form.username = null;
      this.form.password = null;
    },
    validateUser() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.submit();
      }
    },
    submit() {
      this.loading = true;

      this.login(this.form).then(() => {
        if (!this.authenticated) {
          this.loading = false;
          this.loginFailed = true;
          this.$toast.center("Kirjautuminen epäonnistui");

          return;
        } else {
          this.$router.replace({
            name: "home",
          });
        }
      });
    },
  },
};
</script>
