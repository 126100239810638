<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col md="10" lg="8">
        <v-card class="elevation-12 main-content">
          <v-card-title v-if="overview">
            {{ overview.surveyName }}
            <v-spacer></v-spacer>
            <v-btn
              v-if="survey.PreviousSurveyGuid"
              color="info"
              :to="{
                name: 'compare',
                params: { guid: survey.Guid },
              }"
              exact
              >Vertailuraportti</v-btn
            >
            <v-btn color="warning" @click="reportTokenDialog = true"
              >Jakaminen</v-btn
            >
          </v-card-title>
          <v-card-text>
            <LoadingIndicator
              :isLoading="isLoading && !overviewWithFilterLoading"
            />

            <div v-if="overview">
              <!-- Rinkulat -->
              <div class="mb-10">
                <ReportSurveyOverview :overview="overview" />
              </div>
              <!-- Tabit -->
              <v-tabs
                v-model="tab"
                color="basil"
                grow
                slider-size="0"
                active-class="custom-v-tabs-active-tab"
              >
                <v-tab @click="tabClick"> Näytä kokonaistilanne </v-tab>
                <v-tab
                  v-for="item in overview.predefined"
                  :key="item.id"
                  @click="tabClick"
                >
                  Näytä {{ item.question }}
                </v-tab>
              </v-tabs>
              <div class="custom-v-tabs-slider"></div>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <ReportTemplatesList
                    :survey="survey"
                    :report="report"
                    :reportTemplates="reportTemplates"
                    :reportIsLoading="reportIsLoading"
                    @getWebReport="getReport"
                    @downloadWordReport="downloadWordReport"
                  />

                  <div class="d-flex justify-end">
                    <v-switch
                      v-model="showLegend"
                      label="Näytä selitteet"
                    ></v-switch>
                  </div>
                  <ReportAllTopBottom
                    title="Kokonaistilanne"
                    :overview="overview"
                    @groupClicked="groupClicked"
                    v-show="showGroup === 0 || showGroup === 1"
                    :showLegend="showLegend"
                  />
                  <ReportCalculationGroups
                    title="Osa-alueet"
                    :overview="overview"
                    @groupClicked="groupClicked"
                    v-show="showGroup === 0 || showGroup === 2"
                    :showLegend="showLegend"
                  />
                </v-tab-item>
                <v-tab-item v-for="item in overview.predefined" :key="item.id">
                  <v-expansion-panels class="mt-3">
                    <v-expansion-panel
                      active-class="active-panel"
                      v-for="(selection, sIdx) in item.selections"
                      :key="selection.id"
                      :style="{
                        'border-right': '1px solid #f2f2f2',
                        'border-left': '1px solid #f2f2f2',
                      }"
                    >
                      <v-expansion-panel-header
                        :ref="`panel-header-${selection.id}`"
                        @click="clicked(item.id, selection.id)"
                      >
                        <v-row>
                          <v-col class="d-flex align-center" cols="3">{{
                            selection.selection
                          }}</v-col>
                          <v-col
                            cols="6"
                            :style="{
                              color: getRowColor(sIdx, item.selections.length),
                            }"
                            class="d-flex align-center justify-space-between"
                          >
                            <v-progress-linear
                              height="25"
                              :color="getRowColor(sIdx, item.selections.length)"
                              :value="selection.average * (100 / selection.max)"
                              ><span>{{
                                selection.average
                                  ? selection.average.toFixed(2)
                                  : 0
                              }}</span></v-progress-linear
                            >
                          </v-col>
                          <v-col
                            cols="3"
                            class="d-flex justify-end align-center"
                            :style="{
                              color: getAnswerPercentageColor(
                                selection.answers,
                                selection.voters
                              ),
                            }"
                            >{{
                              `${selection.answers} / ${selection.voters} (${
                                selection.voters > 0
                                  ? (
                                      (selection.answers / selection.voters) *
                                      100
                                    ).toFixed(0)
                                  : 0
                              }%)`
                            }}</v-col
                          >
                        </v-row>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <LoadingIndicator
                          :isLoading="overviewWithFilterLoading"
                        />
                        <ReportTemplatesList
                          v-if="!overviewWithFilterLoading"
                          :survey="survey"
                          :report="report"
                          :question="item"
                          :selection="selection"
                          :reportTemplates="reportTemplates"
                          :reportIsLoading="reportIsLoading"
                          @getWebReport="getReport"
                          @downloadWordReport="downloadWordReport"
                        />

                        <div class="d-flex justify-end">
                          <v-switch
                            v-show="!overviewWithFilterLoading"
                            v-model="showLegend"
                            label="Näytä selitteet"
                          ></v-switch>
                        </div>
                        <ReportQuestionSelection
                          v-if="!overviewWithFilterLoading"
                          :survey="survey"
                          :report="report"
                          :item="item"
                          :selection="selection"
                          :reportTemplates="reportTemplates"
                          :overviewWithFilter="overviewWithFilter"
                          :overviewWithFilterLoading="overviewWithFilterLoading"
                          :reportIsLoading="reportIsLoading"
                          :showLegend="showLegend"
                          @getWebReport="getReport"
                          @downloadWordReport="downloadWordReport"
                          bgColor="#ffffff"
                        />
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="secondary"
              :to="{
                name: 'surveys',
                params: { guid: this.$route.params.guid },
              }"
              >Takaisin</v-btn
            >
            <v-dialog v-model="reportTokenDialog" width="60%">
              <v-card>
                <v-card-text>
                  <v-data-table :headers="tokenHeaders" :items="reportTokens">
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>Raportin jakaminen</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <!-- EDIT -->
                        <v-dialog
                          v-model="editTokenDialog"
                          @click:outside="closeEditDialog"
                          max-width="500px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="info"
                              dark
                              class="mb-2"
                              v-bind="attrs"
                              v-on="on"
                            >
                              Lisää uusi
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="text-h5">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="8">
                                    <v-combobox
                                      v-model="editedItem.email"
                                      :error-messages="emailErrors"
                                      :items="tokenEmails"
                                      placeholder="Email"
                                    ></v-combobox>
                                  </v-col>
                                  <v-col cols="4">
                                    <DatePickerInput
                                      label="Voimassa"
                                      :min="minDate"
                                      :max="maxDate"
                                      :date.sync="editedItem.valid"
                                    />
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="closeEditDialog"
                              >
                                Peruuta
                              </v-btn>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="save"
                                :loading="reportIsLoading"
                              >
                                Tallenna
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <!-- DELETE -->
                        <v-dialog
                          v-model="dialogDelete"
                          @click:outside="closeDeleteDialog"
                          max-width="500px"
                        >
                          <v-card>
                            <v-card-title class="text-h5"
                              >Haluatko varmasti poistaa
                              data-avaimen?</v-card-title
                            >
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="closeDeleteDialog"
                                >Peruuta</v-btn
                              >
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="deleteConfirm"
                                >OK</v-btn
                              >
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <!-- EMAIL -->
                        <v-dialog
                          v-model="emailDialog"
                          @click:outside="closeEmailDialog"
                          max-width="500px"
                        >
                          <v-card>
                            <v-card-title>
                              <span class="text-h5">Lähetä sähköposti</span>
                            </v-card-title>

                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col
                                    ><h3>{{ editedItem.email }}</h3>
                                    <span>
                                      Kirjoita viestin saate alle. Linkki
                                      tulokset sivulle lisätään viestin
                                      loppuun.</span
                                    ></v-col
                                  >
                                </v-row>
                                <v-row>
                                  <v-col>
                                    <v-textarea
                                      v-model="tokenEmail"
                                      label="Viestin saate"
                                    ></v-textarea>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="closeEmailDialog"
                              >
                                Peruuta
                              </v-btn>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="sendEmailClick"
                                :loading="reportIsLoading"
                              >
                                Lähetä
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-toolbar>
                    </template>
                    <template v-slot:[`item.guid`]="{ item }">
                      <router-link
                        :to="{
                          name: 'token-report',
                          params: { guid: item.guid },
                        }"
                        target="_blank"
                        >{{ item.guid }}</router-link
                      >
                    </template>
                    <template v-slot:[`item.valid`]="{ item }">
                      {{ formatDate(item.valid) }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small class="mr-2" @click="toClipboard(item)">
                        mdi-content-copy
                      </v-icon>
                      <v-icon small class="mr-2" @click="sendEmail(item)">
                        mdi-email
                      </v-icon>
                      <v-icon small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                      </v-icon>
                      <v-icon small @click="deleteItem(item)">
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="error" @click="closeReportTokenDialog">
                    Sulje
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">{{ snackbarText }}</v-snackbar>
  </v-container>
</template>

<style scoped>
.active-panel {
  background-color: #f6f6f6;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import axios from "axios";
import moment from "moment";

import LoadingIndicator from "@/components/LoadingIndicator";
import ReportCalculationGroups from "@/components/Report/ReportCalculationGroups";
import ReportAllTopBottom from "@/components/Report/ReportAllTopBottom";
import ReportQuestionSelection from "@/components/Report/ReportQuestionSelection";
import ReportSurveyOverview from "@/components/Report/ReportSurveyOverview";
import ReportTemplatesList from "@/components/Report/ReportTemplatesList";
import DatePickerInput from "@/components/DatePickerInput";

export default {
  name: "report",
  mixins: [validationMixin],
  components: {
    LoadingIndicator,
    ReportCalculationGroups,
    ReportAllTopBottom,
    ReportQuestionSelection,
    ReportSurveyOverview,
    ReportTemplatesList,
    DatePickerInput,
  },
  data: () => ({
    tab: 0,
    openPanel: undefined,
    overviewWithFilterLoading: false,
    reportDialog: false,
    showGroup: 0,
    showLegend: false,
    reportTokenDialog: false,
    editTokenDialog: false,
    dialogDelete: false,
    emailDialog: false,
    tokenEmail: "",
    snackbar: false,
    snackbarText: "",
    defaultItem: {
      guid: "",
      email: "",
      valid: undefined,
    },
    tokenHeaders: [
      {
        text: "Id",
        value: "guid",
        align: "start",
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Voimassa",
        value: "valid",
      },
      { text: "Toiminnot", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      guid: "",
      email: "",
      valid: undefined,
    },
  }),
  computed: {
    ...mapGetters({
      reportIsLoading: "report/isLoading",
      surveyIsLoading: "survey/isLoading",
      survey: "survey/survey",
      report: "report/report",
      overview: "report/overview",
      overviewWithFilter: "report/overviewWithFilter",
      user: "auth/user",
      reportTokens: "report/reportTokens",
    }),
    isLoading() {
      return this.reportIsLoading || this.surveyIsLoading;
    },
    reportTemplates() {
      return this.survey !== null ? this.survey.ReportTemplates : [];
    },
    formTitle() {
      return this.editedIndex === -1 ? "Lisää uusi" : "Muokkaa";
    },
    emailErrors() {
      const errors = [];
      !this.$v.editedItem.email.required &&
        errors.push("Syötä sähköpostiosoite");
      !this.$v.editedItem.email.email && errors.push("Syötä sähköpostiosoite");
      return errors;
    },
    minDate() {
      return moment().format("YYYY-MM-DD");
    },
    maxDate() {
      return moment().add(3, "M").format("YYYY-MM-DD");
    },
    tokenEmails() {
      return this.survey.Options.ContactDetails
        ? [this.survey.Options.ContactDetails]
        : [];
    },
  },
  methods: {
    ...mapActions({
      getExternalSurvey: "survey/getExternalSurvey",
      getReportOverview: "report/getReportOverview",
      getWebReport: "report/getWebReport",
      getReportOverviewWithFilter: "report/getReportOverviewWithFilter",
      clearOverviewWithFilter: "report/clearOverviewWithFilter",
      getReportTokens: "report/getReportTokens",
      postReportToken: "report/postReportToken",
      putReportToken: "report/putReportToken",
      deleteReportToken: "report/deleteReportToken",
      sendReportLinkEmail: "report/sendReportLinkEmail",
    }),
    clicked: async function (questionId, selectionId) {
      if (this.openPanel !== selectionId) {
        this.openPanel = selectionId;
        this.clearOverviewWithFilter();
        this.overviewWithFilterLoading = true;
        await this.getReportOverviewWithFilter({
          guid: this.$route.params.guid,
          questionId: questionId,
          selectionId: selectionId,
        });
        this.overviewWithFilterLoading = false;
      } else {
        this.openPanel = undefined;
      }
    },
    tabClick: function () {
      if (this.openPanel) {
        let panel = this.$refs[`panel-header-${this.openPanel}`][0];
        panel.$el.click();
      }
    },
    getAnswerPercentageColor(answerCount, voterCount) {
      if (voterCount === 0) {
        return this.$config?.report?.chartColorRed || "#F44336";
      }
      let percentage = (answerCount / voterCount) * 100;

      let highAnswerPercentageMin =
        this.$config?.report?.highAnswerPercentageMin ?? 66;
      let mediumAnswerPercentageMin =
        this.$config?.report?.mediumAnswerPercentageMin ?? 33;

      if (percentage >= highAnswerPercentageMin) {
        return this.$config?.report?.chartColorGreen || "#4CAF50";
      } else if (percentage >= mediumAnswerPercentageMin) {
        return this.$config?.report?.chartColorYellow || "#FFEB3B";
      } else {
        return this.$config?.report?.chartColorRed || "#F44336";
      }
    },
    getRowColor(index, count) {
      if (count === 1) {
        return this.$config?.report?.chartColorBlue || "#2196F3";
      }
      let colorize = Math.round(count / 4);
      if (index + 1 <= colorize) {
        return this.$config?.report?.chartColorGreen || "#4CAF50";
      } else if (count - index <= colorize) {
        return this.$config?.report?.chartColorRed || "#F44336";
      } else {
        return this.$config?.report?.chartColorYellow || "#FFEB3B";
      }
    },
    getReport(guid, reportId, questionId, selectionId) {
      this.getWebReport({
        guid: guid,
        reportId: reportId,
        questionId: questionId,
        selectionId: selectionId,
      });
    },
    async downloadWordReport(report, question, selection) {
      this.$store.commit("report/SET_ISLOADING", true);
      let filter =
        question !== undefined && selection !== undefined
          ? {
              Id: question.id,
              Selected: [selection.id],
            }
          : {};

      try {
        let response = await axios({
          method: "POST",
          url: `/api/report/wordreport`,
          data: {
            Guid: this.survey.Guid,
            ReportId: report.Id,
            Filter: filter,
          },
          responseType: "blob",
        });

        const blob = new Blob([response.data]);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download =
          filter.Id !== undefined
            ? `${report.Name} - ${question.question} - ${selection.selection}.docx`
            : `${report.Name}.docx`;
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (e) {
        console.log(e);
      }

      this.$store.commit("report/SET_ISLOADING", false);
    },
    groupClicked(event) {
      this.showGroup = event;
    },
    async save() {
      if (this.$v.$invalid) {
        return;
      }

      let response = "";
      if (this.editedIndex > -1) {
        response = await this.putReportToken({
          surveyGuid: this.$route.params.guid,
          guid: this.editedItem.guid,
          email: this.editedItem.email,
          valid: this.editedItem.valid,
        });
      } else {
        response = await this.postReportToken({
          surveyGuid: this.$route.params.guid,
          email: this.editedItem.email,
          valid: this.editedItem.valid,
        });
      }

      if (response) {
        this.getReportTokens(this.$route.params.guid);
        this.closeEditDialog();
        this.snackbarText = "Tallennus onnistui.";
      } else {
        this.snackbarText = "Tapahtui virhe.";
      }
    },
    editItem(item) {
      this.editedIndex = this.reportTokens.indexOf(item);
      this.editedItem = Object.assign(
        {},
        { email: item.email, guid: item.guid, valid: item.valid.split("T")[0] }
      );
      this.editTokenDialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.reportTokens.indexOf(item);
      this.editedItem = Object.assign({}, { guid: item.guid });
      this.dialogDelete = true;
    },
    closeReportTokenDialog() {
      this.reportTokenDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeEditDialog() {
      this.editTokenDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeEmailDialog() {
      this.emailDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    sendEmail(item) {
      this.editedIndex = this.reportTokens.indexOf(item);
      this.editedItem = Object.assign(
        {},
        { email: item.email, guid: item.guid, valid: item.valid.split("T")[0] }
      );
      this.emailDialog = true;
    },
    async sendEmailClick() {
      let response = await this.sendReportLinkEmail({
        guid: this.editedItem.guid,
        email: this.tokenEmail,
      });

      if (response) {
        this.snackbarText = "Sähköposti lähetetty.";
        this.closeEmailDialog();
      } else {
        this.snackbarText = "Tapahtui virhe.";
      }
    },
    async deleteConfirm() {
      await this.deleteReportToken(this.editedItem.guid);
      this.getReportTokens(this.$route.params.guid);
      this.closeDeleteDialog();
    },
    async toClipboard(item) {
      await navigator.clipboard.writeText(
        `${location.origin}/results/${item.guid}`
      );
      this.snackbarText = "Linkki kopioitu leikepöydälle.";
    },
  },
  validations: {
    editedItem: {
      email: { required, email },
    },
  },
  watch: {
    snackbarText: function (newValue) {
      if (newValue) {
        this.snackbar = true;
        setTimeout(() => {
          this.snackbar = false;
          this.snackbarText = "";
        }, 2000);
      }
    },
  },
  async created() {
    this.changeLanguage("fi");
    await this.getExternalSurvey(this.$route.params.guid);
    await this.getReportOverview(this.$route.params.guid);
    this.getReportTokens(this.$route.params.guid);
  },
  destroyed() {
    this.$store.dispatch("report/clearOverview", null);
    this.$store.dispatch("report/clearOverviewWithFilter", null);
    this.$store.dispatch("survey/clearSurvey", null);
  },
};
</script>
