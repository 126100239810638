<template>
  <v-btn @click="uploadExcel" class="mr-2 mobile-btn">
    Tuo excel-pohja
    <v-icon right>mdi-cloud-upload</v-icon>

    <input ref="uploader" class="d-none" type="file" @change="handleUpload" />
  </v-btn>
</template>

<script>
import { mapActions } from "vuex";
export default {
  components: {},
  computed: {},
  data: () => ({}),
  methods: {
    ...mapActions({
      postFile: "voter/postFile",
    }),
    uploadExcel() {
      this.$refs.uploader.click();
    },
    async handleUpload(e) {
      let data = new FormData();
      var file = e.target.files[0];
      data.append("file", file);
      data.append("guid", this.guid);
      await this.postFile(data);

      this.$emit("uploaded");
    },
  },
  watch: {},
  props: {
    guid: String,
  },
};
</script>
