<template>
  <v-card
    v-if="!hasSelected || selected"
    :outlined="selected"
    class="survey-card"
    :color="selected ? 'blue lighten-4' : undefined"
  >
    <v-img v-if="survey.imageUrl" height="160px" :src="survey.imageUrl"></v-img>
    <v-card-title class="survey-title">
      {{ survey.name }}
    </v-card-title>
    <v-card-text v-html="survey.description"></v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        title="Katso malliraportti"
        :disabled="!survey.exampleReportUrl"
        :href="survey.exampleReportUrl"
        target="_blank"
        icon
      >
        <v-icon>mdi-file-document-outline</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        title="Mittauksen rakenne"
        :outlined="selected"
        icon
        @click="$emit('selectSurveyInfoClick', survey.guid)"
      >
        <v-icon>{{
          !isLoading && selected ? "mdi-close" : "mdi-help-circle"
        }}</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        title="Valitse mittaus"
        icon
        @click="$emit('selectSurveyEditClick', survey.guid)"
      >
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      isLoading: "survey/isLoading",
    }),
  },
  props: { survey: Object, selected: Boolean, hasSelected: Boolean },
};
</script>

<style lang="scss" scoped>
.survey-card {
  .survey-title {
    word-break: break-word;
  }
}
</style>
