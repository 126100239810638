import axios from "axios";

export default {
  namespaced: true,

  state: {
    surveys: [],
    survey: null,
    surveySimple: null,
    externalSurveys: [],
    isLoading: false,
    units: [],
    classes: [],
  },

  getters: {
    surveys(state) {
      return state.surveys;
    },
    survey(state) {
      return state.survey;
    },
    surveySimple(state) {
      return state.surveySimple;
    },
    externalSurveys(state) {
      return state.externalSurveys;
    },
    isLoading(state) {
      return state.isLoading;
    },
    units(state) {
      return state.units;
    },
    classes(state) {
      return state.classes;
    },
  },

  mutations: {
    SET_SURVEYS(state, surveys) {
      state.surveys = surveys;
    },
    SET_SURVEY(state, survey) {
      state.survey = survey;
    },
    SET_SURVEY_SIMPLE(state, survey) {
      state.surveySimple = survey;
    },
    SET_EXTERNAL_SURVEYS(state, externalSurveys) {
      state.externalSurveys = externalSurveys;
    },
    SET_ISLOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_UNITS(state, units) {
      state.units = units;
    },
    SET_CLASSES(state, classes) {
      state.classes = classes;
    },
  },

  actions: {
    async getSurveys({ commit }) {
      try {
        commit("SET_ISLOADING", true);
        let response = await axios.get(`${process.env.VUE_APP_API_URL}/survey`);
        commit("SET_ISLOADING", false);
        commit("SET_SURVEYS", response.data);
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    async getSurvey({ commit }, guid) {
      try {
        commit("SET_ISLOADING", true);
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/survey/${guid}`
        );
        commit("SET_ISLOADING", false);
        commit("SET_SURVEY", response.data);
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    async getExternalSurveys({ commit }) {
      try {
        commit("SET_ISLOADING", true);
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/survey/external`
        );
        commit("SET_ISLOADING", false);
        commit("SET_EXTERNAL_SURVEYS", response.data);
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    async getExternalSurvey({ commit }, guid) {
      try {
        commit("SET_ISLOADING", true);
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/survey/external/${guid}?simple=false`
        );
        commit("SET_ISLOADING", false);
        //TODO: PITÄISIKÖ OLLA OMASSA MUUTTUJASSA EIKÄ KÄYTTÄÄ SAMAA MIHIN MUOKATTAVA HAETAAN ?
        commit("SET_SURVEY", response.data);
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    async getExternalSurveySimple({ commit }, guid) {
      try {
        commit("SET_ISLOADING", true);
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/survey/external/${guid}?simple=true`
        );
        commit("SET_ISLOADING", false);
        //TODO: PITÄISIKÖ OLLA OMASSA MUUTTUJASSA EIKÄ KÄYTTÄÄ SAMAA MIHIN MUOKATTAVA HAETAAN ?
        commit("SET_SURVEY_SIMPLE", response.data);
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    async postExternalSurvey({ commit }, survey) {
      try {
        commit("SET_ISLOADING", true);
        let response = await axios.post(
          `${process.env.VUE_APP_API_URL}/survey`,
          survey
        );
        commit("SET_ISLOADING", false);
        return response.data.Guid;
      } catch (e) {
        commit("SET_ISLOADING", false);
        return "";
      }
    },
    async putExternalSurvey({ commit }, survey) {
      try {
        commit("SET_ISLOADING", true);
        await axios.put(
          `${process.env.VUE_APP_API_URL}/survey/${survey.surveyGuid}`,
          survey
        );
        commit("SET_ISLOADING", false);
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    async postExternalSurveySelection({ commit }, selection) {
      try {
        commit("SET_ISLOADING", true);
        await axios.post(
          `${process.env.VUE_APP_API_URL}/survey/selection`,
          selection
        );
        commit("SET_ISLOADING", false);
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    async putExternalSurveySelection({ commit }, selection) {
      try {
        commit("SET_ISLOADING", true);
        await axios.put(
          `${process.env.VUE_APP_API_URL}/survey/selection/${selection.id}`,
          selection
        );
        commit("SET_ISLOADING", false);
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    async deleteExternalSurveySelection({ commit }, selection) {
      try {
        commit("SET_ISLOADING", true);
        await axios.delete(
          `${process.env.VUE_APP_API_URL}/survey/selection/${selection.id}`
        );
        commit("SET_ISLOADING", false);
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    async getSurveyUnits({ commit }) {
      try {
        commit("SET_ISLOADING", true);
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/survey/external/units`
        );
        commit("SET_ISLOADING", false);
        commit("SET_UNITS", response.data);
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    async getSurveyClasses({ commit }) {
      try {
        commit("SET_ISLOADING", true);
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/survey/external/classes`
        );
        commit("SET_ISLOADING", false);
        commit("SET_CLASSES", response.data);
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    clearSurvey({ commit }) {
      commit("SET_SURVEY", null);
    },
  },
};
