<template>
  <BaseChartComponent
    :type="type"
    :width="width"
    :height="height"
    :dataFormat="dataFormat"
    :dataSource="dataSource"
  />
</template>

<script>
import BaseChartComponent from "@/components/Report/Charts/BaseChartComponent";

export default {
  components: {
    BaseChartComponent,
  },
  computed: {
    dataSource: function () {
      return {
        chart: {
          caption: "",
          xaxisname: "",
          yaxisname: "",
          theme: "fusion",
          yAxisMaxValue: this.reportItem.Chart1.Series[0]?.MaxValue,
          yAxisMinValue: this.reportItem.Chart1.Series[0]?.MinValue,
        },
        categories: [
          {
            category: this.reportItem.Chart1.Series[0].Values.map((v) => ({
              label: v.Label,
            })),
          },
        ],
        dataset: this.reportItem.Chart1.Series.map((s, idx) => ({
          seriesname: s.Label,
          color: this.colors[idx],
          data: s.Values.map((v) => ({
            value: v.Value,
          })),
        })),
      };
    },
    colors: function () {
      return [
        this.$config?.chartColorBlue || "#2196F3",
        this.$config?.chartColorRed || "#ED7D31",
        this.$config?.chartColorYellow || "#FFC000",
        this.$config?.chartColorGreen || "#70AD47",
        this.$config?.chartColorGray || "#A5A5A5",
      ];
    },
  },
  data: () => ({
    dataFormat: "json",
    type: "mscolumn2d",
  }),
  methods: {},
  props: {
    width: { type: String, required: true },
    height: { type: String, required: true },
    reportItem: { type: Object, required: true },
  },
};
</script>

<style></style>
