<template>
  <v-row>
    <v-col cols="12" sm="4">
      <ReportCard :title="$t('voter.report.answerPercentage')">
        {{ answerPercentageFormated }} %
      </ReportCard>
    </v-col>

    <v-col cols="12" sm="4">
      <ReportCard :title="$t('voter.report.average')">
        {{ overview.all[0].average.toFixed(2) }}
      </ReportCard>
    </v-col>

    <v-col cols="12" sm="4">
      <ReportCard :title="$t('voter.report.stdDev')">
        {{ overview.all[0].stdDev.toFixed(2) }}
      </ReportCard>
    </v-col>
  </v-row>
</template>

<script>
import ReportCard from "@/components/Report/ReportCard";

export default {
  components: {
    ReportCard,
  },
  computed: {
    answerPercentage() {
      if (this.filter && this.overview) {
        let selection = this.overview.predefined[0].selections[0];
        return selection.voters > 0
          ? (selection.answers / selection.voters) * 100
          : 0;
      }

      return this.overview && this.overview.voterCount > 0
        ? (this.overview.answerCount / this.overview.voterCount) * 100
        : 0;
    },
    answerPercentageFormated() {
      return this.answerPercentage.toFixed(0);
    },
  },
  props: {
    overview: { type: Object, required: true },
    filter: { type: Boolean, default: false },
  },
};
</script>

<style></style>
