<template>
  <div class="my-3">
    <div v-if="enableDrill" class="d-flex justify-start mb-3">
      <span
        :style="{ fontSize: 'larger' }"
        :class="
          group.children.length
            ? 'chart-title d-flex align-center'
            : 'd-flex align-center'
        "
        @click="$emit('groupClicked', group)"
      >
        {{ group.name }}
        <v-btn v-if="group.children.length" x-small text class="no-active"
          ><v-icon>mdi-arrow-right</v-icon></v-btn
        >
      </span>
    </div>
    <VueFusionCharts
      type="stackedbar2d"
      :width="width"
      :height="height"
      dataFormat="json"
      :dataSource="dataSource"
      :id="getId()"
    >
    </VueFusionCharts>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

import VueFusionChartsComponent from "vue-fusioncharts/component";
import FusionCharts from "fusioncharts/core";
import StackedBar2D from "fusioncharts/viz/stackedbar2d";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

FusionCharts.options.license({
  key: "2mA4mpE-11E2B4G1B3I3A3C4E2F3B4E4D4rmeD7E4jlbA-8uG1B6d1paB2E3C1F3H3A8A5D4F4A3E3B2A7A32B13B9tkeG4H3D3mA9C11A5e1cvA2I3A1ptkE2B2G2phhC2B2C8B3E4B2C3H2C3B10C6ogaD3D2TF2wqe1B2E2B2qlE1GC3C1xzkB4D1H4C6D4A2F3F4H2B1B3D1A2C5s==",
  creditLabel: false,
});

const VueFusionCharts = VueFusionChartsComponent(
  FusionCharts,
  StackedBar2D,
  FusionTheme
);

export default {
  components: {
    VueFusionCharts,
  },
  data: () => ({}),
  computed: {},
  methods: {
    getId() {
      return `c-${uuidv4()}`;
    },
  },
  props: {
    width: { type: String, required: true },
    height: { type: String, required: true },
    group: { type: Object, required: true },
    dataSource: { type: Object, required: true },
    enableDrill: { type: Boolean, default: true },
  },
};
</script>

<style lang="scss" scoped>
.chart-title {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
