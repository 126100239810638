<template>
  <fragment>
    <tr>
      <td
        :class="{ 'font-weight-bold': all }"
        :style="{ 'padding-left': margin ? `${margin}px` : `0` }"
      >
        {{ item.name }}
      </td>
      <td :class="{ 'font-weight-bold': all }">
        {{ origAverage.toFixed(2) }}
      </td>
      <td :class="{ 'font-weight-bold': all }">
        {{ average.toFixed(2) }}
      </td>
      <td>
        <v-icon v-if="trend === 1" :color="rowColor">mdi-thumb-up</v-icon>
        <v-icon v-else-if="trend === 0">mdi-thumbs-up-down</v-icon>
        <v-icon v-else :color="rowColor">mdi-thumb-down</v-icon>
      </td>
    </tr>
    <fragment v-if="!all && item.id.startsWith('c_')">
      <CompareRow
        v-for="c in item.children"
        :key="c"
        :item="getChildItem(c)"
        :margin="margin + 40"
        :filter="filter"
        :survey="survey"
        :report="report"
        :overview="overview"
        :compareOverview="compareOverview"
        :overviewWithFilter="overviewWithFilter"
        :compareOverviewWithFilter="compareOverviewWithFilter"
      />
    </fragment>
  </fragment>
</template>

<script>
import { Fragment } from "vue-fragment";

export default {
  name: "CompareRow",
  components: { Fragment },
  computed: {
    origAverage() {
      const overview = this.filter
        ? this.compareOverviewWithFilter
        : this.compareOverview;

      if (this.all) {
        return overview?.all[0].average ?? 0;
      }

      const isQuestion = this.item.id.startsWith("q");
      const itemId = parseInt(this.item.id.split("_")[1]);

      const json = JSON.parse(this.overview.reportCombinationJson);
      const compareId = isQuestion
        ? Object.keys(json.surveys[0].questions).find(
            (k) => parseInt(json.surveys[0].questions[k]) === itemId
          )
        : Object.keys(json.surveys[0].calculationGroups).find(
            (k) => parseInt(json.surveys[0].calculationGroups[k]) === itemId
          );

      const compareOverviewItem = isQuestion
        ? overview?.questions.find((q) => q.id === `q_${compareId}`)
        : overview?.majorGroups
            .concat(overview.minorGroups)
            .find((c) => c.id === `c_${compareId}`);

      return compareOverviewItem?.average ?? 0;
    },
    average() {
      const overview = this.filter ? this.overviewWithFilter : this.overview;

      if (this.all) {
        return overview?.all[0].average ?? 0;
      }

      const isQuestion = this.item.id.startsWith("q");
      const itemId = this.item.id.split("_")[1];
      const overviewItem = isQuestion
        ? overview.questions.find((q) => q.id === `q_${itemId}`)
        : overview.majorGroups
            .concat(overview.minorGroups)
            .find((c) => c.id === `c_${itemId}`);
      const avg = overviewItem?.average ?? 0;

      return avg;
    },
    trend() {
      return this.average > this.origAverage
        ? 1
        : this.average < this.origAverage
        ? -1
        : 0;
    },
    rowColor() {
      if (this.trend === 0) {
        return "";
      } else if (this.trend === 1) {
        return this.$config?.report?.chartColorGreen || "#4CAF50";
      } else {
        return this.$config?.report?.chartColorRed || "#F44336";
      }
    },
  },
  methods: {
    getChildItem(id) {
      const isQuestion = id.startsWith("q");
      const itemId = id.split("_")[1];
      const overview = this.filter ? this.overviewWithFilter : this.overview;
      const overviewItem = isQuestion
        ? overview.questions.find((q) => q.id === `q_${itemId}`)
        : overview.majorGroups
            .concat(overview.minorGroups)
            .find((c) => c.id === `c_${itemId}`);

      return overviewItem;
    },
  },
  props: {
    item: Object,
    margin: { type: Number, default: 0 },
    filter: { type: Boolean, default: false },
    all: { type: Boolean, default: false },
    survey: { type: Object, required: true },
    report: { type: Array, required: true },
    overview: { type: Object, required: true },
    compareOverview: { type: Object, required: true },
    overviewWithFilter: Object,
    compareOverviewWithFilter: Object,
  },
};
</script>
