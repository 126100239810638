<template>
  <div>
    <VoterNavigation
      v-if="overview"
      :user="userEmail"
      @languageChanged="languageChanged"
    />

    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col md="10" lg="8">
          <v-card class="elevation-12 main-content">
            <v-card-title v-if="overview">
              {{ overview.surveyName }}
              <v-spacer></v-spacer>
              <v-btn
                v-if="survey.PreviousSurveyGuid"
                color="info"
                :to="{
                  name: 'token-compare',
                  params: { guid: $route.params.guid },
                }"
                exact
                >{{ $t("voter.report.comparisonReport") }}</v-btn
              >
            </v-card-title>
            <v-card-text>
              <LoadingIndicator
                :isLoading="isLoading && !overviewWithFilterLoading"
              />

              <div v-if="overview">
                <!-- Rinkulat -->
                <div class="mb-10">
                  <ReportSurveyOverview :overview="overview" />
                </div>
                <!-- Tabit -->
                <v-tabs
                  v-model="tab"
                  color="basil"
                  grow
                  slider-size="0"
                  active-class="custom-v-tabs-active-tab"
                >
                  <v-tab @click="tabClick"
                    >{{ $t("voter.report.show") }}
                    {{ $t("voter.report.overall") }}</v-tab
                  >
                  <v-tab
                    v-for="item in overview.predefined"
                    :key="item.id"
                    @click="tabClick"
                  >
                    {{ $t("voter.report.show") }} {{ item.question }}
                  </v-tab>
                </v-tabs>
                <div class="custom-v-tabs-slider"></div>
                <v-tabs-items v-model="tab">
                  <v-tab-item
                    ><div class="d-flex justify-end">
                      <v-switch
                        v-model="showLegend"
                        :label="$t('voter.report.showDescriptions')"
                      ></v-switch>
                    </div>
                    <ReportAllTopBottom
                      :title="$t('voter.report.overall')"
                      :overview="overview"
                      @groupClicked="groupClicked"
                      v-show="showGroup === 0 || showGroup === 1"
                      :showLegend="showLegend"
                    />
                    <ReportCalculationGroups
                      :title="$t('voter.report.subAreas')"
                      :overview="overview"
                      @groupClicked="groupClicked"
                      v-show="showGroup === 0 || showGroup === 2"
                      :showLegend="showLegend"
                    />
                  </v-tab-item>
                  <v-tab-item
                    v-for="item in overview.predefined"
                    :key="item.id"
                  >
                    <v-expansion-panels class="mt-3">
                      <v-expansion-panel
                        active-class="active-panel"
                        v-for="(selection, sIdx) in item.selections"
                        :key="selection.id"
                        :style="{
                          'border-right': '1px solid #f2f2f2',
                          'border-left': '1px solid #f2f2f2',
                        }"
                      >
                        <v-expansion-panel-header
                          :ref="`panel-header-${selection.id}`"
                          @click="clicked(item.id, selection.id)"
                        >
                          <v-row>
                            <v-col class="d-flex align-center" cols="3">{{
                              selection.selection
                            }}</v-col>
                            <v-col
                              cols="6"
                              :style="{
                                color: getRowColor(
                                  sIdx,
                                  item.selections.length
                                ),
                              }"
                              class="d-flex align-center justify-space-between"
                            >
                              <v-progress-linear
                                height="25"
                                :color="
                                  getRowColor(sIdx, item.selections.length)
                                "
                                :value="
                                  selection.average * (100 / selection.max)
                                "
                              >
                                <span>{{
                                  selection.average
                                    ? selection.average.toFixed(2)
                                    : 0
                                }}</span></v-progress-linear
                              >
                            </v-col>
                            <v-col
                              cols="3"
                              class="d-flex justify-end align-center"
                              :style="{
                                color: getRowColor(
                                  sIdx,
                                  item.selections.length
                                ),
                              }"
                              >{{
                                `${selection.answers} / ${selection.voters} (${
                                  selection.voters > 0
                                    ? (
                                        (selection.answers / selection.voters) *
                                        100
                                      ).toFixed(0)
                                    : 0
                                }%)`
                              }}</v-col
                            >
                          </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <LoadingIndicator
                            :isLoading="overviewWithFilterLoading"
                          />
                          <ReportTemplatesList
                            v-if="!overviewWithFilterLoading"
                            :survey="survey"
                            :report="report"
                            :question="item"
                            :selection="selection"
                            :reportTemplates="reportTemplates"
                            :reportIsLoading="reportIsLoading"
                            @getWebReport="getReport"
                            @downloadWordReport="downloadWordReport"
                          />

                          <div class="d-flex justify-end">
                            <v-switch
                              v-show="!overviewWithFilterLoading"
                              v-model="showLegend"
                              :label="$t('voter.report.showDescriptions')"
                            ></v-switch>
                          </div>
                          <ReportQuestionSelection
                            v-if="!overviewWithFilterLoading"
                            :survey="survey"
                            :report="report"
                            :item="item"
                            :selection="selection"
                            :reportTemplates="reportTemplates"
                            :overviewWithFilter="overviewWithFilter"
                            :overviewWithFilterLoading="
                              overviewWithFilterLoading
                            "
                            :reportIsLoading="reportIsLoading"
                            :showLegend="showLegend"
                            @getWebReport="getReport"
                            @downloadWordReport="downloadWordReport"
                            bgColor="#ffffff"
                          />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-tab-item>
                </v-tabs-items>
              </div>
              <div v-if="notFound">
                <v-alert type="error">
                  {{ $t("voter.report.notFound") }}
                </v-alert>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
.active-panel {
  background-color: #f6f6f6;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

import LoadingIndicator from "@/components/LoadingIndicator";
import ReportCalculationGroups from "@/components/Report/ReportCalculationGroups";
import ReportAllTopBottom from "@/components/Report/ReportAllTopBottom";
import ReportQuestionSelection from "@/components/Report/ReportQuestionSelection";
import ReportSurveyOverview from "@/components/Report/ReportSurveyOverview";
import ReportTemplatesList from "@/components/Report/ReportTemplatesList";
import VoterNavigation from "@/components/VoterNavigation";

export default {
  name: "token-report",
  components: {
    LoadingIndicator,
    ReportCalculationGroups,
    ReportAllTopBottom,
    ReportQuestionSelection,
    ReportSurveyOverview,
    ReportTemplatesList,
    VoterNavigation,
  },
  data: () => ({
    tab: 0,
    openPanel: undefined,
    overviewWithFilterLoading: false,
    reportDialog: false,
    showGroup: 0,
    showLegend: false,
    userEmail: "",
    notFound: false,
  }),
  computed: {
    ...mapGetters({
      reportIsLoading: "anonymous/reportLoading",
      surveyIsLoading: "anonymous/surveyLoading",
      survey: "anonymous/survey",
      report: "anonymous/report",
      overview: "anonymous/overview",
      overviewWithFilter: "anonymous/overviewWithFilter",
      overviewLoading: "anonymous/overviewLoading",
    }),
    isLoading() {
      return (
        this.reportIsLoading || this.surveyIsLoading || this.overviewLoading
      );
    },
    reportTemplates() {
      return this.survey !== null ? this.survey.ReportTemplates : [];
    },
  },
  methods: {
    ...mapActions({
      getExternalSurvey: "anonymous/getSurveyByReportToken",
      getReportOverview: "anonymous/getReportOverviewByReportToken",
      getWebReport: "anonymous/getWebReportByReportToken",
      getReportOverviewWithFilter:
        "anonymous/getReportOverviewWithFilterByReportToken",
      clearOverview: "anonymous/clearOverview",
      clearOverviewWithFilter: "anonymous/clearOverviewWithFilter",
    }),
    clicked: async function (questionId, selectionId) {
      if (this.openPanel !== selectionId) {
        this.openPanel = selectionId;
        this.clearOverviewWithFilter();
        this.overviewWithFilterLoading = true;
        await this.getReportOverviewWithFilter({
          guid: this.$route.params.guid,
          questionId: questionId,
          selectionId: selectionId,
        });
        this.overviewWithFilterLoading = false;
      } else {
        this.openPanel = undefined;
      }
    },
    tabClick: function () {
      if (this.openPanel) {
        let panel = this.$refs[`panel-header-${this.openPanel}`][0];
        panel.$el.click();
      }
    },
    getAnswerPercentageColor(answerCount, voterCount) {
      if (voterCount === 0) {
        return this.$config?.report?.chartColorRed || "#F44336";
      }
      let percentage = (answerCount / voterCount) * 100;

      let highAnswerPercentageMin =
        this.$config?.report?.highAnswerPercentageMin ?? 66;
      let mediumAnswerPercentageMin =
        this.$config?.report?.mediumAnswerPercentageMin ?? 33;

      if (percentage >= highAnswerPercentageMin) {
        return this.$config?.report?.chartColorGreen || "#4CAF50";
      } else if (percentage >= mediumAnswerPercentageMin) {
        return this.$config?.report?.chartColorYellow || "#FFEB3B";
      } else {
        return this.$config?.report?.chartColorRed || "#F44336";
      }
    },
    getRowColor(index, count) {
      if (count === 1) {
        return this.$config?.report?.chartColorBlue || "#2196F3";
      }
      let colorize = Math.round(count / 4);
      if (index + 1 <= colorize) {
        return this.$config?.report?.chartColorGreen || "#4CAF50";
      } else if (count - index <= colorize) {
        return this.$config?.report?.chartColorRed || "#F44336";
      } else {
        return this.$config?.report?.chartColorYellow || "#FFEB3B";
      }
    },
    getReport(guid, reportId, questionId, selectionId) {
      this.getWebReport({
        guid: this.$route.params.guid,
        reportId: reportId,
        questionId: questionId,
        selectionId: selectionId,
      });
    },
    async downloadWordReport(report, question, selection) {
      this.$store.commit("anonymous/SET_REPORT_LOADING", true);

      try {
        let response = await axios({
          method: "POST",
          url: `/api/anonymous/report/wordreport`,
          data: {
            Guid: this.$route.params.guid,
            ReportId: report.Id,
            Filter: {
              Id: question.id,
              Selected: [selection.id],
            },
          },
          responseType: "blob",
        });

        const blob = new Blob([response.data]);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${report.Name} - ${question.question} - ${selection.selection}.docx`;
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (e) {
        console.log(e);
      }

      this.$store.commit("anonymous/SET_REPORT_LOADING", false);
    },
    groupClicked(event) {
      this.showGroup = event;
    },
    async languageChanged() {
      this.clearOverview();
      this.getExternalSurvey(this.$route.params.guid);
      this.getReportOverview(this.$route.params.guid);
    },
  },
  async created() {
    this.changeLanguage("fi");

    await this.getExternalSurvey(this.$route.params.guid);

    if (this.survey) {
      this.userEmail = this.survey.Email;
      this.getReportOverview(this.$route.params.guid);
    } else {
      this.notFound = true;
    }
  },
};
</script>
