<template>
  <v-form>
    <div class="d-flex align-center">
      <v-text-field
        v-model="surveyDetails.name"
        :error-messages="nameErrors"
        required
        @input="$v.surveyDetails.name.$touch()"
        @blur="$v.surveyDetails.name.$touch()"
        label="Mittauksen nimi"
      ></v-text-field>
      <v-icon color="red" v-if="nameErrors.length"
        >mdi-exclamation-thick</v-icon
      >
    </div>
    <v-row>
      <v-col>
        <v-select
          v-model="surveyDetails.owner"
          :items="userNames"
          clearable
          placeholder="Mittauksen hallinnoija"
          label="Mittauksen hallinnoija (Valitse järjestelmän käyttäjistä)"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        ><h4>
          Tilaajalle voidaan Tulokset-osiosta lähettää sähköpostilla linkki
          mittauksen tuloksiin ja raportteihin.
        </h4></v-col
      >
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="surveyDetails.contact"
          label="Mittauksen tilaaja"
        ></v-text-field>
      </v-col>
      <v-col>
        <div class="d-flex align-center">
          <v-text-field
            v-model="surveyDetails.contactDetails"
            label="Tilaajan sähköpostiosoite"
            :error-messages="contactDetailsErrors"
            email
          ></v-text-field>
          <v-icon color="red" v-if="contactDetailsErrors.length"
            >mdi-exclamation-thick</v-icon
          >
        </div>
      </v-col>
    </v-row>
    <v-row
      ><v-col
        ><h4>
          Voit yksilöidä, mihin yksikköön/alueeseen mittaus kuuluu.
        </h4></v-col
      ><v-col>
        <h4>
          <span>Vastaajien lisäys puhelinnumerolla.</span><br /><span
            >Tekstiviesteillä lähetetyt viestit arvioitaville ja vastaajille
            veloitetaan toteutuneen mukaan ({{ smsPrice }} € / viesti).</span
          >
        </h4></v-col
      ></v-row
    >
    <v-row>
      <v-col>
        <v-combobox
          v-model="surveyDetails.unit"
          :items="units"
          clearable
          label="Yksikkö"
        ></v-combobox>
      </v-col>
      <v-col>
        <v-checkbox
          color="info"
          v-model="surveyDetails.smsEnabled"
          label="Vastaajien lisäys puhelinnumerolla"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row
      ><v-col></v-col
      ><v-col
        ><h4>
          Edu-mittaukset ovat opetushallituksen alaisissa tutkintoonjohtavissa
          koulutuksissa käytettäviä mittauksia.
        </h4></v-col
      ></v-row
    >
    <v-row>
      <v-col cols="6">
        <div class="d-flex align-center">
          <v-text-field
            v-model="surveyDetails.reference"
            label="Laskutusviite"
            required
            @input="$v.surveyDetails.reference.$touch()"
            @blur="$v.surveyDetails.reference.$touch()"
            :error-messages="referenceErrors"
          ></v-text-field>
          <v-icon color="red" v-if="referenceErrors.length"
            >mdi-exclamation-thick</v-icon
          >
        </div>
      </v-col>
      <v-col>
        <v-select
          v-model="surveyDetails.class"
          :items="classes"
          label="Tyyppi"
          item-text="name"
          item-value="id"
        ></v-select>
      </v-col>
    </v-row>

    <!-- Ei näytetä jos käytetään käännöksiin. Voisiko tehdä järkevämmin ? -->
    <div v-if="language === undefined">
      <v-row>
        <v-col md="6">
          <DatePickerInput
            label="Mittauksen alkamispäivämäärä"
            :date.sync="surveyDetails.openDate"
            required
            :min="openDateMin"
            :max="dateMax"
            :errors="openDateErrors"
            :readonly="datesReadOnly"
          />
        </v-col>
        <v-col md="6">
          <DatePickerInput
            label="Mittauksen päättymispäivämäärä"
            :date.sync="surveyDetails.closeDate"
            required
            :min="closeDateMin"
            :max="dateMax"
            :errors="closeDateErrors"
            :readonly="datesReadOnly"
          />
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

import DatePickerInput from "@/components/DatePickerInput";

export default {
  mixins: [validationMixin],
  components: {
    DatePickerInput,
  },
  computed: {
    ...mapGetters({
      survey: "survey/survey",
      units: "survey/units",
      classes: "survey/classes",
      users: "user/users",
    }),
    smsPrice() {
      return this.$config.survey?.smsPrice ?? 0.45;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.surveyDetails.name.$dirty) return errors;
      !this.$v.surveyDetails.name.required && errors.push("Pakollinen");
      return errors;
    },
    contactDetailsErrors() {
      const errors = [];
      !this.$v.surveyDetails.contactDetails.email &&
        errors.push("Syötä sähköpostiosoite");
      return errors;
    },
    referenceErrors() {
      const errors = [];
      if (!this.$v.surveyDetails.reference.$dirty) return errors;
      !this.$v.surveyDetails.reference.required && errors.push("Pakollinen");
      return errors;
    },
    openDateErrors() {
      const errors = [];
      if (!this.$v.surveyDetails.openDate.$dirty) return errors;
      !this.$v.surveyDetails.openDate.required && errors.push("Pakollinen");
      return errors;
    },
    closeDateErrors() {
      const errors = [];
      if (!this.$v.surveyDetails.closeDate.$dirty) return errors;
      !this.$v.surveyDetails.closeDate.required && errors.push("Pakollinen");
      return errors;
    },
    userNames: function () {
      return this.users.map((u) => u.name);
    },
    openDateMin: function () {
      return moment().format("YYYY-MM-DD");
    },
    closeDateMin: function () {
      if (this.survey?.Id !== 0) {
        return moment(this.surveyDetails.openDate).format("YYYY-MM-DD");
      }
      return moment().format("YYYY-MM-DD");
    },
    dateMax: function () {
      if (this.survey?.Id !== 0) {
        return moment(this.survey.CreateDate).add(3, "M").format("YYYY-MM-DD");
      }
      return moment().add(3, "M").format("YYYY-MM-DD");
    },
    datesReadOnly: function () {
      if (this.survey?.Id === 0) {
        return false;
      }

      var createDate = moment(this.survey.CreateDate);

      return createDate < moment().add(-3, "M");
    },
  },
  methods: {
    ...mapActions({
      getSurveyUnits: "survey/getSurveyUnits",
      getSurveyClasses: "survey/getSurveyClasses",
      getExternalUsers: "user/getExternalUsers",
    }),
  },
  validations: {
    surveyDetails: {
      name: { required },
      contactDetails: { email },
      reference: { required },
      openDate: { required },
      closeDate: { required },
    },
  },
  props: {
    surveyDetails: { type: Object, required: true },
    language: Object,
  },
  async mounted() {
    this.getSurveyUnits();
    this.getExternalUsers();
    await this.getSurveyClasses();
    if (!this.surveyDetails.class) {
      this.surveyDetails.class = this.classes[0]?.id;
    }
    this.$v.$touch();
  },
};
</script>

<style></style>
