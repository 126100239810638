var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-text-field',{class:{
    'deleted-text-question':
      !_vm.language && _vm.deletedTextQuestions.includes(_vm.question.Id),
  },attrs:{"label":"Kysymys","required":"","prepend-icon":_vm.language
      ? undefined
      : _vm.deletedTextQuestions.includes(_vm.question.Id)
      ? 'mdi-delete-restore'
      : 'mdi-delete',"error-messages":_vm.questionErrors},on:{"input":_vm.input,"blur":function($event){return _vm.$v.questionQuestion.$touch()},"click:prepend":function($event){return _vm.toggleDeleted(_vm.question.Id)}},model:{value:(_vm.questionQuestion),callback:function ($$v) {_vm.questionQuestion=$$v},expression:"questionQuestion"}})
}
var staticRenderFns = []

export { render, staticRenderFns }