<template>
  <div v-show="!voterLoading || showDialog">
    <h3 class="mb-5">Arvioitavat henkilöt</h3>
    <v-row>
      <v-col md="2" cols="12">
        <Voters360AddDialog
          :predefinedQuestions="predefinedQuestions"
          @setNewVoters="setNewVoters"
          @error="$emit('update:snackbarText', $event)"
        />
      </v-col>
      <v-col md="2" cols="12">
        <Voters360SendMailDialog
          v-if="selectedLanguage"
          :selectedVoters.sync="selectedVoters"
          :voters360="voters360"
          :voterLoading="voterLoading"
          :show="showDialog"
          :selectedLanguage="selectedLanguage"
          :emails="emails"
          @update:selectedLanguage="$emit('update:selectedLanguage', $event)"
          @toggle="toggle"
          @sendEmails="sendEmails"
          @selectChange="checkboxChangedInDialog"
          @showDialogChanged="showDialog = $event"
          @error="$emit('update:snackbarText', $event)"
          @emailTitleChanged="$emit('emailTitleChanged', $event)"
          @emailBodyChanged="$emit('emailBodyChanged', $event)"
        />
      </v-col>
      <v-col offset-md="4" md="4" cols="12" class="d-flex justify-end">
        <v-text-field
          v-model="search360"
          append-icon="mdi-magnify"
          label="Haku"
          class="pa-0 ma-0"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="voters.voters360 > 0">
      <v-col>
        <v-checkbox
          class="pl-3"
          hide-details
          @change="toggle"
          label="Valitse kaikki"
          v-model="allSelected"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-expansion-panels accordion>
        <v-expansion-panel v-for="v in voters360filtered" :key="v.selection.id">
          <v-expansion-panel-header>
            <div class="d-flex align-center">
              <v-checkbox
                @click.native.stop
                class="flex-shrink-1 ma-0 pa-0"
                hide-details
                v-model="checkboxes[v.voter.voterCode]"
                @change="checkboxChanged(v.voter.voterCode)"
              ></v-checkbox>
              <strong>
                <span class="mr-1">{{ v.selection.selection }}</span
                ><span class="mr-1">({{ v.selection.email }})</span>
                <VoterRole
                  class="mr-1"
                  v-for="v in v.voters.filter(
                    (vv) => vv.selection.id !== survey.Options.Selection360Self
                  )"
                  :key="v.voterCode"
                  :text="v.selection.selection"
                  :count="v.emails.length"
                  :excludeRole="
                    v.selection.id === survey.Options.Selection360Superior
                  "
                  onlyFirstLetter
                />
                <!-- TODO: Selvitä miksi ekalla renderöinnillä voters360 tulee tyhjänä -->
                <v-icon
                  color="success"
                  v-if="v.voter && !!v.voter.allVotersAdded"
                  title="Käyttäjä on lisännyt kaikki vastaajat"
                  >mdi-check</v-icon
                >
              </strong>
            </div>
          </v-expansion-panel-header>
          <!-- TODO: Selvitä miksi vaatii tämän -->
          <v-expansion-panel-content v-if="v.voter">
            <Voters360Voter
              :voter360="v"
              :predefinedQuestions="predefinedQuestions"
              :survey="survey"
              :voterLoading="voterLoading"
              @predefinedChanged="predefinedChanged"
              @setVoter="setVoter"
              @addVoter="addVoter"
              @deleteVoter="confirmDelete"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-dialog v-model="currentVoter.visible" persistent width="480">
      <v-card>
        <v-card-title class="headline"> Muokkaa vastaajaa </v-card-title>
        <v-card-text>
          <v-text-field
            label="Email"
            v-model="currentVoter.email"
            :disabled="voterLoading || currentVoter.answered"
          ></v-text-field>
          <v-select
            :items="survey.Languages"
            label="Kieli"
            v-model="currentVoter.languageCode"
            :disabled="voterLoading || currentVoter.answered"
            item-text="Language"
            item-value="Code"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <span v-if="voterLoading">Tallennetaan...</span>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="currentVoter.visible = false"
            :disabled="voterLoading"
          >
            Peruuta
          </v-btn>
          <v-btn @click="editVoter" :disabled="voterLoading"> Tallenna </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Varmista poisto
        </v-card-title>

        <v-card-text> Haluatko varmasti poistaa arvioijan ? </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="deleteDialog = false"> Peruuta </v-btn>
          <v-btn @click="deleteVoterClick"> Kyllä </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Voters360AddDialog from "@/components/Voters/Voters360AddDialog";
import Voters360Voter from "@/components/Voters/Voters360Voter";
import VoterRole from "@/components/Voters/VoterRole";
import Voters360SendMailDialog from "@/components/Voters/Voters360SendMailDialog";

export default {
  components: {
    Voters360AddDialog,
    Voters360Voter,
    VoterRole,
    Voters360SendMailDialog,
  },
  computed: {
    ...mapGetters({
      survey: "survey/survey",
      voterLoading: "voter/isLoading",
      voters: "voter/voters",
    }),
    selectionEmails: function () {
      return this.predefinedQuestions
        .find((q) => q.questionFor360)
        .selections.map((s) => s.email)
        .filter((e) => e && e.length > 0);
    },
    voters360filtered: function () {
      if (this.search360.length > 0) {
        return this.voters360.filter((v) => {
          const selFound =
            v.selection.selection
              .toLowerCase()
              .indexOf(this.search360.toLowerCase()) >= 0;
          const emailFound =
            v.selection.email &&
            v.selection.email
              .toLowerCase()
              .indexOf(this.search360.toLowerCase()) >= 0;

          return selFound || emailFound;
        });
      }
      return this.voters360;
    },
  },
  data: () => ({
    currentVoter: {
      email: "",
      languageCode: "",
      code: "",
      answered: false,
      visible: false,
    },
    rules: {
      required: (value) => !!value || "Pakollinen kenttä",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Virheellinen email";
      },
    },
    search360: "",
    emailsDialog: false,
    selectedVoters: [],
    showDialog: false,
    deleteDialog: false,
    checkboxes: {},
    allSelected: false,
  }),
  methods: {
    ...mapActions({
      getExternalSurvey: "survey/getExternalSurvey",
      getVoters: "voter/getVoters",
      deleteVoter: "voter/deleteVoter",
      postVoters: "voter/postVoters",
      putVoter: "voter/putVoter",
      sendNewVoterMail: "voter/sendNewVoterMail",
    }),
    setVoter(voter, edit) {
      this.currentVoter.email = voter.email;
      this.currentVoter.languageCode = voter.languageCode;
      this.currentVoter.code = voter.code;
      this.currentVoter.answered = voter.answered;
      this.currentVoter.visible = edit !== undefined ? edit : true;
    },
    async addVoter(email, voter, selection) {
      if (!email || email.length === 0) {
        return;
      }

      let answers = Object.assign({}, voter.predefined);

      for (const key in answers) {
        if (answers[key].trim() === "") {
          delete answers[key];
        }
      }

      let newVoter = {
        // TODO: Halutaanko että käyttäjä voi valita ?
        LanguageCode: undefined,
        VoterEmail: email,
        Answers: answers,
      };

      newVoter.Answers[voter.question.id] = voter.selection.selection;
      newVoter.Answers[selection.question.id] = selection.selection.selection;

      let voters = {
        surveyGuid: this.survey.Guid,
        voters: [newVoter],
      };

      await this.postVoters(voters);
      this.getVoters(this.survey.Guid);
    },
    confirmDelete(voter) {
      this.setVoter(voter, false);
      this.deleteDialog = true;
    },
    async deleteVoterClick() {
      this.deleteDialog = false;
      await this.deleteVoter(this.currentVoter.code);
      this.getVoters(this.survey.Guid);
    },
    async editVoter() {
      let o = {
        email: this.currentVoter.email,
        code: this.currentVoter.code,
        languageCode: this.currentVoter.languageCode,
      };

      let email = o.email;
      const validEmail = this.rules.email(email);

      if (!email || email.length === 0) {
        return;
      }

      if (validEmail !== true) {
        if (parseInt(email)) {
          email += "@textmagic.com";
        } else {
          return;
        }
      }

      let voter = {
        LanguageCode: o.languageCode,
        VoterEmail: email,
        VoterCode: o.code,
      };

      if (await this.putVoter(voter)) {
        this.currentVoter.visible = false;
        this.getVoters(this.survey.Guid);
      }
    },
    async predefinedChanged(voter360, questionId, selection) {
      if (selection === null || selection.trim().length === 0) {
        this.$emit("update:snackbarText", "Et voi tallentaa tyhjää arvoa.");
        return;
      }

      let newSelection = {};
      newSelection[questionId] = selection;
      let answers = Object.assign(voter360.voter.answers, newSelection);

      let voter = {
        LanguageCode: voter360.voter.languageCode,
        VoterEmail: voter360.voter.voterEmail,
        VoterCode: voter360.voter.voterCode,
        Answers: answers,
      };

      await this.putVoter(voter);

      let emails = voter360.voters
        .filter((s) => s.selection.id !== this.survey.Options.Selection360Self)
        .reduce((acc, curr) => {
          acc = acc.concat(
            curr.emails.map((e) => {
              let email = Object.assign({}, e);
              email.answers = Object.assign(
                {},
                this.voters.find((v) => v.voterCode === e.code).answers
              );
              email.answers[questionId] = voter360.voter.answers[questionId];
              return email;
            })
          );
          return acc;
        }, []);

      this.getExternalSurvey(this.survey.Guid);

      await Promise.all(
        emails.map(async (v) => {
          let voter = {
            LanguageCode: v.languageCode,
            VoterEmail: v.email,
            VoterCode: v.code,
            Answers: v.answers,
          };
          await this.putVoter(voter);
        })
      );

      this.getVoters(this.survey.Guid);
    },
    async setNewVoters(emails) {
      await this.getExternalSurvey(this.survey.Guid);
      await this.getVoters(this.survey.Guid);

      this.selectedVoters = this.voters360
        .map((v) => v.voter)
        .filter((v) => emails.includes(v.voterEmail))
        .map((v) => v.voterCode);

      this.selectedVoters.forEach((v) => {
        this.checkboxes[v] = true;
      });

      this.showDialog = true;
    },
    async sendEmails() {
      let model = {
        surveyGuid: this.survey.Guid,
        mailModels: [],
      };

      Object.keys(this.emails).forEach((languageCode) => {
        let newVotersModel = {
          title: this.emails[languageCode].title,
          body: this.emails[languageCode].body,
          languageCode: languageCode,
          voters: this.voters360
            .filter((v) => this.selectedVoters.includes(v.voter.voterCode))
            .map((v) => ({
              languageCode: v.voter.languageCode,
              voterEmail: v.voter.voterEmail,
            }))
            .filter((v) => v.languageCode === languageCode),
        };

        if (newVotersModel.voters.length) {
          model.mailModels.push(newVotersModel);
        }
      });

      let emailsOk =
        model.mailModels.length > 0 &&
        model.mailModels.filter(
          (m) => m.title.length === 0 || m.body.length === 0
        ).length === 0;

      if (!emailsOk) {
        this.$emit("update:snackbarText", "Tarkasta viestit.");
        return;
      }

      await this.sendNewVoterMail(model);

      this.$emit("update:snackbarText", "Viestit lähetetty.");

      this.showDialog = false;
      this.selectedVoters = [];
      Object.keys(this.checkboxes).forEach((v) => {
        this.checkboxes[v] = false;
      });
      this.allSelected = false;
    },
    toggleSelected() {
      if (this.selectedVoters.length === this.voters360.length) {
        this.selectedVoters = [];
        Object.keys(this.checkboxes).forEach((v) => {
          this.checkboxes[v] = false;
        });
        this.allSelected = false;
      } else {
        let voters = this.voters360.map((v) => v.voter.voterCode);
        this.selectedVoters = voters;
        voters.forEach((v) => {
          this.checkboxes[v] = true;
        });
        this.allSelected = true;
      }
    },
    toggle() {
      this.$nextTick(() => {
        this.toggleSelected();
      });
    },
    checkboxChanged(voterCode) {
      let selected = !!this.checkboxes[voterCode];
      if (selected) {
        this.selectedVoters.push(voterCode);
      } else {
        this.selectedVoters = this.selectedVoters.filter(
          (v) => v !== voterCode
        );
      }
      if (this.selectedVoters.length === this.voters360.length) {
        this.allSelected = true;
      }
      if (this.selectedVoters.length === 0) {
        this.allSelected = false;
      }
    },
    checkboxChangedInDialog(selectedVoterCodes) {
      selectedVoterCodes.forEach((v) => {
        this.checkboxes[v] = true;
      });
      Object.keys(this.checkboxes)
        .filter((v) => !selectedVoterCodes.includes(v))
        .forEach((v) => {
          this.checkboxes[v] = false;
        });
      if (selectedVoterCodes.length === this.voters360.length) {
        this.allSelected = true;
      }
      if (selectedVoterCodes.length === 0) {
        this.allSelected = false;
      }
    },
  },
  props: {
    voters360: { type: Array, required: true },
    predefinedQuestions: { type: Array, required: true },
    selectedLanguage: Object,
    emails: { type: Object, required: true },
  },
};
</script>

<style></style>
