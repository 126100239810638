<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="3">
        <v-card class="elevation-12">
          <v-card-title> Vaihda uusi salasana </v-card-title>
          <v-card-text>
            <p>Anna uusi salasana. Salasanan minimipituus on 8 merkkiä.</p>
            <v-form @submit.prevent>
              <v-text-field
                :disabled="loading"
                label="Salasana"
                prepend-icon="mdi-lock"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                :color="isValidPassword(form.password) ? 'success' : null"
                required
                @keyup.enter="submit"
                v-model="form.password"
              ></v-text-field>
              <v-text-field
                :disabled="loading"
                label="Salasana uudestaan"
                prepend-icon="mdi-lock"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                :color="isValid ? 'success' : null"
                required
                @keyup.enter="submit"
                v-model="form.passwordAgain"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="default" text to="/login">Peruuta</v-btn>
            <v-btn
              color="primary"
              @click="submit"
              :disabled="loading || !isValid"
              >Vaihda salasana</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "reset",
  components: {},
  data: () => ({
    form: {
      password: "",
      passwordAgain: "",
    },
    loading: false,
    showPassword: false,
  }),
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
    }),
    isValid() {
      let first = this.form.password;
      let second = this.form.passwordAgain;

      return (
        this.isValidPassword(first) &&
        this.isValidPassword(second) &&
        first === second
      );
    },
  },
  methods: {
    ...mapActions({
      reset: "auth/reset",
    }),
    async submit() {
      if (this.isValid) {
        this.loading = true;

        let token = this.$route.params.token;

        let data = {
          resettoken: token,
          newPassword: this.form.password,
          newPasswordAgain: this.form.passwordAgain,
        };

        await this.reset(data);

        if (this.authenticated) {
          this.$toast.center("Salasana päivitettiin.");
          this.$router.replace({
            path: "/",
          });
        } else {
          this.$toast.center("Salasanan päivittäminen ei onnistunut.");
        }

        this.loading = false;
      }
    },
  },
};
</script>
