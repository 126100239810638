<template>
  <v-app :style="{ backgroundImage: `url(${bg})` }">
    <Navigation v-if="$route.matched.some(({ name }) => name !== 'voter')" />
    <v-main style="margin-top: 64px">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation";

import bg from "@/assets/blurred_bg.jpeg";

export default {
  components: {
    Navigation,
  },
  data: () => ({
    bg: bg,
  }),
};
</script>

<style lang="scss">
$include: fi, gb;

@import "flag-icons/sass/flag-icons.scss";

#app {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.v-btn.no-active:hover::before {
  opacity: 0;
}

@media (max-width: 480px) {
  .mobile-btn {
    width: 100%;
    margin: 0 0 10px 0 !important;
  }
}

$card-padding: 32px;

.v-card__title {
  padding-left: $card-padding;
  padding-right: $card-padding;
}

.v-card__text {
  padding-left: $card-padding;
  padding-right: $card-padding;
}

.v-card__actions {
  padding-left: $card-padding;
  padding-right: $card-padding;
}

.custom-v-tabs-active-tab {
  &:hover {
    background-color: #e6e6e6 !important;
  }
  &::before {
    opacity: 0 !important;
  }
  background-color: #e6e6e6 !important;
}

.custom-v-tabs-slider {
  border-top: 1px solid #c0c0c0;
}

.v-tab {
  &:hover:not(.custom-v-tabs-active-tab) {
    background-color: #f9f9f9 !important;
  }
  &::before {
    opacity: 0 !important;
  }
  background-color: #f9f9f9;
}
</style>
