<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col md="10" lg="8">
        <v-card class="elevation-12 main-content">
          <v-card-title>
            Luo uusi mittaus <span v-if="survey">- {{ survey.Name }}</span>
          </v-card-title>
          <v-card-text>
            <v-stepper v-model="step" class="elevation-1" non-linear>
              <v-stepper-header>
                <template v-for="s in steps">
                  <v-stepper-step
                    :complete="step > s.step"
                    :key="s.step"
                    :step="s.step"
                    :editable="survey !== null"
                    color="info"
                    >{{ s.text }}</v-stepper-step
                  >
                  <v-divider
                    v-if="s.step < steps.length"
                    :key="-s.step"
                  ></v-divider>
                </template>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-container v-if="surveys">
                    <v-row>
                      <v-col
                        cols="12"
                        :md="selectedSurvey === s.guid ? 12 : 6"
                        v-for="s in surveys"
                        :key="s.guid"
                      >
                        <ExternalSurveyCard
                          :survey="s"
                          :hasSelected="!!selectedSurvey"
                          :selected="selectedSurvey === s.guid"
                          @selectSurveyInfoClick="selectSurvey($event)"
                          @selectSurveyEditClick="selectSurvey($event, 2)"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                  <LoadingIndicator :isLoading="isLoading" />
                  <ExternalSurveyOverview class="mt-3" />
                </v-stepper-content>
                <v-stepper-content step="2">
                  <LoadingIndicator :isLoading="isLoading" />
                  <div v-if="survey">
                    <EditSurveyDetails
                      ref="survey-details"
                      :surveyDetails="surveyDetails"
                    />

                    <EditSurveyPredefinedQuestions
                      ref="survey-predefined"
                      :predefinedQuestions="predefinedQuestions"
                      :selections="selections"
                      :addedSelections="addedSelections"
                      :deletedSelections="deletedSelections"
                      :snackbarText.sync="snackbarText"
                      @add:selection="addSelection($event)"
                      @delete:selection="deleteSelection($event)"
                      @delete:addedSelection="deleteAddedSelection($event)"
                    />

                    <EditSurveyTextQuestions
                      ref="survey-text"
                      :textQuestions="textQuestions"
                      :deletedTextQuestions="deletedTextQuestions"
                      :newTextQuestions="newTextQuestions"
                      :newTextQuestionTranslations="newTextQuestionTranslations"
                    />
                  </div>
                </v-stepper-content>
                <v-stepper-content step="3" v-if="survey">
                  <div v-if="surveyTranslationLanguages.length > 0">
                    <LanguageSelector
                      :languages="surveyTranslationLanguages"
                      :selectedLanguage.sync="selectedLanguage"
                    />

                    <NewTranslations
                      ref="survey-translations"
                      v-if="selectedLanguage"
                      :language="selectedLanguage"
                      :surveyDetails="surveyDetails"
                      :surveyDetailsTranslations="surveyDetailsTranslations"
                      :predefinedQuestions="predefinedQuestions"
                      :deletedTextQuestions="deletedTextQuestions"
                      :deletedSelections="deletedSelections"
                      :newTextQuestions="newTextQuestions"
                      :newTextQuestionTranslations="newTextQuestionTranslations"
                      :textQuestions="textQuestions"
                      :textQuestionTranslations="textQuestionTranslations"
                      :selections="selections"
                      :selectionTranslations="selectionTranslations"
                      :addedSelections="addedSelections"
                      :addedSelectionTranslations="addedSelectionTranslations"
                    />
                  </div>
                  <h3 v-else>Ei käännöksiä</h3>
                </v-stepper-content>
                <v-stepper-content step="4" v-if="survey">
                  <h1 class="mb-3">{{ this.surveyDetails.name }}</h1>
                  <div>
                    <v-row>
                      <v-col md="12">
                        <v-row>
                          <v-col md="6"> Voimassaolo </v-col>
                          <v-col md="6">
                            {{ computedOpenDate }} - {{ computedCloseDate }}
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <h3>Taustatiedot</h3>
                    <v-row v-for="q in predefinedQuestions" :key="q.Id">
                      <v-col md="12">
                        <v-row>
                          <v-col md="6">
                            {{ q.question }}
                          </v-col>
                          <v-col md="6">
                            <ul
                              v-for="s in q.selections.filter(
                                (s) => !deletedSelections.includes(s.Id)
                              )"
                              :key="s.Id"
                              class="pa-0"
                            >
                              <li>
                                {{ s.selection }}
                              </li>
                            </ul>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <h3 v-if="allTextQuestions.length > 0">Tekstikysymykset</h3>
                    <v-row v-for="(q, qIdx) in allTextQuestions" :key="qIdx">
                      <v-col md="12">
                        {{ q.Question }}
                      </v-col>
                    </v-row>
                  </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>
          <v-card-actions>
            <v-btn v-if="step > 1" color="secondary" @click="backClick"
              >Takaisin</v-btn
            >

            <v-spacer></v-spacer>

            <v-btn
              v-if="survey && !isLoading && step < steps.length"
              color="primary"
              @click="forwardClick"
              >Seuraava</v-btn
            >

            <v-btn
              v-if="survey && step === steps.length"
              color="success"
              @click="saveSurvey"
              :loading="isLoading"
              :disabled="isLoading"
              >Tallenna</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">{{ snackbarText }}</v-snackbar>
  </v-container>
</template>

<style scoped>
ul {
  list-style-type: none;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

import EditSurveyDetails from "@/components/Survey/EditSurveyDetails";
import EditSurveyPredefinedQuestions from "@/components/Survey/EditSurveyPredefinedQuestions";
import EditSurveyTextQuestions from "@/components/Survey/EditSurveyTextQuestions";
import ExternalSurveyCard from "@/components/Survey/ExternalSurveyCard";
import ExternalSurveyOverview from "@/components/Survey/ExternalSurveyOverview";
import LanguageSelector from "@/components/LanguageSelector";
import NewTranslations from "@/components/Survey/NewTranslations";
import LoadingIndicator from "@/components/LoadingIndicator";

export default {
  name: "new-survey",
  components: {
    EditSurveyDetails,
    EditSurveyPredefinedQuestions,
    EditSurveyTextQuestions,
    ExternalSurveyCard,
    ExternalSurveyOverview,
    LanguageSelector,
    NewTranslations,
    LoadingIndicator,
  },
  computed: {
    ...mapGetters({
      surveys: "survey/surveys",
      survey: "survey/survey",
      isLoading: "survey/isLoading",
      user: "auth/user",
    }),
    steps: function () {
      return [
        { step: 1, text: "Mittauksen valinta" },
        { step: 2, text: "Mittauksen hallinta" },
        { step: 3, text: "Käännökset" },
        { step: 4, text: "Yhteenveto" },
      ];
    },
    predefinedQuestions: function () {
      if (!this.survey) {
        return [];
      }

      var s = this.survey.Pages.map((p) =>
        p.QuestionGroups.map((g) =>
          g.Questions.filter((q) => q.Predefined && q.CanEdit).map((q) => ({
            Id: q.Id,
            question: q.Question,
            isChoice: q.IsChoice,
            selections: q.Selections.map((s) => ({
              Id: s.Id,
              selection: s.Selection,
              originalSelection: s.Selection,
            })),
          }))
        ).filter((g) => g.length > 0)
      ).filter((p) => p.length > 0);

      return [].concat(...[].concat(...s));
    },
    allTextQuestions: function () {
      const original = this.textQuestions.filter(
        (q) => !this.deletedTextQuestions.includes(q.Id)
      );

      return original.concat(this.newTextQuestions);
    },
    textQuestionsWithoutDeleted: function () {
      return this.textQuestions.filter(
        (q) => !this.deletedTextQuestions.includes(q.Id)
      );
    },
    surveyTranslationLanguages: function () {
      return this.survey.Languages.filter((l) => !l.IsDefault);
    },
    computedOpenDate: function () {
      return this.surveyDetails.openDate
        ? moment(this.surveyDetails.openDate).format("L")
        : "";
    },
    computedCloseDate: function () {
      return this.surveyDetails.closeDate
        ? moment(this.surveyDetails.closeDate).format("L")
        : "";
    },
  },
  data: () => ({
    selectedSurvey: undefined,
    selectedLanguage: undefined,
    step: 1,
    surveyDetails: {
      name: "",
      openDate: undefined,
      closeDate: undefined,
      owner: undefined,
      ownerDetails: undefined,
      contact: undefined,
      contactDetails: undefined,
      unit: undefined,
      smsEnabled: false,
    },
    surveyDetailsTranslations: [],
    newTextQuestions: [],
    newTextQuestionTranslations: [],
    textQuestions: [],
    deletedTextQuestions: [],
    deletedSelections: [],
    addedSelections: [],
    addedSelectionTranslations: [],
    textQuestionTranslations: [],
    selections: [],
    selectionTranslations: [],
    snackbar: false,
    snackbarText: "",
    tab: 1,
  }),
  watch: {
    selectedSurvey: async function (newSelectedSurvey) {
      if (newSelectedSurvey) {
        await this.getSurvey(newSelectedSurvey);
        this.initializeSurvey();
      }
    },
    snackbarText: function (newValue) {
      if (newValue) {
        this.snackbar = true;
        setTimeout(() => {
          this.snackbar = false;
          this.snackbarText = "";
        }, 2000);
      }
    },
  },
  methods: {
    ...mapActions({
      getSurveys: "survey/getSurveys",
      getSurvey: "survey/getSurvey",
      postExternalSurvey: "survey/postExternalSurvey",
    }),
    selectSurvey(guid, step) {
      if (this.isLoading) {
        return;
      }
      if (this.selectedSurvey && this.selectedSurvey == guid && !step) {
        this.selectedSurvey = undefined;
        this.$store.dispatch("survey/clearSurvey");
      } else {
        this.selectedSurvey = guid;
      }
      if (step) {
        this.step = step;
        this.scrollTop();
      }
    },
    addSelection(selection) {
      const id =
        this.addedSelections.length === 0
          ? -1
          : Math.min(...this.addedSelections.map((q) => q.Id)) - 1;
      this.addedSelections.push({
        selection: selection,
        originalSelection: selection,
        Id: id,
      });
      this.addedSelectionTranslations.forEach((l) => {
        l.selections.push({
          Id: id,
          Selection: "",
        });
      });
    },
    deleteSelection(id) {
      let idx = this.deletedSelections.indexOf(id);
      if (idx >= 0) {
        this.deletedSelections.splice(idx, 1);
      } else {
        this.deletedSelections.push(id);
      }
    },
    deleteAddedSelection(id) {
      let idx = this.addedSelections.findIndex((s) => s.Id === id);
      this.addedSelections.splice(idx, 1);
      this.addedSelectionTranslations.forEach((l) => {
        l.selections.splice(idx, 1);
      });
    },
    initializeSurvey() {
      this.selections = [];

      // Survey details
      this.selectedLanguage = this.survey.Languages.find((l) => !l.IsDefault);
      this.surveyDetails.name = this.survey.Name;
      this.surveyDetails.class = 1;
      this.surveyDetails.openDate = moment().format("YYYY-MM-DD");
      this.surveyDetails.closeDate = moment().add(3, "M").format("YYYY-MM-DD");
      this.surveyDetails.owner = this.survey.Options.owner;
      this.surveyDetails.ownerDetails = this.survey.Options.ownerDetails;
      this.surveyDetails.contact = this.survey.Options.contact;
      this.surveyDetails.contactDetails = this.survey.Options.contactDetails;
      this.surveyDetails.unit = this.survey.Options.unit;
      this.survey.Languages.filter((l) => !l.IsDefault).forEach((l) => {
        const translation = this.survey.SurveyTranslations.find(
          (t) => t.Code === l.Code
        );
        this.surveyDetailsTranslations.push({
          code: l.Code,
          name: translation?.Name ?? "",
        });
      });

      let sele = {};
      // Survey predefined selections
      this.predefinedQuestions
        .reduce((acc, curr) => acc.concat(...curr.selections), [])
        .forEach((s) => {
          sele[s.Id] = s.selection;
        });

      this.predefinedQuestions.forEach((q) => {
        this.selections = this.selections.concat(q.selections);
      });

      this.survey.Languages.filter((l) => !l.IsDefault).forEach((l) => {
        const translations = Object.assign({}, sele);
        Object.keys(translations).forEach((id) => {
          const translation =
            this.survey.SurveyQuestionSelectionTranslations.find(
              (t) => t.Id === parseInt(id) && t.Code === l.Code
            );
          translations[id] = translation ? translation.Selection : "";
        });

        this.selectionTranslations.push({
          code: l.Code,
          selections: translations,
        });
      });

      // Survey text questions
      const textQuestionsArray = this.survey.Pages.map((p) =>
        p.QuestionGroups.map((g) =>
          g.Questions.filter(
            (q) =>
              (q.QuestionTypeId === 1 || q.QuestionTypeId === 2) &&
              !q.Predefined
          ).map((q) => ({
            Id: q.Id,
            Question: q.Question,
          }))
        ).filter((g) => g.length > 0)
      ).filter((p) => p.length > 0);
      this.textQuestions = [].concat(...[].concat(...textQuestionsArray));

      this.survey.Languages.filter((l) => !l.IsDefault).forEach((l) => {
        this.textQuestionTranslations.push({
          code: l.Code,
          textQuestions: [].concat(
            this.textQuestions.map((q) => {
              return {
                Id: q.Id,
                Question:
                  this.survey.SurveyQuestionTranslations.find(
                    (t) => t.Id === q.Id && t.Code === l.Code
                  )?.Question || "",
              };
            })
          ),
        });
        this.newTextQuestionTranslations.push({
          code: l.Code,
          textQuestions: [],
        });
        this.addedSelectionTranslations.push({
          code: l.Code,
          selections: [],
        });
      });
    },
    validateSurvey() {
      // Mittauksen hallinta
      this.$refs["survey-details"].$v.$touch();
      let surveyInvalid = this.$refs["survey-details"].$v.$invalid;
      let surveyPredefinedQuestionsValid =
        this.$refs["survey-predefined"].isValid;
      let surveyTextQuestionsValid = this.$refs["survey-text"].isValid;

      if (
        surveyInvalid ||
        !surveyPredefinedQuestionsValid ||
        !surveyTextQuestionsValid
      ) {
        this.step = 2;
        this.snackbarText = "Tarkasta virheet";
        this.snackbar = true;
        return false;
      }
      // Käännökset

      let surveyTranslationsValid = this.surveyTranslationLanguages.every(
        (l) => {
          // Survey
          let detailsValid = !!this.surveyDetailsTranslations
            .find((t) => t.code === l.Code)
            .name.trim();

          // Predefined Selections
          let predefinedTranslations = this.selectionTranslations.find(
            (t) => t.code === l.Code
          );
          let predefinedValid = Object.keys(
            predefinedTranslations.selections
          ).every((t) => !!predefinedTranslations.selections[t].trim());

          // TextQuestions
          let textTranslations = this.textQuestionTranslations.find(
            (t) => t.code === l.Code
          );
          let textValid = textTranslations.textQuestions.every(
            (t) => !!t.Question.trim()
          );

          // newTextQuestions
          let newTextTranslations = this.newTextQuestionTranslations.find(
            (t) => t.code === l.Code
          );
          let newTextValid = newTextTranslations.textQuestions.every(
            (t) => !!t.Question.trim()
          );

          // newAddedSelections
          let newAddedSelectionTranslations =
            this.addedSelectionTranslations.find((t) => t.code === l.Code);
          let newAddedSelectionValid =
            newAddedSelectionTranslations.selections.every(
              (t) => !!t.Selection.trim()
            );

          let valid =
            detailsValid &&
            predefinedValid &&
            textValid &&
            newTextValid &&
            newAddedSelectionValid;

          if (!valid) {
            this.snackbarText = "Tarkasta virheet";
            this.snackbar = true;
            this.selectedLanguage = l;
            this.step = 3;
          }

          return valid;
        }
      );

      return surveyTranslationsValid;
    },
    async saveSurvey() {
      if (!this.validateSurvey()) {
        return;
      }

      const guid = await this.postExternalSurvey(this.getSurveyModel());

      if (!guid) {
        this.snackbarText = "Tapahtui virhe";
        this.snackbar = true;
        return;
      }

      this.snackbarText = "Mittaus tallennettu";
      this.snackbar = true;
      setTimeout(() => {
        this.$router.push({
          name: "surveys",
          params: { guid: guid },
        });
      }, 2000);
    },
    getSurveyModel() {
      var textQuestions = this.textQuestions
        .filter((q) => !this.deletedTextQuestions.includes(q.Id))
        .map((q) => ({ id: q.Id, question: q.Question }));

      const surveyTranslations = this.surveyDetailsTranslations.map((t) => {
        const obj = Object.assign({}, t);
        const languageId = this.survey.Languages.find(
          (l) => l.Code === t.code
        ).Id;
        delete obj.code;
        obj.languageId = languageId;
        return obj;
      });

      const surveyQuestionTranslations = []
        .concat(...this.textQuestionTranslations)
        .concat(...this.newTextQuestionTranslations)
        .map((qt) => {
          const languageId = this.survey.Languages.find(
            (l) => l.Code === qt.code
          ).Id;
          let translations = [].concat(
            ...qt.textQuestions.filter(
              (q) => !this.deletedTextQuestions.includes(q.Id)
            )
          );
          translations.forEach((t) => {
            t.languageId = languageId;
          });
          return translations;
        })
        .reduce((acc, curr) => {
          return curr.concat(acc);
        }, []);

      const questionSelectionTranslations = this.selectionTranslations
        .map((qt) => {
          const languageId = this.survey.Languages.find(
            (l) => l.Code === qt.code
          ).Id;
          let translations = Object.keys(qt.selections).map((id) => ({
            id: parseInt(id),
            selection: qt.selections[id],
            languageId: languageId,
          }));
          return translations;
        })
        .reduce((acc, curr) => {
          return curr.concat(acc);
        }, []);

      let predefined = this.selections
        .filter((s) => !this.deletedSelections.includes(s.Id))
        .reduce((acc, curr) => {
          acc[curr.Id] = curr.selection;
          return acc;
        }, {});

      let qq = questionSelectionTranslations.filter(
        (s) => !this.deletedSelections.includes(s.id)
      );

      if (this.addedSelections.length) {
        this.addedSelections.forEach((s) => (predefined[s.Id] = s.selection));

        this.addedSelectionTranslations.forEach((t) => {
          let languageId = this.survey.Languages.find(
            (l) => l.Code === t.code
          ).Id;

          t.selections.forEach((s) =>
            qq.push({
              id: s.Id,
              selection: s.Selection,
              languageId: languageId,
            })
          );
        });
      }

      return {
        surveyGuid: this.survey.Guid,
        name: this.surveyDetails.name,
        openDate: this.surveyDetails.openDate
          ? moment(this.surveyDetails.openDate).toJSON()
          : undefined,
        closeDate: this.surveyDetails.closeDate
          ? moment(this.surveyDetails.closeDate).toJSON()
          : undefined,
        owner: this.surveyDetails.owner,
        ownerDetails: this.surveyDetails.ownerDetails,
        contact: this.surveyDetails.contact,
        contactDetails: this.surveyDetails.contactDetails,
        unit: this.surveyDetails.unit,
        class: this.surveyDetails.class,
        reference: this.surveyDetails.reference,
        surveyTranslations: surveyTranslations,
        surveyQuestionTranslations: surveyQuestionTranslations,
        questionSelectionTranslations: qq,
        predefined: predefined,
        textQuestions: textQuestions.concat(this.newTextQuestions),
      };
    },
    forwardClick() {
      this.step += 1;
      this.scrollTop();
    },
    backClick() {
      this.step -= 1;
      this.scrollTop();
    },
  },
  created() {
    this.getSurveys();
  },
  destroyed() {
    this.selectedSurvey = undefined;
    this.$store.dispatch("survey/clearSurvey");
  },
};
</script>
