var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("Taustatietojen hallinta")]),_c('v-tabs',{attrs:{"color":"basil","slider-size":"0","active-class":"custom-v-tabs-active-tab"}},_vm._l((_vm.editableQuestions),function(question,qIdx){return _c('v-tab',{key:question.id,on:{"click":function($event){_vm.tab = qIdx + 1}}},[_vm._v(_vm._s(question.question))])}),1),_c('div',{staticClass:"custom-v-tabs-slider"}),_vm._l((_vm.editableQuestions),function(question,qIdx){return _c('div',{key:question.id},[(_vm.tab === qIdx + 1 && _vm.newSelections[question.id])?_c('div',[(!question.questionFor360)?_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('form',{staticClass:"d-flex justify-space-around align-center",on:{"submit":function($event){$event.preventDefault();return _vm.addSelection(question)}}},[_c('v-text-field',{staticClass:"mr-3",attrs:{"disabled":_vm.surveyLoading || _vm.voterLoading,"placeholder":question.question},model:{value:(_vm.newSelections[question.id].selection),callback:function ($$v) {_vm.$set(_vm.newSelections[question.id], "selection", $$v)},expression:"newSelections[question.id].selection"}}),_c('v-btn',{attrs:{"type":"submit","color":"success","disabled":_vm.surveyLoading || _vm.voterLoading,"loading":_vm.surveyLoading || _vm.voterLoading}},[_vm._v("Lisää")])],1)])],1):_vm._e(),_c('div',[_c('v-data-table',{attrs:{"headers":[
            { text: question.question, value: 'selection' },
            { text: '', value: 'actions' },
          ],"items":question.selections},scopedSlots:_vm._u([{key:`item.selection`,fn:function({ item }){return [_c('v-edit-dialog',{attrs:{"large":"","cancel-text":"Peruuta","save-text":"OK","persistent":""},on:{"save":function($event){_vm.editSelection(
                  Object.assign({}, item, {
                    questionId: question.id,
                    question: question.question,
                    questionFor360: question.questionFor360,
                  })
                )},"open":function($event){return _vm.setEditing(item)},"close":_vm.clearEditing,"cancel":_vm.clearEditing},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{staticClass:"pt-5",attrs:{"label":question.question,"rules":[_vm.rules.required],"autofocus":""},model:{value:(_vm.editing),callback:function ($$v) {_vm.editing=$$v},expression:"editing"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.selection)+" ")])]}},{key:`item.actions`,fn:function({ item }){return [_c('div',{staticClass:"pa-0 d-flex justify-end"},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteSelection(item)}}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)})],1)],1):_vm._e()])}),_c('v-snackbar',{model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.snackbarText))])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }