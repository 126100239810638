<template>
  <v-dialog v-model="showDialog" scrollable persistent width="720">
    <v-card>
      <v-card-title>Lisää vastaajia</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <v-row>
          <v-col>
            <v-textarea
              outlined
              hide-details
              label="Lisää vastaajia, yksi per rivi."
              v-model="voterData"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-sm-flex justify-sm-space-between">
            <v-btn @click="parseInput" color="info" class="mobile-btn"
              >Lue tekstikenttä</v-btn
            >

            <ExcelExport :guid="survey.Guid" />
            <ExcelImport :guid="survey.Guid" @uploaded="finishUpload" />
          </v-col>
        </v-row>

        <v-row v-if="rows.length > 0">
          <v-data-table
            :search="search"
            v-model="selectedRows"
            :headers="headers"
            :items="rows"
            show-select
            disable-pagination
            class="elevation-0 col"
          >
            <template v-slot:[`item.email`]="{ item }">
              <v-edit-dialog
                large
                persistent
                cancel-text="Peruuta"
                save-text="OK"
                @open="editText = item.email"
                @save="saveEmail(item)"
              >
                {{ item.email }}
                <template v-slot:input>
                  <v-text-field
                    label="Sähköposti"
                    v-model="editText"
                    class="pt-5"
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template
              v-for="slot in selectValues"
              v-slot:[getSlotKey(slot)]="{ item }"
            >
              <v-edit-dialog
                :key="slot.objectKey"
                large
                persistent
                cancel-text="Peruuta"
                save-text="OK"
                @open="editText = getObjectValue(item, slot.objectKey)"
                @save="saveSelect(item, slot.objectKey)"
              >
                {{ getObjectValue(item, slot.objectKey) }}
                <template v-slot:input>
                  <v-select
                    v-if="slot.locked"
                    :items="slot.values"
                    v-model="editText"
                    item-text="value"
                    item-value="key"
                    class="pt-5"
                  ></v-select>

                  <v-combobox
                    v-else
                    :items="slot.selections"
                    v-model="editText"
                    @update:search-input="updateCombo"
                    item-text="value"
                    item-value="value"
                    class="pt-5"
                  ></v-combobox>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small @click="deleteRow(item)"> mdi-delete </v-icon>
            </template>
          </v-data-table>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-5">
        <v-spacer />
        <v-btn color="success" @click="save" v-if="selectedRows.length > 0">
          Tuo valitut</v-btn
        >
        <v-btn color="error" @click.stop="show = false">Sulje</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

import ExcelImport from "@/components/Voters/VoterExcelImportBtn";
import ExcelExport from "@/components/Voters/VoterExcelExportBtn";

export default {
  components: { ExcelImport, ExcelExport },
  computed: {
    show: {
      get() {
        return this.showDialog;
      },
      set(value) {
        if (!value) {
          this.rows = [];
          this.selectedRows = [];
          this.voterData = "";
          this.$emit("close");
        }
      },
    },
    headers: function () {
      let arr = [
        {
          text: "Sähköposti",
          value: "email",
        },
        {
          text: "Kieli",
          value: "languageCode",
        },
      ];

      arr = arr.concat(
        this.predefinedQuestions.map((q) => {
          return {
            text: q.question,
            value: `answers[${q.id}]`,
          };
        })
      );

      arr = arr.concat({
        text: "",
        value: "actions",
        sortable: false,
      });

      return arr;
    },
  },
  data: () => ({
    voterData: "",
    search: "",
    editText: "",
    rows: [],
    selectData: {},
    selectValues: [],
    selectedRows: [],
    index: 0,
  }),
  methods: {
    ...mapActions({
      getVoters: "voter/getVoters",
      postVoters: "voter/postVoters",
    }),
    updateCombo(s) {
      this.editText = s;
    },
    async finishUpload() {
      await this.getVoters();
      this.show = false;
    },
    getSlotKey(slot) {
      return `item.${slot.objectKey}`;
    },
    getObjectValue(item, key) {
      let nested = key.match(/\[(.*)\]/);

      if (nested && nested.length > 0 && nested.index > 0) {
        var propertyKey = key.substring(0, nested.index);
        var nestedKey = nested[1];

        return item[propertyKey][nestedKey];
      }

      return item[key];
    },
    parseInput() {
      if (this.voterData) {
        var split = this.voterData.split(/\r?\n/);

        if (split && split.length > 0) {
          split.forEach((r) => {
            let first = r.split(" ")[0];
            let email = this.cleanEmail(first);

            if (email && email.length > 0) {
              let voter = {
                id: this.index++,
                email: email,
                languageCode: this.survey.DefaultLanguage,
                answers: {},
              };

              this.predefinedQuestions.map((q) => {
                let key = `${q.id}`;

                voter.answers[key] = "";
              });
              this.rows.push(voter);
              this.selectedRows.push(voter);
            }
          });

          this.voterData = "";
        }
      }
    },

    saveEmail(data) {
      if (data && this.editText) {
        let email = this.cleanEmail(this.editText);

        if (email && email.length > 0) {
          data.email = email;
        }

        this.editText = "";
      }
    },

    saveSelect(data, key) {
      if (data && this.editText) {
        let value = this.editText.trim();

        let selectList = this.selectValues.find((s) => s.objectKey == key);

        if (selectList && selectList.locked == false && value.length > 0) {
          let old = selectList.selections.find((s) => s == value);

          if (!old) {
            selectList.selections.push(value);
          }
        }

        let nested = key.match(/\[(.*)\]/);

        if (nested && nested.length > 0 && nested.index > 0) {
          var propertyKey = key.substring(0, nested.index);
          var nestedKey = nested[1];

          data[propertyKey][nestedKey] = value;
        } else {
          data[key] = value;
        }
        this.editText = "";
      }
    },

    deleteRow(item) {
      let index = this.rows.findIndex((r) => r.id == item.id);
      let selectedIndex = this.selectedRows.findIndex((r) => r.id == item.id);

      if (index >= 0 && confirm("Oletko varma?")) {
        this.rows.splice(index, 1);

        if (selectedIndex >= 0) {
          this.selectedRows.splice(selectedIndex, 1);
        }
      }
    },

    async save() {
      let voters = this.selectedRows.map((r) => {
        let voter = {
          voterEmail: r.email,
          languageCode: r.languageCode,
          answers: Object.keys(r.answers).reduce((acc, curr) => {
            if (r.answers[curr]) {
              acc[curr] = r.answers[curr];
            }
            return acc;
          }, {}),
        };

        return voter;
      });

      if (voters.length > 0) {
        await this.postVoters({
          surveyGuid: this.survey.Guid,
          voters: voters,
        });

        this.getVoters(this.survey.Guid);
        this.show = false;
      }
    },
    setSelectValues() {
      let values = this.survey.Languages.map((l) => {
        return {
          key: l.Code,
          value: l.Language,
        };
      });

      this.selectValues.push({
        objectKey: "languageCode",
        locked: true,
        values: values,
        selections: [],
      });

      let questions = this.predefinedQuestions.map((q) => {
        let locked =
          this.survey?.Options?.Question360Role === q.id ||
          this.survey?.Options?.Question360Person === q.id;

        let selections = q.selections.filter((s) => s.selection.length > 0);

        return {
          objectKey: `answers[${q.id}]`,
          locked: locked,
          values: selections.map((s) => {
            return {
              key: s.selection,
              value: s.selection,
            };
          }),
          selections: selections.map((s) => {
            return s.selection;
          }),
        };
      });

      this.selectValues = this.selectValues.concat(questions);
    },
  },
  created() {
    this.setSelectValues();
  },
  watch: {
    survey: function () {
      this.setSelectValues();
    },
  },
  props: {
    survey: Object,
    predefinedQuestions: Array,
    showDialog: Boolean,
  },
};
</script>
