import axios from "axios";

export default {
  namespaced: true,

  state: {
    voters: null,
    survey: null,
    report: [],
    overview: null,
    compareOverview: null,
    overviewWithFilter: null,
    compareOverviewWithFilter: null,
    surveyLoading: false,
    voterLoading: false,
    reportLoading: false,
    overviewLoading: false,
    overviewWithFilterLoading: false,
  },

  getters: {
    voters(state) {
      return state.voters;
    },
    survey(state) {
      return state.survey;
    },
    report(state) {
      return state.report;
    },
    overview(state) {
      return state.overview;
    },
    compareOverview(state) {
      return state.compareOverview;
    },
    overviewWithFilter(state) {
      return state.overviewWithFilter;
    },
    compareOverviewWithFilter(state) {
      return state.compareOverviewWithFilter;
    },
    surveyLoading(state) {
      return state.surveyLoading;
    },
    voterLoading(state) {
      return state.voterLoading;
    },
    reportLoading(state) {
      return state.reportLoading;
    },
    overviewLoading(state) {
      return state.overviewLoading;
    },
    overviewWithFilterLoading(state) {
      return state.overviewWithFilterLoading;
    },
  },

  mutations: {
    SET_SURVEY(state, survey) {
      state.survey = survey;
    },
    SET_VOTERS(state, voters) {
      state.voters = voters;
    },
    SET_REPORT(state, report) {
      state.report = report;
    },
    SET_OVERVIEW(state, overview) {
      state.overview = overview;
    },
    SET_COMPARE_OVERVIEW(state, overview) {
      state.compareOverview = overview;
    },
    SET_OVERVIEW_WITH_FILTER(state, overviewWithFilter) {
      state.overviewWithFilter = overviewWithFilter;
    },
    SET_COMPARE_OVERVIEW_WITH_FILTER(state, overviewWithFilter) {
      state.compareOverviewWithFilter = overviewWithFilter;
    },
    SET_SURVEY_LOADING(state, isLoading) {
      state.surveyLoading = isLoading;
    },
    SET_VOTER_LOADING(state, isLoading) {
      state.voterLoading = isLoading;
    },
    SET_REPORT_LOADING(state, isLoading) {
      state.reportLoading = isLoading;
    },
    SET_OVERVIEW_LOADING(state, isLoading) {
      state.overviewLoading = isLoading;
    },
    SET_OVERVIEW_WITH_FILTER_LOADING(state, isLoading) {
      state.overviewWithFilterLoading = isLoading;
    },
  },

  actions: {
    async getSurvey({ commit }, guid) {
      try {
        commit("SET_SURVEY_LOADING", true);
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/anonymous/survey/${guid}`
        );
        commit("SET_SURVEY_LOADING", false);
        commit("SET_SURVEY", response.data);
      } catch (e) {
        commit("SET_SURVEY_LOADING", false);
        return false;
      }
    },
    async getSurveyByReportToken({ commit }, guid) {
      try {
        commit("SET_SURVEY_LOADING", true);
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/anonymous/report/${guid}`
        );
        commit("SET_SURVEY_LOADING", false);
        commit("SET_SURVEY", response.data);
      } catch (e) {
        commit("SET_SURVEY_LOADING", false);
        return false;
      }
    },
    async getVoters({ commit }, guid) {
      try {
        commit("SET_VOTER_LOADING", true);
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/anonymous/voter/${guid}`
        );
        commit("SET_VOTER_LOADING", false);
        commit("SET_VOTERS", response.data);
        return true;
      } catch (e) {
        commit("SET_VOTER_LOADING", false);
        return false;
      }
    },
    async getReportOverview({ commit }, { guid, compare = false }) {
      try {
        commit("SET_OVERVIEW_LOADING", true);
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/anonymous/overview/${guid}?compare=${compare}`
        );
        commit("SET_OVERVIEW_LOADING", false);
        commit("SET_OVERVIEW", response.data);
      } catch (e) {
        commit("SET_OVERVIEW_LOADING", false);
        return false;
      }
    },
    async getReportOverviewByReportToken({ commit }, guid) {
      try {
        commit("SET_OVERVIEW_LOADING", true);
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/anonymous/report/overview/${guid}`
        );
        commit("SET_OVERVIEW_LOADING", false);
        commit("SET_OVERVIEW", response.data);
      } catch (e) {
        commit("SET_OVERVIEW_LOADING", false);
        return false;
      }
    },
    async getReportOverviewWithFilter({ commit }, { guid, compare = false }) {
      try {
        commit("SET_OVERVIEW_WITH_FILTER_LOADING", true);
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/anonymous/overviewwithfilter/${guid}?compare=${compare}`
        );
        commit("SET_OVERVIEW_WITH_FILTER_LOADING", false);
        compare
          ? commit("SET_COMPARE_OVERVIEW_WITH_FILTER", response.data)
          : commit("SET_OVERVIEW_WITH_FILTER", response.data);
      } catch (e) {
        commit("SET_OVERVIEW_WITH_FILTER_LOADING", false);
        return false;
      }
    },
    async getReportOverviewWithFilterByReportToken(
      { commit },
      { guid, questionId, selectionId }
    ) {
      try {
        commit("SET_REPORT_LOADING", true);
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/anonymous/report/overviewwithfilter/${guid}/${questionId}/${selectionId}`
        );
        commit("SET_REPORT_LOADING", false);
        commit("SET_OVERVIEW_WITH_FILTER", response.data);
      } catch (e) {
        commit("SET_REPORT_LOADING", false);
        return false;
      }
    },
    async getCompareReportOverviewByReportToken({ commit }, guid) {
      try {
        commit("SET_REPORT_LOADING", true);
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/anonymous/report/compare/${guid}`
        );
        commit("SET_COMPARE_OVERVIEW", response.data);
        commit("SET_REPORT_LOADING", false);
      } catch (e) {
        commit("SET_REPORT_LOADING", false);
        return false;
      }
    },
    async getCompareReportOverviewWithFilterByReportToken(
      { commit },
      { guid, questionId, selectionId }
    ) {
      try {
        commit("SET_REPORT_LOADING", true);
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/anonymous/report/comparewithfilter/${guid}/${questionId}/${selectionId}`
        );
        commit("SET_REPORT_LOADING", false);
        commit("SET_COMPARE_OVERVIEW_WITH_FILTER", response.data);
      } catch (e) {
        commit("SET_REPORT_LOADING", false);
        return false;
      }
    },
    async getWebReport({ commit }, { guid, reportId }) {
      commit("SET_REPORT_LOADING", true);
      try {
        let response = await axios.post(
          `${process.env.VUE_APP_API_URL}/anonymous/webreport/${guid}/${reportId}`
        );
        commit("SET_REPORT_LOADING", false);
        commit("SET_REPORT", response.data);
      } catch (e) {
        commit("SET_REPORT_LOADING", false);
        return false;
      }
    },
    async getWebReportByReportToken(
      { commit },
      { guid, reportId, questionId, selectionId }
    ) {
      commit("SET_REPORT_LOADING", true);
      try {
        let response = await axios.post(
          `${process.env.VUE_APP_API_URL}/anonymous/report/webreport/${guid}/${reportId}`,
          { Id: questionId, Selected: [selectionId] }
        );
        commit("SET_REPORT_LOADING", false);
        commit("SET_REPORT", response.data);
      } catch (e) {
        commit("SET_REPORT_LOADING", false);
        return false;
      }
    },
    async allVotersAdded({ commit }, voter) {
      try {
        commit("SET_VOTER_LOADING", true);
        await axios.put(
          `${process.env.VUE_APP_API_URL}/anonymous/questionSelectionEmail/${voter.guid}`,
          voter
        );
        commit("SET_VOTER_LOADING", false);
        return true;
      } catch (e) {
        commit("SET_VOTER_LOADING", false);
        return false;
      }
    },
    async postVoters({ commit }, voters) {
      try {
        commit("SET_VOTER_LOADING", true);
        await axios.post(
          `${process.env.VUE_APP_API_URL}/anonymous/voter`,
          voters
        );
        commit("SET_VOTER_LOADING", false);
        return true;
      } catch (e) {
        commit("SET_VOTER_LOADING", false);
        return false;
      }
    },
    async putVoter({ commit }, voter) {
      try {
        commit("SET_VOTER_LOADING", true);
        await axios.put(
          `${process.env.VUE_APP_API_URL}/anonymous/voter/${voter.VoterCode}`,
          voter
        );
        commit("SET_VOTER_LOADING", false);
        return true;
      } catch (e) {
        commit("SET_VOTER_LOADING", false);
        return false;
      }
    },
    async deleteVoter({ commit }, guid) {
      try {
        commit("SET_VOTER_LOADING", true);
        await axios.delete(
          `${process.env.VUE_APP_API_URL}/anonymous/voter/${guid}`
        );
        commit("SET_VOTER_LOADING", false);
        return true;
      } catch (e) {
        commit("SET_VOTER_LOADING", false);
        return false;
      }
    },
    clearOverview({ commit }) {
      commit("SET_OVERVIEW", null);
    },
    clearCompareOverview({ commit }) {
      commit("SET_COMPARE_OVERVIEW", null);
    },
    clearOverviewWithFilter({ commit }) {
      commit("SET_OVERVIEW_WITH_FILTER", null);
    },
    clearCompareOverviewWithFilter({ commit }) {
      commit("SET_COMPARE_OVERVIEW_WITH_FILTER", null);
    },
    clearReport({ commit }) {
      commit("SET_REPORT", []);
    },
  },
};
