<template>
  <BaseChartComponent
    :type="type"
    :width="width"
    :height="height"
    :dataFormat="dataFormat"
    :dataSource="dataSource"
  />
</template>

<script>
import BaseChartComponent from "@/components/Report/Charts/BaseChartComponent";

export default {
  components: {
    BaseChartComponent,
  },
  computed: {
    chartData: function () {
      return this.useChart2 ? this.reportItem.Chart2 : this.reportItem.Chart1;
    },
    dataSource: function () {
      return {
        chart: {
          caption: "",
          xaxisname: "",
          yaxisname: "",
          theme: "fusion",
          yAxisMaxValue: this.chartData.Series[0]?.MaxValue,
          yAxisMinValue: this.chartData.Series[0]?.MinValue,
        },
        data: this.chartData.Series[0].Values.map((v) => ({
          label: v.Label,
          value: v.Value,
          color: this.$config?.report?.chartColorBlue || "#2196F3",
        })),
      };
    },
  },
  data: () => ({
    dataFormat: "json",
    type: "column2d",
  }),
  methods: {},
  props: {
    width: { type: String, required: true },
    height: { type: String, required: true },
    reportItem: { type: Object, required: true },
    useChart2: Boolean,
  },
};
</script>

<style></style>
