<template>
  <v-simple-table>
    <template>
      <thead v-if="table.Header">
        <tr>
          <td v-for="(cell, idx) in table.Header.Cells" :key="idx">
            <span class="font-weight-bold">{{ cell.Content[0].Text }}</span>
          </td>
        </tr>
      </thead>
      <tbody v-if="table.Rows">
        <tr v-for="(row, rIdx) in table.Rows" :key="rIdx">
          <td v-for="(cell, cIdx) in row.Cells" :key="cIdx">
            <span :class="cell.Content[0].Bold ? 'font-weight-bold' : ''">{{
              cell.Content[0].Text
            }}</span>
          </td>
        </tr>
      </tbody>
      <tfoot v-if="table.Footer">
        <tr>
          <td v-for="(cell, idx) in table.Footer.Cells" :key="idx">
            <span class="font-weight-bold">{{ cell.Content[0].Text }}</span>
          </td>
        </tr>
      </tfoot>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    table: { type: Object, required: true },
  },
};
</script>

<style>
</style>