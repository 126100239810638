<template>
  <BaseChartComponent
    :type="type"
    :width="width"
    :height="height"
    :dataFormat="dataFormat"
    :dataSource="dataSource"
  />
</template>

<script>
import BaseChartComponent from "@/components/Report/Charts/BaseChartComponent";

export default {
  components: {
    BaseChartComponent,
  },
  computed: {
    dataSource: function () {
      return {
        chart: {
          caption: "",
          theme: "fusion",
        },
        data: this.reportItem.Chart1.Series[0].Values.map((v) => ({
          label: v.Label,
          value: v.Value,
        })),
      };
    },
  },
  data: () => ({
    dataFormat: "json",
    type: "pie2d",
  }),
  methods: {},
  props: {
    width: { type: String, required: true },
    height: { type: String, required: true },
    reportItem: { type: Object, required: true },
  },
};
</script>

<style>
</style>