<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col md="10" lg="8">
        <v-card class="elevation-12 main-content">
          <v-card-title>
            <span v-if="survey">Toista mittaus {{ survey.Name }}</span>
          </v-card-title>
          <v-card-text>
            <LoadingIndicator :isLoading="isLoading" />

            <div v-if="survey">
              <v-tabs
                color="basil"
                active-class="custom-v-tabs-active-tab"
                slider-size="0"
              >
                <v-tab @click="tab = 1">Mittaus</v-tab>
                <v-tab v-if="canTranslate" @click="tab = 2">Käännökset</v-tab>
              </v-tabs>
              <div class="custom-v-tabs-slider"></div>
              <div v-show="tab === 1" class="pt-4">
                <EditSurveyDetails
                  ref="survey-details"
                  :surveyDetails="surveyDetails"
                />
              </div>
              <div v-show="tab === 2 && canTranslate" class="pt-4">
                <LanguageSelector
                  :languages="surveyTranslationLanguages"
                  :selectedLanguage.sync="selectedLanguage"
                />
                <NewTranslations
                  v-if="selectedLanguage"
                  :language="selectedLanguage"
                  :surveyDetails="surveyDetails"
                  :surveyDetailsTranslations="surveyDetailsTranslations"
                  :predefinedQuestions="predefinedQuestions"
                  :deletedTextQuestions="deletedTextQuestions"
                  :deletedSelections="deletedSelections"
                  :newTextQuestions="newTextQuestions"
                  :newTextQuestionTranslations="newTextQuestionTranslations"
                  :textQuestions="textQuestions"
                  :textQuestionTranslations="textQuestionTranslations"
                  :selections="selections"
                  :selectionTranslations="selectionTranslations"
                  :addedSelections="addedSelections"
                  :addedSelectionTranslations="addedSelectionTranslations"
                  :onlySurveyDetails="true"
                />
              </div>
            </div>
          </v-card-text>
          <!-- TODO: NÄISTÄ SLOT ? -->
          <v-card-actions>
            <v-btn
              v-if="survey"
              color="secondary"
              :to="{
                name: 'surveys',
                params: { guid: this.survey.Guid },
              }"
              >Takaisin</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              v-if="survey"
              :loading="isLoading"
              :disabled="isLoading"
              color="success"
              @click="saveSurvey"
              >Tallenna</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">{{ snackbarText }}</v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import EditSurveyDetails from "@/components/Survey/EditSurveyDetails";
import LoadingIndicator from "@/components/LoadingIndicator";
import LanguageSelector from "@/components/LanguageSelector";
import NewTranslations from "@/components/Survey/NewTranslations";

export default {
  mixins: [validationMixin],
  name: "edit-survey",
  components: {
    EditSurveyDetails,
    LoadingIndicator,
    LanguageSelector,
    NewTranslations,
  },
  computed: {
    ...mapGetters({
      survey: "survey/survey",
      isLoading: "survey/isLoading",
      user: "auth/user",
    }),
    canTranslate() {
      return (
        this.survey && this.survey.Languages && this.survey.Languages.length > 1
      );
    },
    predefinedQuestions: function () {
      if (!this.survey) {
        return [];
      }

      var s = this.survey.Pages.map((p) =>
        p.QuestionGroups.map((g) =>
          g.Questions.filter((q) => q.Predefined && q.CanEdit).map((q) => ({
            Id: q.Id,
            question: q.Question,
            isChoice: q.IsChoice,
            selections: q.Selections.map((s) => ({
              Id: s.Id,
              selection: s.Selection,
              originalSelection: s.Selection,
            })),
          }))
        ).filter((g) => g.length > 0)
      ).filter((p) => p.length > 0);

      return [].concat(...[].concat(...s));
    },
    surveyTranslationLanguages: function () {
      return this.survey.Languages.filter((l) => !l.IsDefault);
    },
  },
  data: () => ({
    selectedLanguage: undefined,
    surveyDetails: {
      class: undefined,
      closeDate: undefined,
      contact: undefined,
      contactDetails: undefined,
      name: "",
      openDate: undefined,
      owner: undefined,
      ownerDetails: undefined,
      reference: undefined,
      smsEnabled: false,
      unit: undefined,
      oldCombineValue: undefined,
      newCombineValue: undefined,
    },
    surveyDetailsTranslations: [],
    newTextQuestions: [],
    newTextQuestionTranslations: [],
    textQuestions: [],
    deletedTextQuestions: [],
    deletedSelections: [],
    addedSelections: [],
    addedSelectionTranslations: [],
    textQuestionTranslations: [],
    selections: [],
    selectionTranslations: [],
    snackbar: false,
    snackbarText: "",
    tab: 1,
  }),
  methods: {
    ...mapActions({
      getExternalSurvey: "survey/getExternalSurvey",
      postExternalSurvey: "survey/postExternalSurvey",
    }),
    initializeSurvey() {
      this.selections = [];

      // Survey details
      this.selectedLanguage = this.survey.Languages.find((l) => !l.IsDefault);
      this.surveyDetails.name = this.survey.Name;
      this.surveyDetails.openDate = moment().format("YYYY-MM-DD");
      this.surveyDetails.closeDate = moment().add(3, "M").format("YYYY-MM-DD");
      this.surveyDetails.owner = this.survey.Options.Owner;
      this.surveyDetails.ownerDetails = this.survey.Options.OwnerDetails;
      this.surveyDetails.contact = this.survey.Options.Contact;
      this.surveyDetails.contactDetails = this.survey.Options.ContactDetails;
      this.surveyDetails.unit = this.survey.Options.Unit;
      this.surveyDetails.class = this.survey.SurveyClassId ?? 1;
      this.surveyDetails.reference = this.survey.Options.Reference;
      this.surveyDetails.smsEnabled = this.survey.Options.SmsEnabled;
      this.survey.Languages.filter((l) => !l.IsDefault).forEach((l) => {
        const translation = this.survey.SurveyTranslations.find(
          (t) => t.Code === l.Code
        );
        this.surveyDetailsTranslations.push({
          code: l.Code,
          name: translation?.Name ?? "",
        });
      });

      this.surveyDetails.oldCombineValue = moment(
        this.survey.CreateDate
      ).format("YYYY-MM-DD");
      this.surveyDetails.newCombineValue = moment().format("YYYY-MM-DD");

      let sele = {};
      // Survey predefined selections
      this.predefinedQuestions
        .reduce((acc, curr) => acc.concat(...curr.selections), [])
        .forEach((s) => {
          sele[s.Id] = s.selection;
        });

      this.predefinedQuestions.forEach((q) => {
        this.selections = this.selections.concat(q.selections);
      });

      this.survey.Languages.filter((l) => !l.IsDefault).forEach((l) => {
        const translations = Object.assign({}, sele);
        Object.keys(translations).forEach((id) => {
          const translation =
            this.survey.SurveyQuestionSelectionTranslations.find(
              (t) => t.Id === parseInt(id) && t.Code === l.Code
            );
          translations[id] = translation ? translation.Selection : "";
        });

        this.selectionTranslations.push({
          code: l.Code,
          selections: translations,
        });
      });

      // Survey text questions
      const textQuestionsArray = this.survey.Pages.map((p) =>
        p.QuestionGroups.map((g) =>
          g.Questions.filter(
            (q) =>
              (q.QuestionTypeId === 1 || q.QuestionTypeId === 2) &&
              !q.Predefined
          ).map((q) => ({
            Id: q.Id,
            Question: q.Question,
          }))
        ).filter((g) => g.length > 0)
      ).filter((p) => p.length > 0);
      this.textQuestions = [].concat(...[].concat(...textQuestionsArray));

      this.survey.Languages.filter((l) => !l.IsDefault).forEach((l) => {
        this.textQuestionTranslations.push({
          code: l.Code,
          textQuestions: [].concat(
            this.textQuestions.map((q) => {
              return {
                Id: q.Id,
                Question:
                  this.survey.SurveyQuestionTranslations.find(
                    (t) => t.Id === q.Id && t.Code === l.Code
                  )?.Question || "",
              };
            })
          ),
        });
        this.newTextQuestionTranslations.push({
          code: l.Code,
          textQuestions: [],
        });
        this.addedSelectionTranslations.push({
          code: l.Code,
          selections: [],
        });
      });
    },
    async saveSurvey() {
      this.$refs["survey-details"].$v.$touch();
      this.$v.$touch();

      let surveyInvalid =
        this.$refs["survey-details"].$v.$invalid || this.$v.$invalid;

      if (surveyInvalid) {
        this.snackbarText = "Tarkasta mittauksen tiedot";
        this.snackbar = true;
        return;
      }

      const repeatedSurveyGuid = await this.postExternalSurvey(
        this.getSurveyModel()
      );

      this.snackbarText = "Mittaus tallennettu";
      this.snackbar = true;
      setTimeout(() => {
        this.$router.push({
          name: "surveys",
          params: { guid: repeatedSurveyGuid },
        });
      }, 2000);
    },
    getSurveyModel() {
      var textQuestions = this.textQuestions
        .filter((q) => !this.deletedTextQuestions.includes(q.Id))
        .map((q) => ({ id: q.Id, question: q.Question }));

      const surveyTranslations = this.surveyDetailsTranslations.map((t) => {
        const obj = Object.assign({}, t);
        const languageId = this.survey.Languages.find(
          (l) => l.Code === t.code
        ).Id;
        delete obj.code;
        obj.languageId = languageId;
        return obj;
      });

      const surveyQuestionTranslations = []
        .concat(...this.textQuestionTranslations)
        .concat(...this.newTextQuestionTranslations)
        .map((qt) => {
          const languageId = this.survey.Languages.find(
            (l) => l.Code === qt.code
          ).Id;
          let translations = [].concat(
            ...qt.textQuestions.filter(
              (q) => !this.deletedTextQuestions.includes(q.Id)
            )
          );
          translations.forEach((t) => {
            t.languageId = languageId;
          });
          return translations;
        })
        .reduce((acc, curr) => {
          return curr.concat(acc);
        }, []);

      const questionSelectionTranslations = this.selectionTranslations
        .map((qt) => {
          const languageId = this.survey.Languages.find(
            (l) => l.Code === qt.code
          ).Id;
          let translations = Object.keys(qt.selections).map((id) => ({
            id: parseInt(id),
            selection: qt.selections[id],
            languageId: languageId,
          }));
          return translations;
        })
        .reduce((acc, curr) => {
          return curr.concat(acc);
        }, []);

      let predefined = this.selections
        .filter((s) => !this.deletedSelections.includes(s.Id))
        .reduce((acc, curr) => {
          acc[curr.Id] = curr.selection;
          return acc;
        }, {});

      let qq = questionSelectionTranslations.filter(
        (s) => !this.deletedSelections.includes(s.id)
      );

      if (this.addedSelections.length) {
        this.addedSelections.forEach((s) => (predefined[s.Id] = s.selection));

        this.addedSelectionTranslations.forEach((t) => {
          let languageId = this.survey.Languages.find(
            (l) => l.Code === t.code
          ).Id;

          t.selections.forEach((s) =>
            qq.push({
              id: s.Id,
              selection: s.Selection,
              languageId: languageId,
            })
          );
        });
      }

      return {
        surveyGuid: this.survey.Guid,
        name: this.surveyDetails.name,
        openDate: this.surveyDetails.openDate
          ? moment(this.surveyDetails.openDate).toJSON()
          : undefined,
        closeDate: this.surveyDetails.closeDate
          ? moment(this.surveyDetails.closeDate).toJSON()
          : undefined,
        owner: this.surveyDetails.owner,
        ownerDetails: this.surveyDetails.ownerDetails,
        contact: this.surveyDetails.contact,
        contactDetails: this.surveyDetails.contactDetails,
        unit: this.surveyDetails.unit,
        class: this.surveyDetails.class,
        reference: this.surveyDetails.reference,
        surveyTranslations: surveyTranslations,
        surveyQuestionTranslations: surveyQuestionTranslations,
        questionSelectionTranslations: qq,
        predefined: predefined,
        textQuestions: textQuestions.concat(this.newTextQuestions),
        smsEnabled: this.surveyDetails.smsEnabled,
        oldCombineValue: this.surveyDetails.oldCombineValue,
        newCombineValue: this.surveyDetails.newCombineValue,
        repeat: true,
      };
    },
  },
  validations: {
    surveyDetails: {
      oldCombineValue: { required },
      newCombineValue: { required },
    },
  },
  async created() {
    await this.getExternalSurvey(this.$route.params.guid);
    this.initializeSurvey();
  },
  mounted() {
    this.$v.$touch();
  },
  destroyed() {
    this.$store.dispatch("survey/clearSurvey", null);
  },
};
</script>
