<template>
  <ReportGroupBarChart
    :title="$t('voter.report.subAreas')"
    :mainGroup="this.overview.majorGroups"
    :allGroups="
      this.overview.majorGroups
        .concat(this.overview.minorGroups)
        .concat(this.overview.questions)
        .concat(this.overview.drilldownQuestions)
    "
    :showLegend="showLegend"
    @groupClicked="groupClicked"
    :bgColor="bgColor"
  />
</template>

<script>
import ReportGroupBarChart from "@/components/Report/ReportGroupBarChart";

export default {
  components: {
    ReportGroupBarChart,
  },
  computed: {},
  data: () => ({}),
  methods: {
    groupClicked(group) {
      this.$emit("groupClicked", group ? 2 : 0);
    },
  },
  created() {},
  props: {
    title: { type: String },
    overview: { type: Object },
    showLegend: Boolean,
    bgColor: String,
  },
};
</script>

<style></style>
