<template>
  <v-dialog persistent scrollable v-model="dialog" max-width="960px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click="getReport"
        color="info"
        class="mr-3"
        small
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-file-document-outline</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        {{
          question !== undefined && selection !== undefined
            ? `${reportTemplate.Name} - ${question.question} - ${selection.selection}`
            : reportTemplate.Name
        }}
        <v-spacer></v-spacer>
        <v-btn color="black" text @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn></v-card-title
      >

      <v-card-text>
        <LoadingIndicator :isLoading="isLoading" />
        <div v-if="!isLoading">
          <p v-if="!report || report.length === 0">
            {{ $t("voter.report.nodata") }}
          </p>
          <v-row
            v-else
            align="center"
            justify="center"
            v-for="(item, idx) in report"
            :key="idx"
          >
            <v-col>
              <h2 v-if="item.Type === 1">{{ item.Text }}</h2>
              <p v-else-if="item.Type === 2">{{ item.Text }}</p>
              <PieChart
                v-else-if="item.Type === 3 && item.Chart1.Type === 0"
                width="100%"
                height="400"
                :reportItem="item"
              />
              <SimpleColumnChart
                v-else-if="
                  item.Type === 3 &&
                  item.Chart1.Type === 1 &&
                  item.Chart1.Series.length === 1
                "
                width="100%"
                height="400"
                :reportItem="item"
              />
              <MultipleSeriesColumnChart
                v-else-if="
                  item.Type === 3 &&
                  item.Chart1.Type === 1 &&
                  item.Chart1.Series.length > 1
                "
                width="100%"
                height="400"
                :reportItem="item"
              />
              <RadarChart
                v-else-if="item.Type === 3 && item.Chart1.Type === 2"
                width="100%"
                height="400"
                :reportItem="item"
              />
              <div
                v-else-if="
                  item.Type === 3 &&
                  item.Chart1.Type === 3 &&
                  item.Chart1.Series.length === 1
                "
              >
                <v-row
                  ><v-col cols="8"
                    ><SimpleBarChart
                      width="100%"
                      height="400"
                      :reportItem="item"
                  /></v-col>
                  <v-col cols="4"
                    ><SimpleColumnChart
                      width="100%"
                      height="400"
                      :reportItem="item"
                      useChart2 /></v-col
                ></v-row>
              </div>
              <div
                v-else-if="
                  item.Type === 3 &&
                  item.Chart1.Type === 3 &&
                  item.Chart1.Series.length > 1
                "
              >
                <v-row>
                  <v-col cols="8"
                    ><MultipleSeriesBarChart
                      width="100%"
                      height="400"
                      :reportItem="item"
                  /></v-col>
                  <v-col cols="4"
                    ><SimpleColumnChart
                      width="100%"
                      height="400"
                      :reportItem="item"
                      useChart2
                  /></v-col>
                </v-row>
              </div>

              <Table v-else-if="item.Type === 4" :table="item.Table" />

              <h1 v-else>
                {{ getInfo(item) }}
              </h1>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">
          {{ $t("general.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LoadingIndicator from "@/components/LoadingIndicator";
import SimpleColumnChart from "@/components/Report/Charts/SimpleColumnChart";
import MultipleSeriesColumnChart from "@/components/Report/Charts/MultipleSeriesColumnChart";
import RadarChart from "@/components/Report/Charts/RadarChart";
import SimpleBarChart from "@/components/Report/Charts/SimpleBarChart";
import MultipleSeriesBarChart from "@/components/Report/Charts/MultipleSeriesBarChart";
import PieChart from "@/components/Report/Charts/PieChart";
import Table from "@/components/Report/Table";

export default {
  components: {
    LoadingIndicator,
    SimpleColumnChart,
    MultipleSeriesColumnChart,
    RadarChart,
    SimpleBarChart,
    MultipleSeriesBarChart,
    PieChart,
    Table,
  },
  computed: {},
  data: () => ({ dialog: false }),
  methods: {
    getReport() {
      this.$emit(
        "getWebReport",
        this.survey.Guid,
        this.reportTemplate.Id,
        this.question?.id,
        this.selection?.id
      );
    },
    getInfo(item) {
      return `Id: ${item.Id} | Type: ${item.Type} | Chart1.Type: ${
        item?.Chart1?.Type ?? false
      } | Chart2: ${"Chart2" in item && item.Chart2 !== null}`;
    },
  },
  watch: {
    dialog: function (newValue) {
      if (!newValue) {
        this.$store.dispatch("report/clearReport", null);
      }
    },
  },
  props: {
    survey: { type: Object, required: true },
    report: { type: Array, required: true },
    reportTemplate: { type: Object, required: true },
    question: { type: Object, required: false },
    selection: { type: Object, required: false },
    isLoading: { type: Boolean, required: true },
  },
};
</script>

<style></style>
