<template>
  <div>
    <v-row>
      <v-col md="6">
        {{ questionGroup.Title || `Ryhmä ${questionGroupIndex + 1}` }}
      </v-col>
      <v-col md="6">
        {{
          getQuestionGroup(
            questionGroup.Id,
            questionGroup.Title || `Ryhmä ${questionGroupIndex + 1}`
          )
        }}
      </v-col>
    </v-row>
    <Question
      v-for="q in questionGroup.Questions.filter(
        (qq) => !deletedTextQuestions.includes(qq.Id)
      )"
      ref="question"
      :key="q.Id"
      :language="language"
      :question="q"
      :textQuestions="textQuestions"
      :textQuestionTranslations="textQuestionTranslations"
      :selections="selections"
      :selectionTranslations="selectionTranslations"
      :addedSelections="addedSelections"
      :addedSelectionTranslations="addedSelectionTranslations"
      :predefinedQuestions="predefinedQuestions"
      :deletedTextQuestions="deletedTextQuestions"
      :deletedSelections="deletedSelections"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Question from "@/components/Survey/Question";

export default {
  components: {
    Question,
  },
  computed: {
    ...mapGetters({
      survey: "survey/survey",
    }),
    isValid: function() {
      return this.$refs["question"].reduce(
        (acc, curr) => acc && curr.isValid,
        true
      );
    },
  },
  methods: {
    getQuestionGroup(id, original) {
      let translation = undefined;

      if (this.language) {
        translation = this.survey.SurveyQuestionGroupTranslations.find(
          (l) => l.Code === this.language.Code && l.Id === id
        )?.Title;
      }

      return translation || original;
    },
  },
  props: {
    language: Object,
    questionGroup: Object,
    questionGroupIndex: Number,
    textQuestions: Array,
    textQuestionTranslations: Array,
    selections: Array,
    selectionTranslations: Array,
    addedSelections: Array,
    addedSelectionTranslations: Array,
    deletedTextQuestions: Array,
    deletedSelections: Array,
    predefinedQuestions: Array,
  },
};
</script>

<style></style>
