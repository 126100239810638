<template>
  <v-text-field
    v-model="questionQuestion"
    label="Kysymys"
    required
    :prepend-icon="
      language
        ? undefined
        : deletedTextQuestions.includes(question.Id)
        ? 'mdi-delete-restore'
        : 'mdi-delete'
    "
    :error-messages="questionErrors"
    @input="input"
    @blur="$v.questionQuestion.$touch()"
    @click:prepend="toggleDeleted(question.Id)"
    v-bind:class="{
      'deleted-text-question':
        !language && deletedTextQuestions.includes(question.Id),
    }"
  >
  </v-text-field>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  computed: {
    questionErrors() {
      const errors = [];
      if (!this.$v.questionQuestion.$dirty) return errors;
      !this.$v.questionQuestion.required && errors.push("Pakollinen");
      return errors;
    },
    isValid: function () {
      this.validate();
      return !this.$v.$invalid;
    },
  },
  data: function () {
    return {
      questionQuestion: this.question.Question,
    };
  },
  methods: {
    toggleDeleted(id) {
      !this.deletedTextQuestions.includes(id)
        ? this.deletedTextQuestions.splice(0, 0, id)
        : this.deletedTextQuestions.splice(
            this.deletedTextQuestions.indexOf(id),
            1
          );
    },
    input(value) {
      this.question.Question = value;
      this.$v.questionQuestion.$touch();
    },
    validate() {
      this.$v.$touch();
    },
  },
  validations: {
    questionQuestion: {
      required,
    },
  },
  props: {
    question: { type: Object, required: true },
    deletedTextQuestions: { type: Array, required: true },
    language: Object,
  },
};
</script>

<style scoped>
.deleted-text-question >>> input {
  text-decoration: line-through;
  color: red;
}
</style>