<template>
  <div v-if="overview && compareOverview">
    <!-- Rinkulat -->
    <div class="mb-10">
      <v-row>
        <v-col cols="12" xl="6">
          <v-card>
            <v-card-title>{{ compareOverview.surveyName }}</v-card-title>
            <v-card-text>
              <ReportSurveyOverview
                :overview="compareOverview"
                :filter="filter" /></v-card-text
          ></v-card>
        </v-col>
        <v-col cols="12" xl="6">
          <v-card>
            <v-card-title>{{ overview.surveyName }}</v-card-title>
            <v-card-text>
              <ReportSurveyOverview
                :overview="overview"
                :filter="filter" /></v-card-text
          ></v-card>
        </v-col>
      </v-row>
    </div>
    <!-- Tabit -->
    <v-tabs
      v-if="showTabs"
      v-model="tab"
      color="basil"
      grow
      slider-size="0"
      active-class="custom-v-tabs-active-tab"
    >
      <v-tab @click="tabClick">
        {{ `${$t("voter.report.show")} ${$t("voter.report.overall")}` }}
      </v-tab>
      <v-tab
        v-for="item in overview.predefined"
        :key="item.id"
        @click="tabClick"
      >
        {{ `${$t("voter.report.show")} ${item.question}` }}
      </v-tab>
    </v-tabs>
    <div v-if="showTabs" class="custom-v-tabs-slider"></div>
    <v-tabs-items v-model="tab">
      <v-tab-item
        ><div class="d-flex justify-end">
          <v-switch
            v-model="showLegend"
            :label="$t('voter.report.showDescriptions')"
          ></v-switch>
        </div>
        <CompareReportAllTopBottom
          :title="`${$t('voter.report.overall')} -
                  ${compareOverview.surveyName}`"
          :overview="compareOverview"
          v-show="showGroup === 0 || showGroup === 1"
          :showLegend="showLegend"
          :enableDrill="false"
        />
        <CompareReportAllTopBottom
          :title="`${$t('voter.report.overall')} -
                  ${overview.surveyName}`"
          :overview="overview"
          v-show="showGroup === 0 || showGroup === 1"
          :showLegend="showLegend"
          :enableDrill="false"
        />

        <CompareBestWorst
          :overview="overview"
          :compareOverview="compareOverview"
          :mapping="overview.reportCombinationJson"
        />

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <td></td>
                <td>
                  {{ compareOverview.surveyName }}
                </td>
                <td>
                  {{ overview.surveyName }}
                </td>
              </tr>
            </thead>
            <tbody>
              <CompareRow
                :survey="survey"
                :report="report"
                :overview="overview"
                :compareOverview="compareOverview"
                :overviewWithFilter="overviewWithFilter"
                :compareOverviewWithFilter="compareOverviewWithFilter"
                :item="overview.all[0]"
                :all="true"
              />
              <CompareRow
                :item="c"
                v-for="c in overview.majorGroups"
                :key="c.id"
                :survey="survey"
                :report="report"
                :overview="overview"
                :compareOverview="compareOverview"
                :overviewWithFilter="overviewWithFilter"
                :compareOverviewWithFilter="compareOverviewWithFilter"
              />
            </tbody>
          </template>
        </v-simple-table>
      </v-tab-item>
      <v-tab-item v-for="item in overview.predefined" :key="item.id">
        <v-expansion-panels class="mt-3">
          <v-expansion-panel
            active-class="active-panel"
            v-for="selection in item.selections"
            :key="selection.id"
            :style="{
              'border-right': '1px solid #f2f2f2',
              'border-left': '1px solid #f2f2f2',
            }"
          >
            <v-expansion-panel-header
              :ref="`panel-header-${selection.id}`"
              @click="clicked(item.id, selection.id)"
            >
              <v-row>
                <v-col class="d-flex align-center" cols="3">{{
                  selection.selection
                }}</v-col>
                <v-col cols="3" class="d-flex align-center justify-center">
                  <span class="mx-10">{{
                    compareSelectionAverage(item.id, selection.id).toFixed(2)
                  }}</span>
                </v-col>

                <v-col cols="3" class="d-flex align-center justify-center">
                  <span class="mx-10">{{
                    selection.average?.toFixed(2) ?? "0.00"
                  }}</span>
                </v-col>

                <v-col
                  cols="3"
                  class="d-flex align-center justify-end"
                  :style="{
                    color: getAnswerPercentageColor(
                      selection.answers,
                      selection.voters
                    ),
                  }"
                >
                  <v-icon
                    v-if="
                      compareSelectionAverage(item.id, selection.id) <
                      (selection.average ?? 0)
                    "
                    :color="$config?.report?.chartColorGreen ?? '#4CAF50'"
                    >mdi-thumb-up</v-icon
                  >

                  <v-icon
                    v-else-if="
                      compareSelectionAverage(item.id, selection.id) >
                      (selection.average ?? 0)
                    "
                    :color="$config?.report?.chartColorRed ?? '#F44336'"
                    >mdi-thumb-down</v-icon
                  >

                  <v-icon v-else>mdi-thumbs-up-down</v-icon>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <LoadingIndicator :isLoading="overviewWithFilterLoading" />

              <CompareBestWorst
                v-if="!overviewWithFilterLoading"
                :overview="overviewWithFilter"
                :compareOverview="compareOverviewWithFilter"
                :mapping="overview.reportCombinationJson"
              />

              <v-simple-table v-if="!overviewWithFilterLoading">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <td></td>
                      <td>
                        {{ compareOverview.surveyName }}
                      </td>
                      <td>
                        {{ overview.surveyName }}
                      </td>
                      <td>{{ $t("voter.report.trend") }}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <CompareRow
                      :item="overviewWithFilter.all[0]"
                      :all="true"
                      :filter="true"
                      :survey="survey"
                      :report="report"
                      :overview="overview"
                      :compareOverview="compareOverview"
                      :overviewWithFilter="overviewWithFilter"
                      :compareOverviewWithFilter="compareOverviewWithFilter"
                    />
                    <CompareRow
                      :item="c"
                      v-for="c in overviewWithFilter.majorGroups"
                      :key="c.id"
                      :filter="true"
                      :survey="survey"
                      :report="report"
                      :overview="overview"
                      :compareOverview="compareOverview"
                      :overviewWithFilter="overviewWithFilter"
                      :compareOverviewWithFilter="compareOverviewWithFilter"
                    />
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import LoadingIndicator from "@/components/LoadingIndicator";
import CompareBestWorst from "@/components/Compare/CompareBestWorst";
import CompareRow from "@/components/Compare/CompareRow";
import CompareReportAllTopBottom from "@/components/Compare/CompareReportAllTopBottom";
import ReportSurveyOverview from "@/components/Report/ReportSurveyOverview";

export default {
  components: {
    LoadingIndicator,
    CompareBestWorst,
    CompareRow,
    CompareReportAllTopBottom,
    ReportSurveyOverview,
  },
  data: () => ({
    tab: 0,
    openPanel: undefined,
    showGroup: 0,
    showLegend: false,
  }),
  methods: {
    clicked: async function (questionId, selectionId) {
      if (this.openPanel !== selectionId) {
        this.openPanel = selectionId;
        this.$emit("clicked", questionId, selectionId);
      } else {
        this.openPanel = undefined;
      }
    },
    tabClick: function () {
      if (this.openPanel) {
        let panel = this.$refs[`panel-header-${this.openPanel}`][0];
        panel.$el.click();
      }
    },
    getAnswerPercentageColor(answerCount, voterCount) {
      if (voterCount === 0) {
        return this.$config?.report?.chartColorRed || "#F44336";
      }
      let percentage = (answerCount / voterCount) * 100;

      let highAnswerPercentageMin =
        this.$config?.report?.highAnswerPercentageMin ?? 66;
      let mediumAnswerPercentageMin =
        this.$config?.report?.mediumAnswerPercentageMin ?? 33;

      if (percentage >= highAnswerPercentageMin) {
        return this.$config?.report?.chartColorGreen || "#4CAF50";
      } else if (percentage >= mediumAnswerPercentageMin) {
        return this.$config?.report?.chartColorYellow || "#FFEB3B";
      } else {
        return this.$config?.report?.chartColorRed || "#F44336";
      }
    },
    compareSelectionAverage(questionId, selectionId) {
      let mapping = JSON.parse(this.overview.reportCombinationJson).surveys[0];
      let oldQuestionId = parseInt(
        Object.keys(mapping.questions).find(
          (key) => mapping.questions[key] === questionId
        )
      );
      let oldSelectionId = parseInt(
        Object.keys(mapping.selections).find(
          (key) => mapping.selections[key] === selectionId
        )
      );

      let sel =
        this.compareOverview.predefined
          .find((p) => p.id === oldQuestionId)
          ?.selections.find((s) => s.id === oldSelectionId)?.average ?? 0;

      return sel;
    },
  },
  props: {
    survey: Object,
    report: Array,
    overview: Object,
    compareOverview: Object,
    overviewWithFilter: Object,
    compareOverviewWithFilter: Object,
    overviewWithFilterLoading: Boolean,
    showTabs: { type: Boolean, required: true },
    filter: { type: Boolean, default: false },
  },
};
</script>

<style></style>
