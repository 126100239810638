<template>
  <div>
    <VoterNavigation :user="voterEmail" @languageChanged="languageChanged">
      <v-btn text v-for="(item, i) in tabs" :key="i" @click="tab = i">
        {{ item }}
      </v-btn>

      <template v-slot:drawer>
        <v-list-item>
          <v-list-item-content class="text-center">
            <v-list-item-title> {{ voterEmail }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item link v-for="(item, i) in tabs" :key="i" @click="tab = i">
          <v-list-item-icon>
            <v-icon>mdi-format-list-bulleted-square</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> {{ item }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </VoterNavigation>

    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col md="10" lg="8">
          <v-card class="elevation-12 main-content">
            <v-card-title v-if="!isLoading">
              {{ tabs[tab] }}
              <v-spacer />

              <v-switch
                v-show="tab === 0"
                :label="$t('voter.allVotersAdded')"
                :input-value="votersAdded"
                @click="switchClick"
              ></v-switch>
            </v-card-title>
            <v-card-subtitle v-if="survey && !isLoading">
              {{ survey.Name }}
            </v-card-subtitle>
            <v-card-text>
              <LoadingIndicator :isLoading="isLoading" />
              <div v-if="survey && voters && !isLoading">
                <v-tabs-items v-model="tab">
                  <v-tab-item>
                    <div v-if="!thankyouDialog">
                      <Voters360Voter
                        ref="voter360"
                        simpleView
                        :voter360="voter360"
                        :predefinedQuestions="predefinedQuestions"
                        :survey="survey"
                        :voterLoading="voterLoading"
                        :disableEditing="votersAdded"
                        :disableAdd="survey.AnswerCount > 0"
                        @predefinedChanged="predefinedChanged"
                        @setVoter="setVoter"
                        @addVoter="addVoter"
                        @deleteVoter="confirmDelete"
                      />
                      <v-dialog
                        v-model="currentVoter.visible"
                        persistent
                        width="480"
                      >
                        <v-card>
                          <v-card-title>
                            {{ $t("voter.editVoter") }}
                          </v-card-title>
                          <v-divider></v-divider>
                          <v-card-text>
                            <v-text-field
                              :label="$t('general.email')"
                              v-model="currentVoter.email"
                              :disabled="voterLoading || currentVoter.answered"
                            ></v-text-field>
                            <v-select
                              :items="survey.Languages"
                              :label="$t('general.language')"
                              v-model="currentVoter.languageCode"
                              :disabled="voterLoading || currentVoter.answered"
                              item-text="Language"
                              item-value="Code"
                            ></v-select>
                          </v-card-text>
                          <v-card-actions>
                            <span v-if="voterLoading">{{
                              $t("voter.saving")
                            }}</span>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="error"
                              @click="currentVoter.visible = false"
                              :disabled="voterLoading"
                            >
                              {{ $t("general.cancel") }}
                            </v-btn>
                            <v-btn @click="editVoter" :disabled="voterLoading">
                              {{ $t("general.save") }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog v-model="deleteDialog" width="500">
                        <v-card>
                          <v-card-title>
                            {{ $t("voter.confirmDelete") }}
                          </v-card-title>
                          <v-divider></v-divider>
                          <v-card-text>
                            {{ $t("voter.confirmDeleteText") }}
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="error" @click="deleteDialog = false">
                              {{ $t("general.cancel") }}
                            </v-btn>
                            <v-btn @click="deleteVoterClick">
                              {{ $t("general.yes") }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog
                        v-model="votersAddedDialog"
                        persistent
                        max-width="290"
                      >
                        <v-card>
                          <v-card-title>
                            {{ $t("voter.confirmChoice") }}</v-card-title
                          >
                          <v-divider></v-divider>
                          <v-card-text class="mt-3"
                            ><p>
                              {{ $t("voter.confirmChoiceText") }}
                            </p>
                            <p>
                              {{ $t("voter.confirmChoiceText2") }}
                            </p></v-card-text
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="error"
                              @click="votersAddedDialog = false"
                            >
                              {{ $t("general.not") }}
                            </v-btn>
                            <v-btn color="success" @click="allVotersSwitchedOn">
                              {{ $t("general.confirmed") }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                    <div v-else>
                      <p>{{ $t("voter.thanksTitle") }}</p>
                      <p>{{ $t("voter.thanksTitle2") }}</p>
                      <p>{{ $t("voter.thanksTitle3") }}</p>
                      <p>{{ $t("voter.thanksTitle4") }}</p>
                    </div>
                  </v-tab-item>
                  <v-tab-item>
                    <div v-if="showResults">
                      <LoadingIndicator :isLoading="overviewsLoading" />
                      <div
                        class="mb-10"
                        v-if="!overviewsLoading && !!selection"
                      >
                        <v-row
                          ><v-col
                            ><h1>{{ $t("voter.report.all") }}</h1></v-col
                          ></v-row
                        >
                        <ReportSurveyOverview :overview="overview" />
                      </div>
                      <div class="mb-3" v-if="!overviewsLoading && !!selection">
                        <v-row
                          ><v-col
                            ><h1>{{ $t("voter.report.personal") }}</h1></v-col
                          ></v-row
                        >
                        <v-row
                          ><v-col cols="3" class="d-flex align-center">{{
                            selection.selection
                          }}</v-col>
                          <v-col
                            cols="6"
                            :style="{
                              color: getRowColor(0, 1),
                            }"
                            class="d-flex align-center justify-space-between"
                          >
                            <v-progress-linear
                              height="25"
                              :color="getRowColor(0, 1)"
                              :value="selection.average * (100 / selection.max)"
                              ><span>{{
                                selection.average
                                  ? selection.average.toFixed(2)
                                  : 0
                              }}</span></v-progress-linear
                            >
                          </v-col>
                          <v-col
                            class="d-flex align-center justify-end"
                            cols="3"
                            :style="{
                              color: getRowColor(0, 1),
                            }"
                            >{{
                              `${selection.answers} / ${selection.voters} (${
                                selection.voters > 0
                                  ? (
                                      (selection.answers / selection.voters) *
                                      100
                                    ).toFixed(0)
                                  : 0
                              }%)`
                            }}</v-col
                          >
                        </v-row>
                      </div>
                      <ReportTemplatesList
                        v-if="!overviewWithFilterLoading"
                        :survey="survey"
                        :report="report"
                        :question="voter360.question"
                        :selection="voter360.selection"
                        :reportTemplates="reportTemplates"
                        :reportIsLoading="reportLoading"
                        @getWebReport="getReport"
                        @downloadWordReport="downloadWordReport"
                      />
                      <div class="d-flex justify-end">
                        <v-switch
                          v-show="!overviewWithFilterLoading"
                          v-model="showLegend"
                          hide-details
                          :label="$t('voter.report.showDescriptions')"
                        ></v-switch>
                      </div>
                      <ReportAllTopBottom
                        :title="$t('voter.report.bestAndWorst')"
                        v-show="showGroup === 0 || showGroup === 1"
                        v-if="!overviewWithFilterLoading"
                        :showLegend="showLegend"
                        :overview="overviewWithFilter"
                        @groupClicked="groupClicked"
                      />
                      <ReportQuestionSelection
                        v-show="showGroup === 0 || showGroup === 2"
                        :survey="survey"
                        :report="report"
                        :item="voter360.question"
                        :selection="voter360.selection"
                        :reportTemplates="reportTemplates"
                        :overviewWithFilter="overviewWithFilter"
                        :overviewWithFilterLoading="overviewWithFilterLoading"
                        :reportIsLoading="reportLoading"
                        :showLegend="showLegend"
                        @groupClicked="groupClicked"
                      />
                    </div>
                    <div v-else>
                      <p>{{ $t("voter.report.notEnoughAnswers") }}</p>
                    </div>
                  </v-tab-item>
                  <v-tab-item>
                    <CompareMain
                      v-if="showResults"
                      :survey="survey"
                      :report="report"
                      :overview="overviewWithFilter"
                      :compareOverview="compareOverviewWithFilter"
                      :showTabs="false"
                      :filter="true"
                    />
                  </v-tab-item>
                </v-tabs-items>
              </div>
              <div class="text-center" v-else-if="!isLoading">
                {{ $t("voter.invalidToken") }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar">{{ snackbarText }}</v-snackbar>
    </v-container>
  </div>
</template>

<style scoped>
.v-messages {
  display: none;
}
.v-card__subtitle {
  padding-left: 32px;
  padding-right: 32px;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import Vue from "vue";

import LoadingIndicator from "@/components/LoadingIndicator";
import Voters360Voter from "@/components/Voters/Voters360Voter";
import ReportQuestionSelection from "@/components/Report/ReportQuestionSelection";
import ReportSurveyOverview from "@/components/Report/ReportSurveyOverview";
import ReportAllTopBottom from "@/components/Report/ReportAllTopBottom";
import ReportTemplatesList from "@/components/Report/ReportTemplatesList";
import VoterNavigation from "@/components/VoterNavigation";
import CompareMain from "@/components/Compare/CompareMain";

export default {
  name: "voter",
  components: {
    LoadingIndicator,
    Voters360Voter,
    ReportQuestionSelection,
    ReportSurveyOverview,
    ReportAllTopBottom,
    ReportTemplatesList,
    VoterNavigation,
    CompareMain,
  },
  data: function () {
    return {
      currentVoter: {
        email: "",
        languageCode: "",
        code: "",
        answered: false,
        visible: false,
      },
      page: 0,
      snackbar: false,
      snackbarText: "",
      tab: 0,
      panel: [0, 1],
      votersAdded: false,
      votersAddedDialog: false,
      deleteDialog: false,
      showGroup: 0,
      showLegend: false,
      thankyouDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      survey: "anonymous/survey",
      report: "anonymous/report",
      surveyLoading: "anonymous/surveyLoading",
      reportLoading: "anonymous/reportLoading",
      voterLoading: "anonymous/voterLoading",
      voters: "anonymous/voters",
      overview: "anonymous/overview",
      overviewLoading: "anonymous/overviewLoading",
      overviewWithFilter: "anonymous/overviewWithFilter",
      overviewWithFilterLoading: "anonymous/overviewWithFilterLoading",
      compareOverview: "anonymous/compareOverview",
      compareOverviewWithFilter: "anonymous/compareOverviewWithFilter",
    }),
    tabs: function () {
      let tabsArray = [
        this.$i18n.t("voter.voters"),
        this.$i18n.t("voter.results"),
      ];

      if (this.survey?.PreviousSurveyGuid && this.showResults) {
        tabsArray.push(this.$i18n.t("voter.report.personalComparisonReport"));
      }

      return tabsArray;
    },
    isLoading: function () {
      return this.surveyLoading || this.voterLoading;
    },
    overviewsLoading: function () {
      return this.overviewLoading || this.overviewWithFilterLoading;
    },
    theQuestion: function () {
      return this.predefinedQuestions.find((q) => q.canEdit);
    },
    predefinedQuestions: function () {
      if (!this.survey) {
        return [];
      }

      var s = this.survey.Pages.map((p) =>
        p.QuestionGroups.map((g) =>
          g.Questions.filter((q) => q.Predefined).map((q) => ({
            id: q.Id,
            question: q.Question,
            isChoice: q.IsChoice,
            canEdit: q.Id === this.survey.Options.Question360Role,
            questionFor360: q.Id === this.survey.Options.Question360Person,
            selections: q.Selections.map((s) => ({
              id: s.Id,
              selection: s.Selection,
              email: s.Email,
            })),
          }))
        ).filter((g) => g.length > 0)
      ).filter((p) => p.length > 0);

      return [].concat(...[].concat(...s));
    },
    voters360: function () {
      if (this.voters === null) {
        return [];
      }

      let voters = [];

      var question = this.predefinedQuestions.find((q) => q.questionFor360);
      if (question) {
        question.selections.forEach((s) => {
          let selfSelection = this.theQuestion.selections.find(
            (sel) => sel.id === this.survey.Options.Selection360Self
          ).selection;

          let self = this.voters.find((v) => {
            return (
              v.answers[this.survey.Options.Question360Role] ===
                selfSelection &&
              v.answers[this.survey.Options.Question360Person] === s.selection
            );
          });

          const voter = {
            voter: self,
            question: question,
            selection: s,
            voters: this.theQuestion.selections.map((t) => ({
              question: {
                question: this.theQuestion.question,
                id: this.theQuestion.id,
              },
              selection: t,
              emails: this.voters
                .filter(
                  (v) =>
                    v.answers[this.theQuestion.id] === t.selection &&
                    v.answers[question.id] === s.selection
                )
                .map((vv) => ({
                  email: vv.voterEmail,
                  code: vv.voterCode,
                  languageCode: vv.languageCode,
                  answered: vv.answered,
                  added: vv.added,
                })),
            })),
            predefined: this.predefinedQuestions
              .filter((p) => !p.questionFor360 && !p.canEdit)
              .reduce((acc, curr) => {
                acc[curr.id] = self?.answers[curr.id] ?? "";
                return acc;
              }, {}),
          };

          if (self) {
            this.votersAdded = !!self.allVotersAdded;
          }

          voters.push(voter);
        });
      }
      return voters.filter((v) => !!v.voter);
    },
    voter360: function () {
      if (!this.voters360.length) {
        return {};
      }
      let obj = Object.assign({}, this.voters360[0]);
      obj.question.selections = obj.question.selections.filter(
        (s) => s.id === obj.selection.id
      );

      return obj;
    },
    reportTemplates: function () {
      return this.survey !== null ? this.survey.ReportTemplates : [];
    },
    selection: function () {
      return this.overviewWithFilter?.predefined[0].selections[0];
    },
    isValid: function () {
      return this.$refs["voter360"].isValid;
    },
    showResults: function () {
      return (
        this.survey &&
        this.survey.AnswerCount >= this.$config.anonymous.showResultsLimit
      );
    },
    voterEmail: function () {
      return this.voter360?.voter?.voterEmail ?? "";
    },
  },
  methods: {
    ...mapActions({
      getExternalSurvey: "anonymous/getSurvey",
      getVoters: "anonymous/getVoters",
      getReportOverview: "anonymous/getReportOverview",
      getReportOverviewWithFilter: "anonymous/getReportOverviewWithFilter",
      getWebReport: "anonymous/getWebReport",
      allVotersAdded: "anonymous/allVotersAdded",
      postVoters: "anonymous/postVoters",
      putVoter: "anonymous/putVoter",
      deleteVoter: "anonymous/deleteVoter",
    }),
    setVoter(voter, edit) {
      if (this.checkHasAnswers()) {
        return;
      }
      this.currentVoter.email = voter.email;
      this.currentVoter.languageCode = voter.languageCode;
      this.currentVoter.code = voter.code;
      this.currentVoter.answered = voter.answered;
      this.currentVoter.visible = edit !== undefined ? edit : true;
    },
    async addVoter(email, voter, selection) {
      if (this.checkHasAnswers()) {
        return;
      }

      if (!email || email.length === 0) {
        return;
      }

      let answers = Object.assign({}, voter.predefined);

      let newVoter = {
        // TODO: Halutaanko että käyttäjä voi valita ?
        LanguageCode: undefined,
        VoterEmail: email,
        Answers: answers,
      };

      newVoter.Answers[voter.question.id] = voter.selection.selection;
      newVoter.Answers[selection.question.id] = selection.selection.selection;

      let voters = {
        surveyGuid: this.$route.params.guid,
        voters: [newVoter],
      };

      await this.postVoters(voters);
      this.getVoters(this.$route.params.guid);
    },
    confirmDelete(voter) {
      if (this.checkHasAnswers()) {
        return;
      }
      this.setVoter(voter, false);
      this.deleteDialog = true;
    },
    async deleteVoterClick() {
      if (this.checkHasAnswers()) {
        return;
      }
      this.deleteDialog = false;
      await this.deleteVoter(this.currentVoter.code);
      this.getVoters(this.$route.params.guid);
    },
    async editVoter() {
      if (this.checkHasAnswers()) {
        return;
      }

      let o = {
        email: this.currentVoter.email,
        code: this.currentVoter.code,
        languageCode: this.currentVoter.languageCode,
      };

      let email = o.email;

      if (!email || email.length === 0) {
        return;
      }

      let voter = {
        AccessToken: this.$route.params.guid,
        LanguageCode: o.languageCode,
        VoterEmail: email,
        VoterCode: o.code,
      };

      if (await this.putVoter(voter)) {
        this.currentVoter.visible = false;
        this.getVoters(this.$route.params.guid);
      }
    },
    async predefinedChanged(voter360, questionId) {
      if (this.checkHasAnswers()) {
        return;
      }

      let voter = {
        LanguageCode: voter360.voter.languageCode,
        VoterEmail: voter360.voter.voterEmail,
        VoterCode: voter360.voter.voterCode,
        Answers: Object.assign({}, voter360.voter.answers),
      };

      await this.putVoter(voter);

      let emails = voter360.voters
        .filter((s) => s.selection.id !== this.survey.Options.Selection360Self)
        .reduce((acc, curr) => {
          acc = acc.concat(
            curr.emails.map((e) => {
              let email = Object.assign({}, e);
              email.answers = Object.assign(
                {},
                this.voters.find((v) => v.voterCode === e.code).answers
              );
              email.answers[questionId] = voter360.voter.answers[questionId];
              return email;
            })
          );
          return acc;
        }, []);

      await Promise.all(
        emails.map(async (v) => {
          let voter = {
            LanguageCode: v.languageCode,
            VoterEmail: v.email,
            VoterCode: v.code,
            Answers: v.answers,
          };
          await this.putVoter(voter);
        })
      );

      this.getVoters(this.survey.Guid);
    },
    getRowColor(index, count) {
      if (count === 1) {
        return this.$config?.report?.chartColorBlue || "#2196F3";
      }
      let colorize = Math.round(count / 4);
      if (index + 1 <= colorize) {
        return this.$config?.report?.chartColorGreen || "#4CAF50";
      } else if (count - index <= colorize) {
        return this.$config?.report?.chartColorRed || "#F44336";
      } else {
        return this.$config?.report?.chartColorYellow || "#FFEB3B";
      }
    },
    async downloadWordReport(report, question, selection) {
      this.$store.commit("anonymous/SET_REPORT_LOADING", true);

      try {
        let response = await axios({
          method: "POST",
          url: `/api/anonymous/wordreport`,
          data: {
            Guid: this.$route.params.guid,
            ReportId: report.Id,
          },
          responseType: "blob",
        });

        const blob = new Blob([response.data]);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${report.Name} - ${question.question} - ${selection.selection}.docx`;
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (e) {
        console.log(e);
      }

      this.$store.commit("anonymous/SET_REPORT_LOADING", false);
    },
    getReport(...args) {
      this.getWebReport({
        guid: this.$route.params.guid,
        reportId: args[1],
      });
    },
    async switchClick() {
      if (this.votersAdded) {
        try {
          await this.allVotersAdded({
            guid: this.$route.params.guid,
            allVotersAdded: false,
          });
          this.votersAdded = false;
          this.thankyouDialog = false;
        } catch (err) {
          console.log(err);
        }
        return;
      }

      if (!this.isValid) {
        this.votersAddedDialog = true;
        this.votersAdded = true;
        Vue.nextTick(() => {
          this.votersAdded = false;
        });
        return;
      }

      try {
        await this.allVotersAdded({
          guid: this.$route.params.guid,
          allVotersAdded: true,
        });
        this.votersAdded = true;
      } catch (err) {
        console.log(err);
      }
    },
    async allVotersSwitchedOn() {
      try {
        await this.allVotersAdded({
          guid: this.$route.params.guid,
          allVotersAdded: true,
        });
        this.votersAdded = true;
        this.votersAddedDialog = false;
        this.thankyouDialog = true;
      } catch (err) {
        console.log(err);
      }
    },
    groupClicked(event) {
      this.showGroup = event;
    },
    checkHasAnswers() {
      if (this.survey.AnswerCount > 0) {
        this.snackbarText = this.$i18n.t("voter.surveyHasAnswers");
        this.snackbar = true;
      }
      return this.survey.AnswerCount > 0;
    },
    async languageChanged() {
      await this.getExternalSurvey(this.$route.params.guid);
      await this.getVoters(this.$route.params.guid);
      if (this.showResults) {
        this.getReportOverview({ guid: this.$route.params.guid });
        this.getReportOverviewWithFilter({ guid: this.$route.params.guid });
        this.getReportOverviewWithFilter({
          guid: this.$route.params.guid,
          compare: true,
        });
      }
    },
  },
  watch: {
    snackbarText: function (newValue) {
      if (newValue) {
        this.snackbar = true;
      }
    },
  },
  async created() {
    // Onko parempaa tapaa tarkastaa löytyykö kieli kuin $te ?
    if (
      this.$route.query.lang &&
      this.$te("general.cancel", this.$route.query.lang.toLowerCase())
    ) {
      this.changeLanguage(this.$route.query.lang.toLowerCase());
    } else {
      this.changeLanguage("en");
    }
  },
  async mounted() {
    await this.getExternalSurvey(this.$route.params.guid);
    await this.getVoters(this.$route.params.guid);
    this.votersAdded = this.survey.AllVotersAdded !== null;
    if (this.showResults) {
      this.getReportOverview({ guid: this.$route.params.guid });
      this.getReportOverviewWithFilter({ guid: this.$route.params.guid });
      this.getReportOverviewWithFilter({
        guid: this.$route.params.guid,
        compare: true,
      });
    }
  },
};
</script>
