<template>
  <div>
    <h3 class="mb-5">Vastaajat</h3>

    <v-row>
      <v-col md="2" cols="12">
        <v-btn @click="addVoterDialog = true" color="success" class="mobile-btn"
          >Lisää uusi</v-btn
        >
      </v-col>
      <v-col md="2" cols="12">
        <VotersDatatableSendMailDialog
          v-if="selectedLanguage"
          :selectedVoters="selected"
          :selectedLanguage="selectedLanguage"
          :emails="emails"
          @update:selectedLanguage="$emit('update:selectedLanguage', $event)"
          @update:reminderInDays="$emit('update:reminderInDays', $event)"
          @update:sendAfter="$emit('update:sendAfter', $event)"
          @emailChanged="$emit('emailChanged', $event)"
          @titleChanged="$emit('titleChanged', $event)"
          @sendEmails="$emit('sendEmails')"
        />
      </v-col>
      <v-col offset-md="4" md="4" cols="12">
        <v-text-field
          v-model="searchVoter"
          append-icon="mdi-magnify"
          label="Haku"
          class="pa-0 ma-0"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :search="searchVoter"
        v-model="selectedVoters"
        :headers="voterHeaders"
        :items="calculatedVoters"
        :loading="voterLoading"
        item-key="voterCode"
        show-select
        class="elevation-0 col"
        @item-selected="selectedChanged"
        @toggle-select-all="allSelectedChanged"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="deleteVoterClick(item.voterCode)">
            <v-icon small> mdi-delete </v-icon>
          </v-btn>
        </template>

        <template v-slot:[`item.voterEmail`]="{ item }">
          <i v-if="item.answered" class="success--text">
            {{ item.voterEmail }}
          </i>

          <v-edit-dialog
            :return-value.sync="item.voterEmail"
            large
            cancel-text="Peruuta"
            save-text="Tallenna"
            @save="voterEditLanguageSave(item)"
            v-else
          >
            {{ item.voterEmail }}
            <template v-slot:input>
              <v-text-field
                label="Sähköposti"
                v-model="item.voterEmail"
                class="pt-5"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:[`item.languageCode`]="{ item }">
          <span v-if="item.answered">
            {{ item.languageCode }}
          </span>
          <v-edit-dialog
            :return-value.sync="item.languageCode"
            large
            cancel-text="Peruuta"
            save-text="Tallenna"
            @save="voterEditLanguageSave(item)"
            v-else
          >
            {{ item.languageCode }}
            <template v-slot:input>
              <v-select
                :items="survey.Languages"
                v-model="item.languageCode"
                item-text="Language"
                item-value="Code"
              ></v-select>
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:[`item.latestMailAdded`]="{ item }">
          {{ formatDateTime(item.latestMailAdded) }}
        </template>

        <template
          v-for="slot in selectValues"
          v-slot:[getSlotKey(slot)]="{ item }"
        >
          <v-edit-dialog
            :key="slot.objectKey"
            large
            cancel-text="Peruuta"
            save-text="OK"
            @open="editText = getObjectValue(item, slot.objectKey)"
            @save="saveSelect(item, slot.objectKey)"
            @close="editText = ''"
          >
            {{ getObjectValue(item, slot.objectKey) }}
            <template v-slot:input>
              <v-select
                v-if="slot.type == 'select'"
                :items="slot.values"
                v-model="editText"
                item-text="value"
                item-value="key"
                class="pt-5"
              ></v-select>

              <v-combobox
                v-if="slot.type == 'combo'"
                :items="slot.selections"
                v-model="editText"
                @update:search-input="updateCombo"
                item-text="value"
                item-value="value"
                class="pt-5"
              ></v-combobox>

              <v-text-field
                v-if="slot.type == 'text'"
                v-model="editText"
                class="pt-5"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
      </v-data-table>
    </v-row>

    <VotersAddDialog
      :showDialog="addVoterDialog"
      :survey="survey"
      :predefinedQuestions="predefinedQuestions"
      @close="addVoterDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VotersAddDialog from "@/components/Voters/VotersAddDialog";
import VotersDatatableSendMailDialog from "@/components/Voters/VotersDatatableSendMailDialog";

export default {
  components: {
    VotersAddDialog,
    VotersDatatableSendMailDialog,
  },
  computed: {
    ...mapGetters({
      survey: "survey/survey",
      voterLoading: "voter/isLoading",
      voters: "voter/voters",
    }),
    voterHeaders: function() {
      let arr = [
        {
          text: "Email",
          value: "voterEmail",
        },
        {
          text: "Kieli",
          value: "languageCode",
        },
        {
          text: "Viimeisin lähetetty sähköposti",
          value: "latestMailAdded",
        },
      ];

      arr = arr.concat(
        this.predefinedQuestions.map((q) => {
          return {
            text: q.question,
            value: `answers[${q.id}]`,
          };
        })
      );

      arr = arr.concat({
        text: "",
        value: "actions",
        sortable: false,
      });

      return arr;
    },
    calculatedVoters: function() {
      return this.voters.map((v) => {
        return Object.assign(v, {
          isSelectable: !v.answered,
        });
      });
    },
  },
  data: function() {
    return {
      addVoterDialog: false,
      paste: "",
      newVoter: {
        VoterEmail: "",
        VoterCode: "",
        LanguageCode: "",
        Answers: {},
        hasAnswered: false,
      },
      searchVoter: "",
      selectedVoters: [],
      selectValues: [],
      editText: "",
    };
  },
  methods: {
    ...mapActions({
      getExternalSurvey: "survey/getExternalSurvey",
      getVoters: "voter/getVoters",
      postVoters: "voter/postVoters",
      putVoter: "voter/putVoter",
      deleteVoter: "voter/deleteVoter",
      sendMail: "voter/sendMail",
    }),
    async deleteVoterClick(voterCode) {
      if (confirm("Oletko varma?")) {
        await this.deleteVoter(voterCode);
        this.getVoters(this.survey.Guid);
        this.snackbarText = "Vastaaja poistettu";
      }
    },
    selectedChanged(item) {
      this.$emit("selectedChanged", item);
    },
    allSelectedChanged({ value }) {
      this.$emit("allSelectedChanged", value);
    },
    async voterEditLanguageSave(voter) {
      let voterModel = {
        LanguageCode: voter.languageCode,
        VoterEmail: this.cleanEmail(voter.voterEmail),
        VoterCode: voter.voterCode,
      };
      this.selectedChanged({ item: voter, value: false });
      await this.putVoter(voterModel);
      this.$emit("initialize");
    },
    async voterEditAnswers(voter) {
      let voterModel = {
        LanguageCode: voter.languageCode,
        VoterEmail: this.cleanEmail(voter.voterEmail),
        VoterCode: voter.voterCode,
      };
      voterModel.Answers = voter.answers;
      let isSelected = !!this.selected.find(
        (v) => v.voterCode === voter.voterCode
      );
      this.selectedChanged({ item: voter, value: false });
      let response = await this.putVoter(voterModel);
      await this.getVoters(this.survey.Guid);
      let newVoter = this.voters.find(
        (v) => v.voterCode === response.toUpperCase()
      );
      if (isSelected && newVoter) {
        this.selectedChanged({ item: newVoter, value: true });
      }
    },
    getSlotKey(slot) {
      return `item.${slot.objectKey}`;
    },
    getObjectValue(item, key) {
      let nested = key.match(/\[(.*)\]/);

      if (nested && nested.length > 0 && nested.index > 0) {
        var propertyKey = key.substring(0, nested.index);
        var nestedKey = nested[1];

        return item[propertyKey][nestedKey];
      }

      return item[key];
    },
    async saveSelect(data, key) {
      if (this.editText === null || this.editText.trim().length === 0) {
        this.$emit("update:snackbarText", "Et voi tallentaa tyhjää arvoa.");
        return;
      }
      let id = key.substring(key.indexOf("[") + 1, key.indexOf("]"));
      let updated = {};
      updated[id] = this.editText;
      let voter = Object.assign(data);
      voter.answers = Object.assign(voter.answers, updated);
      await this.voterEditAnswers(voter);
    },
    updateCombo(s) {
      this.editText = s;
    },
    titleChanged: function(value) {
      this.$emit("titleChanged", value);
    },
    emailChanged: function(html) {
      this.$emit("emailChanged", html);
    },
  },
  watch: {
    selected: function(newValue) {
      this.selectedVoters = newValue;
    },
  },
  created() {
    let questions = this.predefinedQuestions.map((q) => {
      let locked =
        this.survey.Options.Question360Role === q.id ||
        this.survey.Options.Question360Person === q.id;

      let selections = q.selections.filter((s) => s.selection.length > 0);
      let type = locked ? "select" : "combo";

      return {
        objectKey: `answers[${q.id}]`,
        locked: locked,
        type: type,
        values: selections.map((s) => {
          return {
            key: s.selection,
            value: s.selection,
          };
        }),
        selections: selections.map((s) => {
          return s.selection;
        }),
      };
    });

    this.selectValues = questions;
  },
  props: {
    predefinedQuestions: { type: Array, required: true },
    selected: { type: Array, required: true },
    selectedLanguage: Object,
    emails: { type: Object, required: true },
  },
};
</script>

<style></style>
