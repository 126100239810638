import axios from "axios";

export default {
  namespaced: true,

  state: {
    voters: [],
    isLoading: false,
  },

  getters: {
    voters(state) {
      return state.voters;
    },
    isLoading(state) {
      return state.isLoading;
    },
  },

  mutations: {
    SET_VOTERS(state, voters) {
      state.voters = voters;
    },
    SET_ISLOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
  },

  actions: {
    async getVoters({ commit }, guid) {
      try {
        commit("SET_ISLOADING", true);
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/voter/${guid}`
        );
        commit("SET_ISLOADING", false);
        commit("SET_VOTERS", response.data);
        return true;
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    async postVoters({ commit }, voters) {
      try {
        commit("SET_ISLOADING", true);
        await axios.post(`${process.env.VUE_APP_API_URL}/voter`, voters);
        commit("SET_ISLOADING", false);
        return true;
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    async putVoter({ commit }, voter) {
      try {
        commit("SET_ISLOADING", true);
        let response = await axios.put(
          `${process.env.VUE_APP_API_URL}/voter/${voter.VoterCode}`,
          voter
        );
        commit("SET_ISLOADING", false);
        return response.data;
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    async deleteVoter({ commit }, guid) {
      try {
        commit("SET_ISLOADING", true);
        await axios.delete(`${process.env.VUE_APP_API_URL}/voter/${guid}`);
        commit("SET_ISLOADING", false);
        return true;
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    async sendMail({ commit }, emails) {
      try {
        commit("SET_ISLOADING", true);
        await axios.post(`${process.env.VUE_APP_API_URL}/voter/mail`, emails);
        commit("SET_ISLOADING", false);
        return true;
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    async postFile({ commit }, file) {
      try {
        commit("SET_ISLOADING", true);
        await axios.post(
          `${process.env.VUE_APP_API_URL}/voter/uploadfile`,
          file
        );
        commit("SET_ISLOADING", false);
        return true;
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    async sendNewVoterMail({ commit }, emails) {
      try {
        commit("SET_ISLOADING", true);
        await axios.post(
          `${process.env.VUE_APP_API_URL}/voter/sendNewVoterMail`,
          emails
        );
        commit("SET_ISLOADING", false);
        return true;
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    clearVoters({ commit }) {
      commit("SET_VOTERS", []);
    },
  },
};
