<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col md="10" lg="8">
        <v-card class="elevation-12 main-content">
          <v-card-text>
            <LoadingIndicator
              :isLoading="isLoading && !overviewWithFilterLoading"
            />

            <CompareMain
              :survey="survey"
              :report="report"
              :overview="overview"
              :compareOverview="compareOverview"
              :overviewWithFilter="overviewWithFilter"
              :compareOverviewWithFilter="compareOverviewWithFilter"
              :showTabs="true"
              :overviewWithFilterLoading="overviewWithFilterLoading"
              @clicked="clicked"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="secondary"
              :to="{
                name: 'report',
                params: { guid: this.$route.params.guid },
              }"
              >Takaisin</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">{{ snackbarText }}</v-snackbar>
  </v-container>
</template>

<style scoped>
.active-panel {
  background-color: #f6f6f6;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";

import LoadingIndicator from "@/components/LoadingIndicator";
import CompareMain from "@/components/Compare/CompareMain";

export default {
  name: "report",
  mixins: [validationMixin],
  components: {
    LoadingIndicator,
    CompareMain,
  },
  data: () => ({
    overviewWithFilterLoading: false,
    snackbar: false,
    snackbarText: "",
  }),
  computed: {
    ...mapGetters({
      reportIsLoading: "report/isLoading",
      surveyIsLoading: "survey/isLoading",
      survey: "survey/survey",
      report: "report/report",
      overview: "report/overview",
      compareOverview: "report/compareOverview",
      overviewWithFilter: "report/overviewWithFilter",
      compareOverviewWithFilter: "report/compareOverviewWithFilter",
    }),
    isLoading() {
      return this.reportIsLoading || this.surveyIsLoading;
    },
  },
  methods: {
    ...mapActions({
      getExternalSurvey: "survey/getExternalSurvey",
      getReportOverview: "report/getReportOverview",
      getCompareReportOverview: "report/getCompareReportOverview",
      getReportOverviewWithFilter: "report/getReportOverviewWithFilter",
      getCompareReportOverviewWithFilter:
        "report/getCompareReportOverviewWithFilter",
      clearOverviewWithFilter: "report/clearOverviewWithFilter",
      clearCompareOverviewWithFilter: "report/clearCompareOverviewWithFilter",
    }),
    clicked: async function (questionId, selectionId) {
      this.clearOverviewWithFilter();
      this.clearCompareOverviewWithFilter();
      this.overviewWithFilterLoading = true;
      await this.getReportOverviewWithFilter({
        guid: this.$route.params.guid,
        questionId: questionId,
        selectionId: selectionId,
      });

      const json = JSON.parse(this.overview.reportCombinationJson);
      const compareSurveyQuestionId = Object.keys(
        json.surveys[0].questions
      ).find((k) => json.surveys[0].questions[k] === questionId);
      const compareSurveySelectionId = Object.keys(
        json.surveys[0].selections
      ).find((k) => json.surveys[0].selections[k] === selectionId);

      if (compareSurveySelectionId) {
        await this.getCompareReportOverviewWithFilter({
          guid: this.survey.PreviousSurveyGuid,
          questionId: compareSurveyQuestionId,
          selectionId: compareSurveySelectionId,
        });
      }
      this.overviewWithFilterLoading = false;
    },
  },
  watch: {
    snackbarText: function (newValue) {
      if (newValue) {
        this.snackbar = true;
        setTimeout(() => {
          this.snackbar = false;
          this.snackbarText = "";
        }, 2000);
      }
    },
  },
  async created() {
    this.changeLanguage("fi");

    await this.getExternalSurvey(this.$route.params.guid);

    if (this.survey?.PreviousSurveyGuid) {
      await this.getReportOverview(this.$route.params.guid);
      await this.getCompareReportOverview(this.survey.PreviousSurveyGuid);
    } else {
      this.$router.push({
        name: "surveys",
        params: this.survey ? { guid: this.$route.params.guid } : {},
      });
    }
  },
  destroyed() {
    this.$store.dispatch("report/clearOverview", null);
    this.$store.dispatch("report/clearCompareOverview", null);
    this.$store.dispatch("report/clearOverviewWithFilter", null);
    this.$store.dispatch("survey/clearSurvey", null);
  },
};
</script>
