<template>
  <div>
    <v-row>
      <v-col offset-md="3" md="3">
        {{ selection.selection }}
      </v-col>
      <v-col offset-md="3" md="3">
        <v-text-field
          label="Käännös"
          class="pa-0"
          required
          :error-messages="selectionErrors"
          :disabled='isDeleted'
          v-bind:class="{
            'deleted-selection': isDeleted,
          }"
          @blur="$v.selectionSelection.$touch()"
          v-model="computedSelection"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  computed: {
    ...mapGetters({
      survey: "survey/survey",
    }),
    computedSelection: {
      get() {
        this.validate();
        return (
          this.selectionTranslations.find((l) => l.code === this.language.Code)
            ?.selections[this.selection.Id] ?? ""
        );
      },
      set(newValue) {
        this.selectionSelection = newValue;
        this.selectionTranslations.find(
          (l) => l.code === this.language.Code
        ).selections[this.selection.Id] = newValue;
      },
    },
    selectionErrors() {
      const errors = [];
      if (!this.$v.selectionSelection.$dirty) return errors;
      !this.$v.selectionSelection.required && errors.push("Pakollinen");
      return errors;
    },
    isValid: function () {
      return !this.$v.selectionSelection.$invalid;
    },
  },
  data: function () {
    return {
      selectionSelection: "",
    };
  },
  methods: {
    validate() {
      this.$v.$touch();
    },
  },
  validations: {
    selectionSelection: {
      required,
    },
  },
  watch: {
    language: function (val) {
      this.selectionSelection =
        this.selectionTranslations.find((l) => l.code === val.Code)?.selections[
          this.selection.Id
        ] ?? "";
      this.validate();
    },
  },
  created() {
    this.selectionSelection =
      this.selectionTranslations.find((l) => l.code === this.language.Code)
        ?.selections[this.selection.Id] ?? "";
  },
  props: {
    language: Object,
    selection: Object,
    selectionTranslations: Array,
    isDeleted: Boolean,
  },
};
</script>

<style scoped>
.deleted-selection >>> input {
  text-decoration: line-through;
  color: red;
}
</style>