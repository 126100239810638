<template>
  <div v-if="language">
    <v-row>
      <v-col>
        <h3>Mittauksen tiedot</h3>
        <v-text-field
          label="Mittauksen nimi"
          required
          :error-messages="surveyNameTranslationErrors"
          @input="input"
          @blur="$v.surveyNameTranslation.$touch()"
          v-model="computedName"
        ></v-text-field>
      </v-col>
    </v-row>
    <div v-if="!onlySurveyDetails">
      <Page
        ref="page"
        v-for="(p, pIdx) in survey.Pages"
        :key="p.Id"
        :language="language"
        :page="p"
        :pageIndex="pIdx"
        :textQuestions="textQuestions"
        :textQuestionTranslations="textQuestionTranslations"
        :selections="selections"
        :selectionTranslations="selectionTranslations"
        :addedSelections="addedSelections"
        :addedSelectionTranslations="addedSelectionTranslations"
        :predefinedQuestions="predefinedQuestions"
        :deletedTextQuestions="deletedTextQuestions"
        :deletedSelections="deletedSelections"
      />
    </div>
    <div v-if="!onlySurveyDetails && newTextQuestions.length > 0">
      <v-row>
        <v-col md="12">
          Lisätyt avoimet kysymykset (Lisään mittauksen loppuun)
        </v-col>
      </v-row>
      <Question
        ref="newquestion"
        label="Käännös"
        v-for="q in newTextQuestions"
        :key="q.Id"
        :language="language"
        :question="q"
        :textQuestions="newTextQuestions"
        :textQuestionTranslations="newTextQuestionTranslations"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import Page from "@/components/Survey/Page";
import Question from "@/components/Survey/Question";

export default {
  mixins: [validationMixin],
  components: {
    Page,
    Question,
  },
  computed: {
    ...mapGetters({
      survey: "survey/survey",
    }),
    computedName: {
      get() {
        return (
          this.surveyDetailsTranslations.find(
            (l) => l.code === this.language.Code
          )?.name ?? `${this.surveyDetails.name} ${this.language.Code}`
        );
      },
      set(newValue) {
        this.surveyDetailsTranslations.find(
          (l) => l.code === this.language.Code
        ).name = newValue;
      },
    },
    surveyNameTranslationErrors() {
      const errors = [];
      if (!this.$v.surveyNameTranslation.$dirty) return errors;
      !this.$v.surveyNameTranslation.required && errors.push("Pakollinen");
      return errors;
    },
    isValid: function () {
      let valid = !this.$v.surveyNameTranslation.$invalid;
      if (this.$refs["newquestion"] && this.$refs["newquestion"].length) {
        let newQuestions = this.$refs["newquestion"].reduce((acc, curr) => {
          curr.$v.$touch();
          return acc && curr.isValid;
        }, true);
        valid = valid && newQuestions;
      }
      if (this.$refs["page"] && this.$refs["page"].length) {
        let questionTranslations = this.$refs["page"].reduce((acc, curr) => {
          return acc && curr.isValid;
        }, true);
        valid = valid && questionTranslations;
      }
      return valid;
    },
  },
  data: function () {
    return {
      surveyNameTranslation:
        this.surveyDetailsTranslations.find(
          (l) => l.code === this.language.Code
        )?.name ?? `${this.surveyDetails.name} ${this.language.Code}`,
    };
  },
  methods: {
    input(value) {
      this.surveyNameTranslation = value;
      this.$v.surveyNameTranslation.$touch();
    },
    validate() {
      this.$v.surveyNameTranslation.$touch();
    },
  },
  validations: {
    surveyNameTranslation: {
      required,
    },
  },
  watch: {
    language: function (val) {
      this.surveyNameTranslation =
        this.surveyDetailsTranslations.find((l) => l.code === val.Code)?.name ??
        `${this.surveyDetails.name} ${this.language.Code}`;
      this.validate();
    },
  },
  props: {
    language: Object,
    surveyDetails: Object,
    surveyDetailsTranslations: Array,
    textQuestions: Array,
    textQuestionTranslations: Array,
    selections: Array,
    selectionTranslations: Array,
    deletedTextQuestions: Array,
    deletedSelections: Array,
    newTextQuestions: Array,
    newTextQuestionTranslations: Array,
    predefinedQuestions: Array,
    addedSelections: Array,
    addedSelectionTranslations: Array,
    onlySurveyDetails: { type: Boolean, default: false },
  },
};
</script>

<style></style>
