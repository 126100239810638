<template>
  <v-list-item class="pa-0" inactive :ripple="false" ref="list-item">
    <v-list-item-content>
      <v-row>
        <v-col v-if="smsEnabled" cols="4">
          <v-select v-model="voterType" :items="items">
            <template v-slot:item="{ item }">
              <v-icon class="mr-1">{{ item.icon }}</v-icon> {{ item.text }}
            </template>
            <template v-slot:selection="{ item }">
              <v-icon class="mr-1">{{ item.icon }}</v-icon> {{ item.text }}
            </template>
          </v-select>
        </v-col>
        <v-col :cols="smsEnabled ? 8 : 12">
          <v-combobox
            ref="combo"
            clearable
            :label="
              voterType === 1
                ? $t('voter.addNewEmail')
                : $t('voter.addNewPhone')
            "
            v-model="email"
            :items="selectionEmails"
            :disabled="disableEditing || voterLoading"
            :search-input.sync="search"
            @keyup.enter="enterPressed"
          ></v-combobox
        ></v-col>
      </v-row>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn
        :disabled="disableEditing || voterLoading"
        :loading="voterLoading"
        small
        color="success"
        @click="addVoter"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, numeric } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  computed: {
    selectionEmails: function () {
      return this.predefinedQuestions
        .find((q) => q.questionFor360)
        .selections.map((s) => s.email)
        .filter((e) => e && e.length > 0);
    },
    smsEnabled: function () {
      return this.survey.Options.SmsEnabled;
    },
  },
  data: () => ({
    email: null,
    search: null,
    items: [
      { value: 1, text: "Email", icon: "mdi-email" },
      { value: 2, text: "Puh", icon: "mdi-phone" },
    ],
    voterType: 1,
  }),
  methods: {
    addVoter() {
      this.email = this.search;

      if (this.$v.$invalid) {
        return;
      }

      this.$emit("addVoter", this.search || this.email);
      this.email = null;
      this.$refs["combo"].lazySearch = null;
    },
    enterPressed() {
      this.addVoter();
      this.$refs["list-item"].$el.click();
    },
  },
  validations() {
    if (this.voterType === 1) {
      return { email: { required, email }, search: { required, email } };
    } else {
      return { email: { required, numeric }, search: { required, numeric } };
    }
  },
  watch: {
    voterType: function () {
      this.email = null;
    },
  },
  props: {
    survey: { type: Object, required: true },
    voterLoading: { type: Boolean, required: true },
    voter360: { type: Object, required: true },
    voterSelection: { type: Object, required: true },
    predefinedQuestions: { type: Array, required: true },
    disableEditing: { type: Boolean, default: false },
  },
};
</script>

<style></style>
