import axios from "axios";

export default {
  namespaced: true,

  state: {
    users: [],
    isLoading: false,
  },

  getters: {
    users(state) {
      return state.users;
    },
    isLoading(state) {
      return state.isLoading;
    },
  },

  mutations: {
    SET_USERS(state, users) {
      state.users = users;
    },
    SET_ISLOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
  },

  actions: {
    async getExternalUsers({ commit }) {
      try {
        commit("SET_ISLOADING", true);
        let response = await axios.get(
          `${process.env.VUE_APP_API_URL}/survey/external/user`
        );
        commit("SET_ISLOADING", false);
        commit("SET_USERS", response.data);
        return true;
      } catch (e) {
        commit("SET_ISLOADING", false);
        return false;
      }
    },
    clearExternalUsers({ commit }) {
      commit("SET_USERS", []);
    },
  },
};
