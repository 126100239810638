<template>
  <div v-if="authenticated">
    <v-app-bar color="white" fixed>
      <v-row align="center" justify="center">
        <v-col md="10" lg="8">
          <v-toolbar elevation="0">
            <v-toolbar-title>
              <v-btn
                class="no-active pa-0"
                :ripple="false"
                text
                :to="{ name: 'home' }"
                exact
                large
              >
                <v-img :src="logo" class="logo" />
              </v-btn>
            </v-toolbar-title>

            <v-toolbar-items class="hidden-sm-and-down">
              <v-btn class="" text :to="{ name: 'home' }" exact>
                Mittaukset
              </v-btn>
              <v-btn color="success" class="" text :to="{ name: 'new-survey' }">
                Uusi mittaus
              </v-btn>
            </v-toolbar-items>
            <v-spacer></v-spacer>

            <v-menu bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on" class="hidden-sm-and-down">
                  {{ user.username }}

                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card class="mx-auto" max-width="300">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ user.username }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                  <v-list-item @click="changePassword = true">
                    <v-list-item-icon>
                      <v-icon>mdi-account-key</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Vaihda salasana</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="logoutDialog = true">
                    <v-list-item-icon>
                      <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Kirjaudu ulos</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>

            <v-toolbar-items class="hidden-md-and-up">
              <v-app-bar-nav-icon
                @click="drawer = !drawer"
              ></v-app-bar-nav-icon>
            </v-toolbar-items>
          </v-toolbar>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list nav class="py-0">
        <v-list-item>
          <v-list-item-title class="mt-3">
            <v-img :src="logo" class="logo" />
          </v-list-item-title>
        </v-list-item>

        <v-list-item link :to="{ name: 'home' }" exact>
          <v-list-item-icon>
            <v-icon>mdi-format-list-bulleted-square</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Mittaukset</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'new-survey' }" color="success">
          <v-list-item-icon>
            <v-icon>mdi-shape-square-rounded-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Uusi mittaus</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item @click="changePassword = true">
          <v-list-item-icon>
            <v-icon>mdi-lock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Vaihda salasana</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="logoutDialog = true" color="error">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Kirjaudu ulos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <ChangePassword
      :showDialog="changePassword"
      @close="changePassword = false"
    >
    </ChangePassword>

    <v-dialog v-model="logoutDialog" width="480">
      <v-card>
        <v-card-title class="headline"> Kirjaudu ulos </v-card-title>

        <v-card-text> Sinut kirjataan ulos järjestelmästä. </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="logout"> Kyllä </v-btn>
          <v-btn color="primary" text @click="logoutDialog = false">
            Peruuta
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import logo from "@/assets/mlp_logo.png";
import ChangePassword from "@/components/Settings";

export default {
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  components: { ChangePassword },
  data: () => ({
    drawer: false,
    logoutDialog: false,
    changePassword: false,
    logo: logo,
  }),
  methods: {
    ...mapActions({
      logoutAction: "auth/logout",
    }),
    logout() {
      this.logoutAction().then(() => {
        this.logoutDialog = false;

        this.$router.replace({
          name: "login",
        });
      });
    },
  },
};
</script>

<style>
.v-toolbar__content .logo {
  max-width: 100px;
}

.v-navigation-drawer__content .logo {
  width: 100%;
}

.v-btn--active.no-active::before {
  opacity: 0 !important;
}

.no-hover::before {
  opacity: 0 !important;
}
</style>
