<template>
  <v-menu v-model="menu" :close-on-content-click="false" max-width="290">
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex align-center">
        <v-text-field
          class=""
          :value="formattedDate"
          :error-messages="errors"
          :clearable="!readonly"
          :label="label"
          readonly
          v-bind="attrs"
          v-on="on"
          @click:clear="inputDate = undefined"
          @focus="$v.$touch()"
        ></v-text-field>
        <v-icon color="red" v-if="errors.length">mdi-exclamation-thick</v-icon>
      </div>
    </template>
    <v-date-picker
      v-model="inputDate"
      @change="menu = false"
      :first-day-of-week="1"
      :min="min"
      :max="max"
      :locale="locale"
      :readonly="readonly"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  computed: {
    formattedDate: function() {
      return this.inputDate ? moment(this.inputDate).format("L") : "";
    },
  },
  data() {
    return {
      inputDate: this.date,
      menu: false,
    };
  },
  props: {
    date: {
      type: String,
    },
    locale: {
      type: String,
      default: "fi-fi",
    },
    label: {
      type: String,
      default: "Päivämäärä",
    },
    min: {
      type: String,
    },
    max: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    date: {
      required: requiredIf(function() {
        return this.required;
      }),
    },
  },
  watch: {
    inputDate: function(newDate) {
      this.$emit("update:date", newDate);
      this.$v.$touch();
    },
    // TODO: Selvitä miksi vaatii tämän eikä toimi ilman
    date: function(newDate) {
      this.inputDate = newDate;
    },
  },
};
</script>

<style></style>
