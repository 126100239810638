<template>
  <v-row v-if="selected">
    <v-col>
      <v-btn
        :text="selected.Code !== language.Code"
        :color="selected.Code === language.Code ? 'info' : ''"
        class="mr-3"
        v-for="language in languages"
        :key="language.Code"
        @click="setLanguage(language)"
      >
        <span
          style="font-size: 1.5rem"
          :class="`fi fi-${
            language.Code === 'EN' ? 'gb' : language.Code.toLowerCase()
          } mr-3`"
        ></span>
        {{ language.Code }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      selected: this.selectedLanguage,
    };
  },
  methods: {
    setLanguage(event) {
      this.$emit("update:selectedLanguage", event);
    },
  },
  props: {
    languages: Array,
    selectedLanguage: Object,
  },
  watch: {
    selectedLanguage: function (newValue) {
      this.selected = newValue;
    },
  },
};
</script>

<style></style>
>
