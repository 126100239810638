<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col md="10" lg="8">
        <v-card class="elevation-12 main-content">
          <v-card-title> Mittaukset </v-card-title>
          <v-card-text>
            <LoadingIndicator :isLoading="!surveyLoading && isLoading" />

            <v-text-field
              v-show="!isLoading || (isLoading && surveyLoading)"
              label="Haku: Voit hakea mittauksia sekä mittauksen että omistajan nimellä"
              clearable
              append-icon="mdi-magnify"
              v-model="search"
              class="search-text-field"
            ></v-text-field>

            <v-container fluid class="header-row" v-show="!isLoading">
              <v-row>
                <v-col md="4">Mittaus</v-col>
                <v-col md="2">Yksikkö</v-col>
                <v-col md="2">Luotu</v-col>
                <v-col md="2">Tila</v-col>
                <v-col md="2">Vastauksia</v-col>
              </v-row>
            </v-container>

            <v-expansion-panels>
              <v-expansion-panel
                v-for="survey in filteredExternalSurveys"
                :key="survey.id"
                active-class="active-panel"
              >
                <v-expansion-panel-header
                  @click="setSurvey(survey)"
                  :ref="`s-${survey.guid}`"
                >
                  <v-row class="epanel-listing">
                    <v-col md="4">
                      <strong>{{ survey.name }}</strong>
                    </v-col>
                    <v-col md="2">
                      {{ survey.options.unit }}
                    </v-col>
                    <v-col md="2">
                      {{ formatDate(survey.createDate) }}
                    </v-col>
                    <v-col md="2">
                      <span>{{ survey.isOpen ? "Avoin" : "Valmis" }}</span>
                    </v-col>
                    <v-col
                      md="2"
                      :style="{
                        color: getAnswerPercentageColor(
                          survey.answerCount,
                          survey.voterCount
                        ),
                      }"
                    >
                      {{
                        `${survey.answerCount} / ${survey.voterCount} (${
                          survey.voterCount > 0
                            ? (
                                (survey.answerCount / survey.voterCount) *
                                100
                              ).toFixed(0)
                            : 0
                        } %)`
                      }}
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <LoadingIndicator
                    v-if="isLoading"
                    :isLoading="surveyLoading && isLoading"
                  />
                  <v-row>
                    <v-col class="pr-0">
                      <div v-if="survey.options.owner">
                        Hallinnoija: {{ survey.options.owner }}
                      </div></v-col
                    >
                    <v-col v-if="openText.length > 0" class="pl-0">
                      Aukiolo: {{ openText }}
                    </v-col>
                  </v-row>
                  <v-row
                    class="text-center mt-3"
                    v-if="
                      selectedSurvey &&
                      selectedSurveyGuid == selectedSurvey.guid
                    "
                  >
                    <v-col class="d-sm-flex justify-sm-space-between">
                      <v-btn
                        color="warning"
                        class="v-btn-100 mobile-btn"
                        large
                        dark
                        :to="{
                          name: 'edit-survey',
                          params: { guid: selectedSurvey.guid },
                        }"
                      >
                        <v-icon left>mdi-pencil</v-icon> Muokkaa mittausta
                      </v-btn>
                      <v-btn
                        color="success"
                        class="v-btn-100 mobile-btn"
                        large
                        dark
                        :to="{
                          name: 'voters',
                          params: { guid: selectedSurvey.guid },
                        }"
                      >
                        <v-icon left>mdi-account-multiple</v-icon> Vastaajat &
                        Jakelu
                      </v-btn>
                      <v-btn
                        color="info"
                        class="v-btn-100 mobile-btn"
                        large
                        dark
                        :to="{
                          name: 'report',
                          params: { guid: selectedSurvey.guid },
                        }"
                      >
                        <v-icon left>mdi-chart-line</v-icon> Tulokset
                      </v-btn>
                      <v-btn
                        class="v-btn-100 mobile-btn"
                        large
                        :to="{
                          name: 'repeat-survey',
                          params: { guid: selectedSurvey.guid },
                        }"
                      >
                        <v-icon left>mdi-repeat</v-icon> Toista
                      </v-btn>
                    </v-col>
                  </v-row>
                  <SurveyOverview
                    :showTitle="false"
                    class="mb-5"
                    v-if="
                      selectedSurvey &&
                      selectedSurveyGuid == selectedSurvey.guid
                    "
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              dark
              large
              :to="{ name: 'new-survey' }"
              class="mobile-btn"
            >
              <v-icon left>mdi-plus</v-icon> Luo uusi mittaus
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
@media (max-width: 480px) {
  .epanel-listing {
    flex-direction: column;
  }

  .v-btn-100 {
    min-width: 100% !important;
  }

  .header-row {
    display: none;
  }
}

.active-panel {
  background-color: #f6f6f6;
  border-radius: 4px;
}

.v-btn-100 {
  min-width: 25% !important;
}

.header-row > .row {
  padding: 0 12px;
  margin-right: 15px;
}

.search-text-field label {
  font-size: 0.9375rem;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";

import LoadingIndicator from "@/components/LoadingIndicator";
import SurveyOverview from "@/components/Survey/SurveyOverview";

export default {
  name: "surveys",
  components: {
    LoadingIndicator,
    SurveyOverview,
  },
  computed: {
    ...mapGetters({
      externalSurveys: "survey/externalSurveys",
      selectedSurvey: "survey/surveySimple",
      isLoading: "survey/isLoading",
    }),
    filteredExternalSurveys: function () {
      let res = this.search
        ? this.externalSurveys.filter((s) => {
            let name = s.name.toLowerCase().includes(this.search.toLowerCase());
            let owner =
              s.options.owner &&
              s.options.owner.toLowerCase().includes(this.search.toLowerCase());
            let unit =
              s.options.unit &&
              s.options.unit.toLowerCase().includes(this.search.toLowerCase());

            return name || owner || unit;
          })
        : this.externalSurveys;

      return res.sort(this.dynamicSort("-createDate"));
    },
    openText() {
      if (
        !this.selectedSurvey ||
        (!this.selectedSurvey.openDate && !this.selectedSurvey.closeDate)
      )
        return "";

      let open = this.formatDateTime(this.selectedSurvey.openDate);
      let close = this.formatDateTime(this.selectedSurvey.closeDate);

      return `${open} - ${close}`;
    },
  },
  async created() {
    await this.getExternalSurveys();
    if (this.$route.params.guid) {
      let s = this.$refs[`s-${this.$route.params.guid}`];
      if (s) {
        s[0].$el.click();
      }
    }
  },
  data: () => ({
    headers: [
      {
        text: "Nimi",
        value: "name",
      },
      {
        text: "Vastauksia",
        value: "answerCount",
      },
      {
        text: "Avoinna",
        value: "isOpen",
      },
      {
        text: "Lisätty",
        value: "createDate",
      },
      {
        text: "",
        value: "actions",
        sortable: false,
      },
    ],
    surveyLoading: false,
    selectedSurveyGuid: undefined,
    search: "",
  }),
  methods: {
    ...mapActions({
      getExternalSurveys: "survey/getExternalSurveys",
      getExternalSurveySimple: "survey/getExternalSurveySimple",
      getSurveyStatus: "report/getStatus",
    }),
    editItem(item) {
      this.$router.push({
        name: "edit-survey",
        params: { guid: item.guid },
      });
    },
    showSurvey(item) {
      this.$router.push({
        name: "survey",
        params: { guid: item.guid },
      });
    },
    async setSurvey(item) {
      if (this.selectedSurveyGuid !== item.guid) {
        this.selectedSurveyGuid = item.guid;
        this.surveyLoading = true;
        await this.getExternalSurveySimple(item.guid);

        if (item.voterCount > 0) {
          await this.getSurveyStatus(item.guid);
        }
        this.surveyLoading = false;
      }
    },
    deleteItem() {},
    getAnswerPercentageColor(answerCount, voterCount) {
      if (voterCount === 0) {
        return this.$config?.report?.chartColorRed || "red";
      }
      let percentage = (answerCount / voterCount) * 100;

      let highAnswerPercentageMin =
        this.$config?.report?.highAnswerPercentageMin ?? 66;
      let mediumAnswerPercentageMin =
        this.$config?.report?.mediumAnswerPercentageMin ?? 33;

      if (percentage >= highAnswerPercentageMin) {
        return this.$config?.report?.chartColorGreen || "green";
      } else if (percentage >= mediumAnswerPercentageMin) {
        return this.$config?.report?.chartColorYellow || "yellow";
      } else {
        return this.$config?.report?.chartColorRed || "red";
      }
    },
  },
  destroyed() {
    if (this.$route.name !== "report") {
      this.$store.dispatch("survey/clearSurvey", null);
    }

    this.selectedSurveyGuid = null;
  },
};
</script>
