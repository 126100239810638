import axios from "axios";

import router from "@/router";
import store from "@/store";

axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response;
  },
  (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    // Logout user if token refresh didn't work or user is disabled
    if (
      error.config.url == `${process.env.VUE_APP_API_URL}/auth/refresh-token`
    ) {
      router.push({ name: "login" });

      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    // User tried to login with invalid credentials
    if (
      error.config.url == `${process.env.VUE_APP_API_URL}/auth/authenticate`
    ) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    // Try request again with new token
    return axios
      .post(`${process.env.VUE_APP_API_URL}/auth/refresh-token`)
      .then(async (response) => {
        const token = response.data;

        await store.dispatch("auth/getCurrentUser", token);

        // New request with new token
        const config = error.config;
        config.headers["Authorization"] = `Bearer ${token}`;

        return new Promise((resolve, reject) => {
          axios
            .request(config)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      })
      .catch((error) => {
        Promise.reject(error).then(
          () => {},
          () => {}
        );
      });
  }
);
