<template>
  <v-container v-if="survey && !isLoading">
    <v-row
      ><v-col
        ><h2>{{ name }}</h2></v-col
      ></v-row
    >
    <v-row v-for="(p, pIdx) in survey.Pages" :key="p.Id">
      <v-col md="12">
        <h3>{{ getPage(p.Id, p.Title) || `Sivu ${pIdx + 1}` }}</h3>
        <v-row v-for="(g, gIdx) in p.QuestionGroups" :key="g.Id">
          <v-col md="3">
            {{ getQuestionGroup(g.Id, g.Title) || `Ryhmä ${gIdx + 1}` }}
          </v-col>
          <v-col md="9">
            <ul v-for="q in g.Questions" :key="q.Id" class="pa-0">
              <li>
                {{ getQuestion(q.Id, q.Question) }}
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      survey: "survey/survey",
      isLoading: "survey/isLoading",
    }),
    allQuestions: function () {
      if (!this.survey) {
        return [];
      }

      var s = this.survey.Pages.map((p) =>
        p.QuestionGroups.map((g) =>
          g.Questions.map((q) => ({
            id: q.Id,
            question: q.Question,
          }))
        ).filter((g) => g.length > 0)
      ).filter((p) => p.length > 0);

      return [].concat(...[].concat(...s));
    },
    name: function () {
      return this.language
        ? this.surveyDetailsTranslations.find(
            (t) => t.code === this.language.Code
          )?.name ?? this.survey.Name
        : this.survey.Name;
    },
  },
  methods: {
    clearSurvey() {
      this.$store.dispatch("survey/clearSurvey");
    },
    getQuestion(id, original) {
      let translation = undefined;

      if (this.language) {
        translation =
          this.survey.SurveyQuestionTranslations.filter(
            (q) => q.Id === id && q.Code === this.language.Code
          )[0]?.Question ?? undefined;

        const newTranslation = this.textQuestionTranslations
          .find((l) => l.code === this.language.Code)
          .textQuestions.find((t) => t.id === id)?.question;

        if (newTranslation) {
          translation = newTranslation;
        }
      }

      return translation || original;
    },
    getQuestionGroup(id, original) {
      let translation = undefined;

      if (this.language) {
        translation = this.survey.SurveyQuestionGroupTranslations.find(
          (l) => l.Code === this.language.Code && l.Id === id
        )?.Title;
      }

      return translation || original;
    },
    getPage(id, original) {
      let translation = undefined;

      if (this.language) {
        translation = this.survey.SurveyPageTranslations.find(
          (l) => l.Code === this.language.Code && l.Id === id
        )?.Title;
      }

      return translation || original;
    },
  },
  props: {
    language: Object,
    surveyDetailsTranslations: Array,
    textQuestionTranslations: Array,
  },
};
</script>

<style>
</style>