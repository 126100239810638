<template>
  <div>
    <v-text-field
      label="Otsikko"
      :rules="showValidation ? [rules.required] : []"
      v-model="title"
      @change="titleChanged"
    ></v-text-field>
    <quillEditor
      class="editor"
      ref="myQuillEditor"
      :options="editorOption"
      v-model="email"
      @change="onEditorChange"
    >
      <div id="toolbar" slot="toolbar">
        <span class="ql-formats">
          <button class="ql-bold">Bold</button>
          <button class="ql-italic">Italic</button>
          <button class="ql-underline">Underline</button>
          <button class="ql-strike">Strike</button>
        </span>
        <span class="ql-formats">
          <button class="ql-list" value="ordered">Ordered List</button>
          <button class="ql-list" value="bullet">Bullet List</button>
        </span>

        <span class="ql-formats">
          <select class="ql-header"></select>
        </span>

        <span class="ql-formats">
          <select class="ql-color"></select>
          <select class="ql-background"></select>
        </span>

        <span class="ql-formats">
          <button class="ql-clean"></button>
        </span>
      </div>
    </quillEditor>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  data: function () {
    return {
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
        placeholder:
          "Kirjoita sähköpostiviesti kaikille vastaajille ja anna tarvittavat ohjeet. Linkki mittaukseen lisätään automaattisesti viestin loppuun.",
      },
      rules: {
        required: (value) => !!value || "Pakollinen kenttä",
      },
      email: this.emails[this.selectedLanguage.Code].body,
      title: this.emails[this.selectedLanguage.Code].title,
    };
  },
  methods: {
    titleChanged: function (value) {
      this.$emit("titleChanged", value);
    },
    onEditorChange: function ({ html }) {
      this.$emit("emailChanged", html);
    },
  },
  watch: {
    selectedLanguage: function (lang) {
      this.email = this.emails[lang.Code].body;
      this.title = this.emails[lang.Code].title;
    },
  },
  props: {
    selectedLanguage: { type: Object, required: true },
    emails: { type: Object, required: true },
    showValidation: { type: Boolean, default: true },
  },
};
</script>

<style>
.ql-editor {
  min-height: 200px;
}
.ql-editor p {
  margin-bottom: 10px;
}
</style>

<style lang="scss" scoped>
.editor {
  .ql-custom-button {
    width: 100px;
  }
}
</style>