<template>
  <v-text-field
    required
    :disabled="disabled"
    :readonly='isDeleted'
    :label="label"
    v-bind:class="{
      'deleted-selection': isDeleted,
    }"
    :error-messages="selectionErrors"
    @input="input"
    @blur="$v.sel.$touch()"
    class="pa-0"
    v-model="sel"
    :append-outer-icon="canDelete ? isDeleted ? 'mdi-delete-restore' : 'mdi-delete' : ''"
    @click:append-outer='$emit("delete:selection")'
  />
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  computed: {
    selectionErrors() {
      const errors = [];
      if (!this.$v.sel.$dirty) return errors;
      !this.$v.sel.required && errors.push("Pakollinen");
      return errors;
    },
    isValid: function () {
      return !this.$v.$invalid;
    },
  },
  data: function () {
    return {
      sel: this.selection,
    };
  },
  methods: {
    input(value) {
      this.$v.sel.$touch();
      this.$emit("update:selection", value);
    },
  },
  validations: {
    sel: {
      required,
    },
  },
  props: {
    selection: { type: String, required: true },
    label: String,
    disabled: { type: Boolean, default: false },
    canDelete: { type: Boolean, default: false },
    isDeleted: { type: Boolean, default: false },
  },
};
</script>

<style scoped>
.deleted-selection >>> input {
  text-decoration: line-through;
  color: red;
}
</style>