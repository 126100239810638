<template>
  <v-container fluid>
    <v-row>
      <v-col class="d-flex justify-center">
        <h2>{{ $t("voter.report.biggestChanges") }}</h2>
      </v-col>
    </v-row>
    <v-row
      ><v-col md="8" cols="6"></v-col>
      <v-col md="4" cols="6">
        <v-row>
          <v-col class="d-flex justify-center pb-0">
            <span>{{ this.compareOverview.surveyName }}</span>
          </v-col>
          <v-col class="d-flex justify-center pb-0">
            <span>{{ this.overview.surveyName }}</span>
          </v-col></v-row
        >
      </v-col>
    </v-row>
    <v-row>
      <CompareQuestions
        :overview="overview"
        :compareOverview="compareOverview"
        :best="true"
        :mapping="mapping"
      />
      <CompareQuestions
        :overview="overview"
        :compareOverview="compareOverview"
        :best="false"
        :mapping="mapping"
      />
    </v-row>
  </v-container>
</template>

<script>
import CompareQuestions from "@/components/Compare/CompareQuestions";

export default {
  components: {
    CompareQuestions,
  },
  props: {
    overview: Object,
    compareOverview: Object,
    mapping: String,
  },
};
</script>

<style></style>
