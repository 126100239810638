<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="3">
        <v-card class="elevation-12">
          <v-card-title> Palauta salasana </v-card-title>
          <v-card-text>
            <v-form @submit.prevent>
              <v-text-field
                :disabled="loading"
                label="Sähköpostiosoite"
                name="login"
                prepend-icon="mdi-account"
                type="text"
                required
                @keyup.enter="submit"
                v-model="form.username"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn color="default" text to="/login">Peruuta</v-btn>
            <v-btn color="primary" @click="submit" :disabled="loading"
              >Palauta salasana</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "login",
  components: {},
  data: () => ({
    form: {
      username: "",
    },
    loading: false,
    loginFailed: false,
    showPassword: false,
  }),
  methods: {
    async submit() {
      if (this.form.username && this.form.username.length > 0) {
        this.loading = true;

        axios
          .post(`${process.env.VUE_APP_API_URL}/auth/forgot`, {
            username: this.form.username,
          })
          .then((res) => {
            if (res) {
              this.$toast.center(
                "Sähköpostiin lähetettiin ohjeet käyttäjätunnuksen palauttamiseksi"
              );

              this.$router.replace({
                name: "login",
              });
            } else {
              this.$toast.center("Pyyntöä ei voitu käsitellä.");
            }
            this.loading = false;
          })
          .catch((err) => {
            this.$toast.center(err);
            this.loading = false;
          });
      }
    },
  },
};
</script>
